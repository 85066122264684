export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Input type for dynamic zone sections of CircuitHub */
    CircuitHubSectionsDynamicZoneInput: any;
    /** Input type for dynamic zone content of ContentPage */
    ContentPageContentDynamicZoneInput: any;
    Cursor: any;
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: any;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: any;
    /** Decimal number representation. */
    Decimal: any;
    DocumentationArticleStepsContentDynamicZoneInput: any;
    DocumentationArticleTabsContentDynamicZoneInput: any;
    /** Duration formatted as ISO 8601 */
    Duration: any;
    /** Hexadecimal value of an RGB color. */
    HexColor: any;
    /** A string used to identify an i18n locale */
    I18NLocaleCode: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** The `Long` scalar type represents 52-bit integers */
    Long: any;
    /** Input type for dynamic zone components of Page */
    PageComponentsDynamicZoneInput: any;
    /** A time string with format: HH:mm:ss.SSS */
    Time: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
    /** URL pointing to resource. */
    Url: any;
    /** Version represented as string using format 'MAJOR.MINOR' */
    Version: any;
    _FieldSet: any;
};

export type ApiKey = {
    __typename?: 'APIKey';
    id: Scalars['String'];
    value: Scalars['String'];
};

export type ApiKeysPayload = {
    __typename?: 'APIKeysPayload';
    apiKeys: Array<Maybe<ApiKey>>;
};

/** An ability that is owned by a player (available from version "1.8"). */
export type Ability = {
    /** GRID ID for this ability. */
    id: Scalars['ID'];
    /** The name of this ability. */
    name: Scalars['String'];
    /** Whether this ability can be activated or not. */
    ready: Scalars['Boolean'];
};

/** An ability that is owned by a player (available from version "1.8"). */
export type AbilityValorant = Ability & {
    __typename?: 'AbilityValorant';
    /** How many charges this ability has */
    charges: Scalars['Int'];
    /** GRID ID for this ability. */
    id: Scalars['ID'];
    /** The name of this ability. */
    name: Scalars['String'];
    /** Whether this ability can be activated or not. */
    ready: Scalars['Boolean'];
};

export type Action = {
    __typename?: 'Action';
    value: Scalars['String'];
};

export type ActionFilter = {
    value?: Maybe<StringFilter>;
};

export type AddTeamSeriesWin = {
    __typename?: 'AddTeamSeriesWin';
    id: Scalars['String'];
};

export type AddTeamSeriesWinPayload = {
    teamId: Scalars['String'];
};

export type AddUserPayload = {
    organisationId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type AdminUser = {
    __typename?: 'AdminUser';
    firstname: Scalars['String'];
    id: Scalars['ID'];
    lastname: Scalars['String'];
    username?: Maybe<Scalars['String']>;
};

/** Automatic update available options. */
export enum AutomaticUpdatesStatus {
    /** Allows the entity to be automatically modified. */
    Active = 'ACTIVE',
    /** Does not allow automatic modifications. */
    Inactive = 'INACTIVE',
}

export type AvailableFile = {
    __typename?: 'AvailableFile';
    description: Scalars['String'];
    fileName: Scalars['String'];
    fullURL: Scalars['String'];
    id: Scalars['String'];
    status: Scalars['String'];
};

export type BaseSeriesInfo = {
    __typename?: 'BaseSeriesInfo';
    id?: Maybe<Scalars['String']>;
    tournamentId?: Maybe<Scalars['String']>;
};

export type BaseTeamInfo = {
    __typename?: 'BaseTeamInfo';
    id?: Maybe<Scalars['Int']>;
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    primaryColor?: Maybe<Scalars['String']>;
    secondaryColor?: Maybe<Scalars['String']>;
};

export type BaseTitleInfo = {
    __typename?: 'BaseTitleInfo';
    shortName?: Maybe<Scalars['String']>;
};

export type BaseTournamentInfo = {
    __typename?: 'BaseTournamentInfo';
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
};

export type Bestodds = {
    __typename?: 'Bestodds';
    logo?: Maybe<UploadFile>;
    name?: Maybe<Scalars['String']>;
    provider?: Maybe<BestoddsProvider>;
    value?: Maybe<Scalars['Float']>;
};

export type BestoddsProvider = {
    __typename?: 'BestoddsProvider';
    link?: Maybe<Scalars['String']>;
};

export type BettingProviders = {
    __typename?: 'BettingProviders';
    OddslootProvider_id?: Maybe<Scalars['Int']>;
    created_at: Scalars['DateTime'];
    id: Scalars['ID'];
    logo?: Maybe<UploadFile>;
    name?: Maybe<Scalars['String']>;
    sites?: Maybe<Array<Maybe<Site>>>;
    updated_at: Scalars['DateTime'];
};

export type BettingProvidersSitesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** Boolean filter for true/false fields. */
export type BooleanFilter = {
    /** Value to look for, can be nullable. */
    equals?: Maybe<Scalars['Boolean']>;
};

export type BooleanFilterInput = {
    and?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    between?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    contains?: Maybe<Scalars['Boolean']>;
    containsi?: Maybe<Scalars['Boolean']>;
    endsWith?: Maybe<Scalars['Boolean']>;
    eq?: Maybe<Scalars['Boolean']>;
    eqi?: Maybe<Scalars['Boolean']>;
    gt?: Maybe<Scalars['Boolean']>;
    gte?: Maybe<Scalars['Boolean']>;
    in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    lt?: Maybe<Scalars['Boolean']>;
    lte?: Maybe<Scalars['Boolean']>;
    ne?: Maybe<Scalars['Boolean']>;
    not?: Maybe<BooleanFilterInput>;
    notContains?: Maybe<Scalars['Boolean']>;
    notContainsi?: Maybe<Scalars['Boolean']>;
    notIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    notNull?: Maybe<Scalars['Boolean']>;
    null?: Maybe<Scalars['Boolean']>;
    or?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    startsWith?: Maybe<Scalars['Boolean']>;
};

/** Bounds information (ie min and max Coordinates for a map). */
export type Bounds = {
    __typename?: 'Bounds';
    /** Maximum Coordinates value. */
    max: Coordinates;
    /** Minimum Coordinates value. */
    min: Coordinates;
};

export type Brand = {
    __typename?: 'Brand';
    created_at: Scalars['DateTime'];
    dark_colors?: Maybe<Scalars['JSON']>;
    fontImportUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    light_colors?: Maybe<Scalars['JSON']>;
    name?: Maybe<Scalars['String']>;
    primary_colors?: Maybe<Scalars['JSON']>;
    primary_logo?: Maybe<UploadFile>;
    secondary_colors?: Maybe<Scalars['JSON']>;
    secondary_logo?: Maybe<UploadFile>;
    slate_colors?: Maybe<Scalars['JSON']>;
    theme_overrides?: Maybe<Scalars['JSON']>;
    updated_at: Scalars['DateTime'];
};

/** In-game character (ie champion, class) information */
export type Character = {
    __typename?: 'Character';
    /** GRID Character ID. */
    id: Scalars['ID'];
    /** Character name */
    name: Scalars['String'];
};

export type CircuitHub = {
    __typename?: 'CircuitHub';
    banner?: Maybe<UploadFile>;
    content_pages?: Maybe<Array<Maybe<ContentPage>>>;
    created_at: Scalars['DateTime'];
    footer?: Maybe<ComponentCircuitHubFooter>;
    id: Scalars['ID'];
    navigation?: Maybe<ComponentGenericNavigation>;
    pages?: Maybe<Array<Maybe<Page>>>;
    published_at?: Maybe<Scalars['DateTime']>;
    sections: Array<Maybe<CircuitHubSectionsDynamicZone>>;
    site?: Maybe<Site>;
    tourLogo?: Maybe<ComponentImagesImage>;
    updated_at: Scalars['DateTime'];
};

export type CircuitHubContent_PagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type CircuitHubPagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type CircuitHubAggregator = {
    __typename?: 'CircuitHubAggregator';
    count?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type CircuitHubConnection = {
    __typename?: 'CircuitHubConnection';
    aggregate?: Maybe<CircuitHubAggregator>;
    groupBy?: Maybe<CircuitHubGroupBy>;
    values?: Maybe<Array<Maybe<CircuitHub>>>;
};

export type CircuitHubConnectionBanner = {
    __typename?: 'CircuitHubConnectionBanner';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type CircuitHubConnectionCreated_At = {
    __typename?: 'CircuitHubConnectionCreated_at';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type CircuitHubConnectionFooter = {
    __typename?: 'CircuitHubConnectionFooter';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type CircuitHubConnectionId = {
    __typename?: 'CircuitHubConnectionId';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type CircuitHubConnectionNavigation = {
    __typename?: 'CircuitHubConnectionNavigation';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type CircuitHubConnectionPublished_At = {
    __typename?: 'CircuitHubConnectionPublished_at';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type CircuitHubConnectionSite = {
    __typename?: 'CircuitHubConnectionSite';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type CircuitHubConnectionTourLogo = {
    __typename?: 'CircuitHubConnectionTourLogo';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type CircuitHubConnectionUpdated_At = {
    __typename?: 'CircuitHubConnectionUpdated_at';
    connection?: Maybe<CircuitHubConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type CircuitHubGroupBy = {
    __typename?: 'CircuitHubGroupBy';
    banner?: Maybe<Array<Maybe<CircuitHubConnectionBanner>>>;
    created_at?: Maybe<Array<Maybe<CircuitHubConnectionCreated_At>>>;
    footer?: Maybe<Array<Maybe<CircuitHubConnectionFooter>>>;
    id?: Maybe<Array<Maybe<CircuitHubConnectionId>>>;
    navigation?: Maybe<Array<Maybe<CircuitHubConnectionNavigation>>>;
    published_at?: Maybe<Array<Maybe<CircuitHubConnectionPublished_At>>>;
    site?: Maybe<Array<Maybe<CircuitHubConnectionSite>>>;
    tourLogo?: Maybe<Array<Maybe<CircuitHubConnectionTourLogo>>>;
    updated_at?: Maybe<Array<Maybe<CircuitHubConnectionUpdated_At>>>;
};

export type CircuitHubInput = {
    banner?: Maybe<Scalars['ID']>;
    content_pages?: Maybe<Array<Maybe<Scalars['ID']>>>;
    created_by?: Maybe<Scalars['ID']>;
    footer: ComponentCircuitHubFooterInput;
    navigation: ComponentGenericNavigationInput;
    pages?: Maybe<Array<Maybe<Scalars['ID']>>>;
    published_at?: Maybe<Scalars['DateTime']>;
    sections: Array<Scalars['CircuitHubSectionsDynamicZoneInput']>;
    site?: Maybe<Scalars['ID']>;
    tourLogo: ComponentImagesImageInput;
    updated_by?: Maybe<Scalars['ID']>;
};

export type CircuitHubSectionsDynamicZone =
    | ComponentCircuitHubBasicSection
    | ComponentCircuitHubExternalContent
    | ComponentCircuitHubPartnersSection
    | ComponentCircuitHubPointsSection
    | ComponentCircuitHubTabsSection
    | ComponentCircuitHubTournaments;

/** The game or respawn clock state (available from version "1.3"). */
export type ClockState = {
    __typename?: 'ClockState';
    /** The current seconds for this clock */
    currentSeconds?: Maybe<Scalars['Int']>;
    /** The id of this clock */
    id?: Maybe<Scalars['String']>;
    /** Indicates if this clock is ticking */
    ticking?: Maybe<Scalars['Boolean']>;
    /** Indicates if this clock is ticking backwards */
    ticksBackwards?: Maybe<Scalars['Boolean']>;
    /** The type of this clock */
    type?: Maybe<Scalars['String']>;
};

export type ComponentArticlesEndpoints = {
    __typename?: 'ComponentArticlesEndpoints';
    closedAccess: Scalars['String'];
    id: Scalars['ID'];
    openAccess: Scalars['String'];
};

export type ComponentArticlesPackage = {
    __typename?: 'ComponentArticlesPackage';
    id: Scalars['ID'];
    package_access?: Maybe<Scalars['JSON']>;
};

export type ComponentArticlesPackageFiltersInput = {
    and?: Maybe<Array<Maybe<ComponentArticlesPackageFiltersInput>>>;
    not?: Maybe<ComponentArticlesPackageFiltersInput>;
    or?: Maybe<Array<Maybe<ComponentArticlesPackageFiltersInput>>>;
    package_access?: Maybe<JsonFilterInput>;
};

export type ComponentArticlesPackageInput = {
    id?: Maybe<Scalars['ID']>;
    package_access?: Maybe<Scalars['JSON']>;
};

export type ComponentArticlesSectionStep = {
    __typename?: 'ComponentArticlesSectionStep';
    content?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    title: Scalars['String'];
};

export type ComponentArticlesSectionTabs = {
    __typename?: 'ComponentArticlesSectionTabs';
    endpoints?: Maybe<ComponentArticlesEndpoints>;
    id: Scalars['ID'];
    name: Scalars['String'];
    query: Scalars['String'];
};

export type ComponentArticlesTag = {
    __typename?: 'ComponentArticlesTag';
    id: Scalars['ID'];
    value: Scalars['String'];
};

export type ComponentArticlesTagFiltersInput = {
    and?: Maybe<Array<Maybe<ComponentArticlesTagFiltersInput>>>;
    not?: Maybe<ComponentArticlesTagFiltersInput>;
    or?: Maybe<Array<Maybe<ComponentArticlesTagFiltersInput>>>;
    value?: Maybe<StringFilterInput>;
};

export type ComponentArticlesTagInput = {
    id?: Maybe<Scalars['ID']>;
    value?: Maybe<Scalars['String']>;
};

export type ComponentButtonsButton = {
    __typename?: 'ComponentButtonsButton';
    href?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    size?: Maybe<Enum_Componentbuttonsbutton_Size>;
    text?: Maybe<Scalars['String']>;
    variant?: Maybe<Enum_Componentbuttonsbutton_Variant>;
};

export type ComponentButtonsButtonInput = {
    href?: Maybe<Scalars['String']>;
    size?: Maybe<Enum_Componentbuttonsbutton_Size>;
    text?: Maybe<Scalars['String']>;
    variant?: Maybe<Enum_Componentbuttonsbutton_Variant>;
};

export type ComponentCircuitHubBackButton = {
    __typename?: 'ComponentCircuitHubBackButton';
    id: Scalars['ID'];
    text?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubBackButtonInput = {
    text?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubBasicSection = {
    __typename?: 'ComponentCircuitHubBasicSection';
    action?: Maybe<ComponentButtonsButton>;
    id: Scalars['ID'];
    image?: Maybe<ComponentCircuitHubSectionImage>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyle>;
    text?: Maybe<ComponentCircuitHubSectionText>;
    video?: Maybe<ComponentCircuitHubVideo>;
};

export type ComponentCircuitHubBasicSectionInput = {
    action?: Maybe<ComponentButtonsButtonInput>;
    image?: Maybe<ComponentCircuitHubSectionImageInput>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyleInput>;
    text?: Maybe<ComponentCircuitHubSectionTextInput>;
    video?: Maybe<ComponentCircuitHubVideoInput>;
};

export type ComponentCircuitHubExternalContent = {
    __typename?: 'ComponentCircuitHubExternalContent';
    id: Scalars['ID'];
    url: Scalars['String'];
};

export type ComponentCircuitHubExternalContentInput = {
    url: Scalars['String'];
};

export type ComponentCircuitHubFooter = {
    __typename?: 'ComponentCircuitHubFooter';
    id: Scalars['ID'];
    linksColumnLeft?: Maybe<ComponentGenericNavigation>;
    linksColumnRight?: Maybe<ComponentGenericNavigation>;
};

export type ComponentCircuitHubFooterInput = {
    linksColumnLeft: ComponentGenericNavigationInput;
    linksColumnRight: ComponentGenericNavigationInput;
};

export type ComponentCircuitHubPartnersSection = {
    __typename?: 'ComponentCircuitHubPartnersSection';
    action?: Maybe<ComponentButtonsButton>;
    id: Scalars['ID'];
    partners?: Maybe<Array<Maybe<Sponsor>>>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyle>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubPartnersSectionPartnersArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type ComponentCircuitHubPartnersSectionInput = {
    action?: Maybe<ComponentButtonsButtonInput>;
    partners?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyleInput>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubPointsSection = {
    __typename?: 'ComponentCircuitHubPointsSection';
    id: Scalars['ID'];
    points?: Maybe<Array<Maybe<ComponentCircuitHubSectionPoint>>>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyle>;
};

export type ComponentCircuitHubPointsSectionInput = {
    points?: Maybe<Array<Maybe<ComponentCircuitHubSectionPointInput>>>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyleInput>;
};

export type ComponentCircuitHubSectionImage = {
    __typename?: 'ComponentCircuitHubSectionImage';
    content?: Maybe<ComponentImagesImage>;
    id: Scalars['ID'];
    position: Enum_Componentcircuithubsectionimage_Position;
};

export type ComponentCircuitHubSectionImageInput = {
    content: ComponentImagesImageInput;
    position: Enum_Componentcircuithubsectionimage_Position;
};

export type ComponentCircuitHubSectionPoint = {
    __typename?: 'ComponentCircuitHubSectionPoint';
    icon?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    textBottom: Scalars['String'];
    textTop: Scalars['String'];
};

export type ComponentCircuitHubSectionPointInput = {
    icon?: Maybe<Scalars['String']>;
    textBottom: Scalars['String'];
    textTop: Scalars['String'];
};

export type ComponentCircuitHubSectionStyle = {
    __typename?: 'ComponentCircuitHubSectionStyle';
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundType?: Maybe<Enum_Componentcircuithubsectionstyle_Backgroundtype>;
    id: Scalars['ID'];
    verticalSpace?: Maybe<Enum_Componentcircuithubsectionstyle_Verticalspace>;
};

export type ComponentCircuitHubSectionStyleInput = {
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundType?: Maybe<Enum_Componentcircuithubsectionstyle_Backgroundtype>;
    verticalSpace?: Maybe<Enum_Componentcircuithubsectionstyle_Verticalspace>;
};

export type ComponentCircuitHubSectionTab = {
    __typename?: 'ComponentCircuitHubSectionTab';
    content?: Maybe<Array<Maybe<ComponentCircuitHubBasicSection>>>;
    id: Scalars['ID'];
    title: Scalars['String'];
};

export type ComponentCircuitHubSectionTabInput = {
    content?: Maybe<Array<Maybe<ComponentCircuitHubBasicSectionInput>>>;
    title: Scalars['String'];
};

export type ComponentCircuitHubSectionText = {
    __typename?: 'ComponentCircuitHubSectionText';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    subtitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubSectionTextInput = {
    description?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubTabsSection = {
    __typename?: 'ComponentCircuitHubTabsSection';
    id: Scalars['ID'];
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyle>;
    tabs?: Maybe<Array<Maybe<ComponentCircuitHubSectionTab>>>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubTabsSectionInput = {
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<ComponentCircuitHubSectionStyleInput>;
    tabs?: Maybe<Array<Maybe<ComponentCircuitHubSectionTabInput>>>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubTournamentConfig = {
    __typename?: 'ComponentCircuitHubTournamentConfig';
    id: Scalars['ID'];
    logo?: Maybe<UploadFile>;
    name?: Maybe<Scalars['String']>;
    order?: Maybe<Scalars['Float']>;
    period?: Maybe<Scalars['String']>;
    ranking?: Maybe<UploadFile>;
    region?: Maybe<Scalars['String']>;
    status?: Maybe<Enum_Componentcircuithubtournamentconfig_Status>;
};

export type ComponentCircuitHubTournamentConfigInput = {
    logo?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    order?: Maybe<Scalars['Float']>;
    period?: Maybe<Scalars['String']>;
    ranking?: Maybe<Scalars['ID']>;
    region?: Maybe<Scalars['String']>;
    status?: Maybe<Enum_Componentcircuithubtournamentconfig_Status>;
};

export type ComponentCircuitHubTournamentInput = {
    action?: Maybe<ComponentButtonsButtonInput>;
    sectionId?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    tournaments?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentCircuitHubTournaments = {
    __typename?: 'ComponentCircuitHubTournaments';
    action?: Maybe<ComponentButtonsButton>;
    id: Scalars['ID'];
    sectionId?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    tournaments?: Maybe<Array<Maybe<Page>>>;
};

export type ComponentCircuitHubTournamentsTournamentsArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type ComponentCircuitHubVideo = {
    __typename?: 'ComponentCircuitHubVideo';
    id: Scalars['ID'];
    position?: Maybe<Enum_Componentcircuithubvideo_Position>;
    url?: Maybe<Scalars['String']>;
};

export type ComponentCircuitHubVideoInput = {
    position?: Maybe<Enum_Componentcircuithubvideo_Position>;
    url?: Maybe<Scalars['String']>;
};

export type ComponentFeaturesFeatureInput = {
    config?: Maybe<Scalars['JSON']>;
    enabled?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
};

export type ComponentFeaturesFeatures = {
    __typename?: 'ComponentFeaturesFeatures';
    config?: Maybe<Scalars['JSON']>;
    enabled?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
};

export type ComponentGenericNavigation = {
    __typename?: 'ComponentGenericNavigation';
    id: Scalars['ID'];
    items?: Maybe<Array<Maybe<ComponentGenericNavigationItem>>>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentGenericNavigationInput = {
    items?: Maybe<Array<ComponentGenericNavigationItemInput>>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentGenericNavigationItem = {
    __typename?: 'ComponentGenericNavigationItem';
    icon?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    url: Scalars['String'];
};

export type ComponentGenericNavigationItemInput = {
    icon?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    url: Scalars['String'];
};

export type ComponentGenericOverrideInput = {
    series?: Maybe<Scalars['String']>;
    team?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    tournament?: Maybe<Scalars['String']>;
};

export type ComponentGenericOverrides = {
    __typename?: 'ComponentGenericOverrides';
    id: Scalars['ID'];
    series?: Maybe<Scalars['String']>;
    team?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    tournament?: Maybe<Scalars['String']>;
};

export type ComponentImagesGalleryImageInput = {
    cta?: Maybe<ComponentButtonsButtonInput>;
    fullsize?: Maybe<ComponentImagesImageInput>;
    thumbnail?: Maybe<ComponentImagesImageInput>;
};

export type ComponentImagesGalleryImages = {
    __typename?: 'ComponentImagesGalleryImages';
    cta?: Maybe<ComponentButtonsButton>;
    fullsize?: Maybe<ComponentImagesImage>;
    id: Scalars['ID'];
    thumbnail?: Maybe<ComponentImagesImage>;
};

export type ComponentImagesImage = {
    __typename?: 'ComponentImagesImage';
    alt_text?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    image?: Maybe<UploadFile>;
};

export type ComponentImagesImageInput = {
    alt_text?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['ID']>;
};

export type ComponentPageComponentsBestOddInput = {
    featured_betting_provider?: Maybe<Scalars['ID']>;
};

export type ComponentPageComponentsBestOdds = {
    __typename?: 'ComponentPageComponentsBestOdds';
    featured_betting_provider?: Maybe<BettingProviders>;
    id: Scalars['ID'];
};

export type ComponentPageComponentsGallery = {
    __typename?: 'ComponentPageComponentsGallery';
    gallery?: Maybe<Gallery>;
    id: Scalars['ID'];
    location?: Maybe<Enum_Componentpagecomponentsgallery_Location>;
};

export type ComponentPageComponentsGalleryInput = {
    gallery?: Maybe<Scalars['ID']>;
    location?: Maybe<Enum_Componentpagecomponentsgallery_Location>;
};

export type ComponentPageComponentsMeta = {
    __typename?: 'ComponentPageComponentsMeta';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    keywords?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentPageComponentsMetaInput = {
    description?: Maybe<Scalars['String']>;
    keywords?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type ComponentPageComponentsPromoBanner = {
    __typename?: 'ComponentPageComponentsPromoBanner';
    id: Scalars['ID'];
    location?: Maybe<Enum_Componentpagecomponentspromobanner_Location>;
    promo_banner?: Maybe<MerchandiseBanner>;
};

export type ComponentPageComponentsPromoBannerInput = {
    location?: Maybe<Enum_Componentpagecomponentspromobanner_Location>;
    promo_banner?: Maybe<Scalars['ID']>;
};

export type ComponentPageComponentsSponsorInput = {
    primary_sponsor?: Maybe<Scalars['ID']>;
    sponsors?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ComponentPageComponentsSponsors = {
    __typename?: 'ComponentPageComponentsSponsors';
    id: Scalars['ID'];
    primary_sponsor?: Maybe<Sponsor>;
    sponsors?: Maybe<Array<Maybe<Sponsor>>>;
};

export type ComponentPageComponentsSponsorsSponsorsArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type ComponentPageComponentsVisualisationsWidget = {
    __typename?: 'ComponentPageComponentsVisualisationsWidget';
    enabled?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    settings?: Maybe<Scalars['JSON']>;
    type?: Maybe<Enum_Componentpagecomponentsvisualisationswidget_Type>;
};

export type ComponentPageComponentsVisualisationsWidgetInput = {
    enabled?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    settings?: Maybe<Scalars['JSON']>;
    type?: Maybe<Enum_Componentpagecomponentsvisualisationswidget_Type>;
};

export type ComponentSocialSocialInput = {
    facebook?: Maybe<Scalars['String']>;
    instagram?: Maybe<Scalars['String']>;
    linkedin?: Maybe<Scalars['String']>;
    twitch?: Maybe<Scalars['String']>;
    twitter?: Maybe<Scalars['String']>;
    youtube?: Maybe<Scalars['String']>;
};

export type ComponentSocialSocials = {
    __typename?: 'ComponentSocialSocials';
    facebook?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    instagram?: Maybe<Scalars['String']>;
    linkedin?: Maybe<Scalars['String']>;
    twitch?: Maybe<Scalars['String']>;
    twitter?: Maybe<Scalars['String']>;
    youtube?: Maybe<Scalars['String']>;
};

export type ContentBundle = {
    __typename?: 'ContentBundle';
    contentPermissions?: Maybe<Array<Maybe<ContentPermissionRule>>>;
    id: Scalars['String'];
    name: Scalars['String'];
};

/** A content catalog entity. */
export type ContentCatalogEntity = {
    /** The content catalog version this entity appears in. */
    contentCatalogVersion: ContentCatalogVersion;
    /**
     * The content catalog entity version external IDs and providers.
     * NOTE: Available only on the internal API.
     */
    externalLinks: Array<ExternalLink>;
    /** The ID of the content catalog entity. */
    id: Scalars['ID'];
    /** The URL to the content catalog entity's image */
    imageUrl: Scalars['Url'];
    /** The name of the content catalog entity. */
    name: Scalars['String'];
    /** The content catalog entity owners */
    owners: Array<Owner>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

/** The result of querying list of content catalog entities, which provides additional information. */
export type ContentCatalogEntityConnection = {
    __typename?: 'ContentCatalogEntityConnection';
    /** All content catalog entities edges for a query on a given page. */
    edges: Array<ContentCatalogEntityEdge>;
    /** Information about the current page being pulled with a query. */
    pageInfo: PageInfo;
    /** Total count of content catalog entities matching the filtering in the API. */
    totalCount: Scalars['Int'];
};

/** Content catalog entity and cursor information. */
export type ContentCatalogEntityEdge = {
    __typename?: 'ContentCatalogEntityEdge';
    /** Content catalog entity cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** A content catalog entity. */
    node: ContentCatalogEntity;
};

/** Content catalog entity selection filter. */
export type ContentCatalogEntityFilter = {
    /** Filter by content catalog entity type. */
    entityType?: Maybe<ContentCatalogEntityTypeFilter>;
    /** Filter by content catalog entity id. */
    id?: Maybe<IdFilter>;
    /** Filter by content catalog entity name. */
    name?: Maybe<StringFilter>;
    /** Filter by entity visibility. */
    private?: Maybe<BooleanFilter>;
    /** Filter by content catalog entity title. */
    title?: Maybe<ContentCatalogEntityTitleFilter>;
};

/** Filter by content catalog entity title. */
export type ContentCatalogEntityTitleFilter = {
    /** Filter by content catalog entity title id. */
    id?: Maybe<IdFilter>;
};

/** The type of a content catalog entity. */
export enum ContentCatalogEntityType {
    /** Content catalog item. */
    Item = 'ITEM',
}

/** Filter by content catalog entity type. */
export type ContentCatalogEntityTypeFilter = {
    /** Filter by content catalog entity type. */
    in: Array<ContentCatalogEntityType>;
};

/** A content catalog item. */
export type ContentCatalogItem = ContentCatalogEntity & {
    __typename?: 'ContentCatalogItem';
    /** The content catalog version this entity appears in. */
    contentCatalogVersion: ContentCatalogVersion;
    /** The cost of an item. When not set, defaults to 0. */
    cost: Scalars['Float'];
    /** The content catalog entity version external IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The ID of the content catalog entity. */
    id: Scalars['ID'];
    /** The URL to the content catalog entity's image. */
    imageUrl: Scalars['Url'];
    /** The name of the content catalog entity. */
    name: Scalars['String'];
    /** The content catalog item owners */
    owners: Array<Owner>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

/** A content catalog version. */
export type ContentCatalogVersion = {
    __typename?: 'ContentCatalogVersion';
    /** The content catalog version external IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The unique identifier of the content catalog version. */
    id: Scalars['ID'];
    /** The unique name of the content catalog version. E.g. 7.34. */
    name: Scalars['String'];
    /** The content catalog version owners */
    owners: Array<Owner>;
    /** The visibility of this version to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The date and time when the content catalog version becomes effective. */
    publishedOn: Scalars['DateTime'];
    /** The title which the content catalog version belongs to. */
    title: Title;
};

/** The result of querying list of content catalog versions, which provides additional information. */
export type ContentCatalogVersionConnection = {
    __typename?: 'ContentCatalogVersionConnection';
    /** All content catalog version edges for a query on a given page. */
    edges: Array<ContentCatalogVersionEdge>;
    /** Information about the current page being pulled with a query. */
    pageInfo: PageInfo;
    /** Total count of content catalog versions matching the filtering in the API. */
    totalCount: Scalars['Int'];
};

/** Content catalog version and cursor information. */
export type ContentCatalogVersionEdge = {
    __typename?: 'ContentCatalogVersionEdge';
    /** Content catalog version cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** A content catalog version. */
    node: ContentCatalogVersion;
};

/** Filter by a content catalog version. */
export type ContentCatalogVersionFilter = {
    /** Filter by content catalog version name. */
    name?: Maybe<StringFilter>;
    /** Filter by owners. */
    owners?: Maybe<OwnerFilter>;
    /** Filter by version visibility. */
    private?: Maybe<BooleanFilter>;
    /** Filter by content catalog version published date. */
    publishedOn?: Maybe<DateTimeFilter>;
    /** Filter by content catalog title. */
    title?: Maybe<ContentCatalogVersionTitleFilter>;
};

/** Ordering options for content catalog versions results. */
export type ContentCatalogVersionOrder = {
    /** The ordering direction. */
    direction: OrderDirection;
    /** The field to order the content catalog entities versions by. */
    field: ContentCatalogVersionOrderField;
};

/** The field which the result will be ordered by. */
export enum ContentCatalogVersionOrderField {
    /** Order by publishedOn. */
    PublishedOn = 'PUBLISHED_ON',
    /** Order by title name. */
    Title = 'TITLE',
}

/** Filter by a content catalog version title. */
export type ContentCatalogVersionTitleFilter = {
    /** Filter by title ID. */
    id?: Maybe<IdFilter>;
};

export type ContentPage = {
    __typename?: 'ContentPage';
    backbutton?: Maybe<ComponentCircuitHubBackButton>;
    circuit_hub?: Maybe<CircuitHub>;
    content?: Maybe<Array<Maybe<ContentPageContentDynamicZone>>>;
    created_at: Scalars['DateTime'];
    footer?: Maybe<ComponentCircuitHubFooter>;
    id: Scalars['ID'];
    meta?: Maybe<ComponentPageComponentsMeta>;
    navigation?: Maybe<ComponentGenericNavigation>;
    published_at?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
    updated_at: Scalars['DateTime'];
};

export type ContentPageAggregator = {
    __typename?: 'ContentPageAggregator';
    count?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type ContentPageConnection = {
    __typename?: 'ContentPageConnection';
    aggregate?: Maybe<ContentPageAggregator>;
    groupBy?: Maybe<ContentPageGroupBy>;
    values?: Maybe<Array<Maybe<ContentPage>>>;
};

export type ContentPageConnectionBackbutton = {
    __typename?: 'ContentPageConnectionBackbutton';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ContentPageConnectionCircuit_Hub = {
    __typename?: 'ContentPageConnectionCircuit_hub';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ContentPageConnectionCreated_At = {
    __typename?: 'ContentPageConnectionCreated_at';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type ContentPageConnectionFooter = {
    __typename?: 'ContentPageConnectionFooter';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ContentPageConnectionId = {
    __typename?: 'ContentPageConnectionId';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ContentPageConnectionMeta = {
    __typename?: 'ContentPageConnectionMeta';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ContentPageConnectionNavigation = {
    __typename?: 'ContentPageConnectionNavigation';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ContentPageConnectionPublished_At = {
    __typename?: 'ContentPageConnectionPublished_at';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type ContentPageConnectionTitle = {
    __typename?: 'ContentPageConnectionTitle';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type ContentPageConnectionUpdated_At = {
    __typename?: 'ContentPageConnectionUpdated_at';
    connection?: Maybe<ContentPageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type ContentPageContentDynamicZone =
    | ComponentCircuitHubBasicSection
    | ComponentCircuitHubExternalContent
    | ComponentCircuitHubPartnersSection
    | ComponentCircuitHubPointsSection
    | ComponentCircuitHubTabsSection
    | ComponentCircuitHubTournaments;

export type ContentPageGroupBy = {
    __typename?: 'ContentPageGroupBy';
    backbutton?: Maybe<Array<Maybe<ContentPageConnectionBackbutton>>>;
    circuit_hub?: Maybe<Array<Maybe<ContentPageConnectionCircuit_Hub>>>;
    created_at?: Maybe<Array<Maybe<ContentPageConnectionCreated_At>>>;
    footer?: Maybe<Array<Maybe<ContentPageConnectionFooter>>>;
    id?: Maybe<Array<Maybe<ContentPageConnectionId>>>;
    meta?: Maybe<Array<Maybe<ContentPageConnectionMeta>>>;
    navigation?: Maybe<Array<Maybe<ContentPageConnectionNavigation>>>;
    published_at?: Maybe<Array<Maybe<ContentPageConnectionPublished_At>>>;
    title?: Maybe<Array<Maybe<ContentPageConnectionTitle>>>;
    updated_at?: Maybe<Array<Maybe<ContentPageConnectionUpdated_At>>>;
};

export type ContentPageInput = {
    backbutton?: Maybe<ComponentCircuitHubBackButtonInput>;
    circuit_hub?: Maybe<Scalars['ID']>;
    content?: Maybe<Array<Scalars['ContentPageContentDynamicZoneInput']>>;
    created_by?: Maybe<Scalars['ID']>;
    footer?: Maybe<ComponentCircuitHubFooterInput>;
    meta?: Maybe<ComponentPageComponentsMetaInput>;
    navigation?: Maybe<ComponentGenericNavigationInput>;
    published_at?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type ContentPermissionOrganisation = {
    __typename?: 'ContentPermissionOrganisation';
    hasPermission: Scalars['Boolean'];
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    users?: Maybe<Array<ContentPermissionUser>>;
};

export type ContentPermissionRule = {
    __typename?: 'ContentPermissionRule';
    access: Scalars['String'];
    action: Scalars['String'];
    entityRules?: Maybe<Array<Maybe<PermissionEntity>>>;
    entityType: Scalars['String'];
};

export type ContentPermissionRuleInput = {
    access: Scalars['String'];
    action: Scalars['String'];
    entityRules?: Maybe<Array<Maybe<PermissionEntityInput>>>;
    entityType: Scalars['String'];
};

export type ContentPermissionRuleMatchDescription = {
    __typename?: 'ContentPermissionRuleMatchDescription';
    /** Children of this rule. */
    children: Array<ContentPermissionRuleMatchDescription>;
    /** Is rule satisfied for passed entity id and type. */
    matches: Scalars['Boolean'];
    /** Description allowing identification of inspected rule. */
    ruleDescription: Scalars['String'];
};

export enum ContentPermissionRuleMatchDescriptionEntityType {
    Organization = 'ORGANIZATION',
    Player = 'PLAYER',
    Series = 'SERIES',
    Team = 'TEAM',
    Title = 'TITLE',
    Tournament = 'TOURNAMENT',
}

/** Full description of content permission rule matches. */
export type ContentPermissionRulesMatchDescription = {
    __typename?: 'ContentPermissionRulesMatchDescription';
    /** Matches of rules as they would be checked during fetching list of entities. */
    listingFetch: ContentPermissionRuleMatchDescription;
};

export type ContentPermissionSubjectProps = {
    __typename?: 'ContentPermissionSubjectProps';
    id: Scalars['String'];
    name: Scalars['String'];
    subjectType: Scalars['String'];
    users?: Maybe<Array<ContentPermissionSubjectProps>>;
};

export type ContentPermissionUser = {
    __typename?: 'ContentPermissionUser';
    disabled: Scalars['Boolean'];
    hasPermission: Scalars['Boolean'];
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
};

export type ContentPermissionsPayload = {
    __typename?: 'ContentPermissionsPayload';
    organisations: Array<Organisation>;
    users: Array<User>;
};

/** Spatial Coordinates. */
export type Coordinates = {
    __typename?: 'Coordinates';
    /** X coordinate value. */
    x: Scalars['Float'];
    /** Y coordinate value. */
    y: Scalars['Float'];
};

/** The fields used to create a new content catalog item or a version of an existing item. */
export type CreateContentCatalogItemInput = {
    /** The version of the content catalog the item should be linked to. */
    contentCatalogVersionId: Scalars['ID'];
    /** The cost of the item. When not provided, it defaults to 0. */
    cost?: Maybe<Scalars['Float']>;
    /** The external item IDs. */
    externalLinks?: Maybe<Array<ExternalLinkInput>>;
    /**
     * The UUID of an existing item to create a new version of it.
     * If not passed, a new item will be created.
     */
    id?: Maybe<Scalars['ID']>;
    /**
     * The image encoded as base64 string.
     * If not provided, it defaults to a placeholder image.
     */
    imageData?: Maybe<Scalars['String']>;
    /**
     * The name of the item.
     * Optional if creating a version of an existing item.
     */
    name?: Maybe<Scalars['String']>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

/** The response for the createContentCatalogItem mutation. */
export type CreateContentCatalogItemPayload = {
    __typename?: 'CreateContentCatalogItemPayload';
    /** The created content catalog version information. */
    createdContentCatalogItem: ContentCatalogItem;
};

/** The fields used to create a content catalog version. */
export type CreateContentCatalogVersionInput = {
    /** The external content catalog version ids. */
    externalLinks?: Array<ExternalLinkInput>;
    /** The UNIQUE name of the content catalog version. */
    name: Scalars['String'];
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The date and time when the catalog version becomes effective. */
    publishedOn: Scalars['DateTime'];
    /** The ID of the title for the content catalog version. */
    titleId: Scalars['ID'];
};

/** The response for the createContentCatalogVersion mutation. */
export type CreateContentCatalogVersionPayload = {
    __typename?: 'CreateContentCatalogVersionPayload';
    /** The created content catalog version information. */
    createdContentCatalogVersion: ContentCatalogVersion;
};

export type CreateOrganisationPayload = {
    name: Scalars['String'];
};

export type CreateOrganizationInput = {
    /** The name of the organization. */
    name: Scalars['String'];
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The teams that play for the organization. */
    teams?: Array<CreateOrganizationTeamInput>;
};

export type CreateOrganizationPayload = {
    __typename?: 'CreateOrganizationPayload';
    createdOrganization: Organization;
};

export type CreateOrganizationTeamInput = {
    teamId: Scalars['ID'];
};

export type CreateOrganizationTitleInput = {
    titleId: Scalars['ID'];
};

export type CreatePlayerInput = {
    externalLinks?: Array<ExternalLinkInput>;
    /** nickname can be maximum 100 characters long */
    nickname: Scalars['String'];
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    team?: Maybe<CreatePlayerTeamInput>;
    title: CreatePlayerTitleInput;
    /** The type of the player. Set to ESPORTS, if not specified. */
    type?: PlayerType;
};

export type CreatePlayerPayload = {
    __typename?: 'CreatePlayerPayload';
    createdPlayer: Player;
};

export type CreatePlayerTeamInput = {
    teamId: Scalars['ID'];
};

export type CreatePlayerTitleInput = {
    titleId: Scalars['ID'];
};

/**
 * Used to reference an existing format.
 * Creating a series users should not be able to create new or change the values of the existing format types.
 */
export type CreateSeriesFormatInput = {
    /** Series format identifier. Takes precedence when both are passed. Check available formats via SeriesFormat query. */
    id?: Maybe<Scalars['ID']>;
    /** @deprecated('Use ID instead.') Series format short name. */
    name?: Maybe<Scalars['String']>;
};

export type CreateSeriesInput = {
    /** The external series ids */
    externalLinks?: Array<ExternalLinkInput>;
    /** The valid series format i.a. best-of-3 (Bo3), score-after-2 (SA2). Referenced by shortName or ID. */
    format: CreateSeriesFormatInput;
    /** The machine data source id for this series */
    machineDataSource?: Maybe<MachineDataSourceInput>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The start time in UTC of this series */
    startTimeScheduled: Scalars['DateTime'];
    /** The teams playing in this series */
    teams: Array<CreateSeriesTeamInput>;
    /** The title id for this series */
    title: CreateSeriesTitleInput;
    /** The tournament id for this series */
    tournament: CreateSeriesTournamentInput;
};

export type CreateSeriesPayload = {
    __typename?: 'CreateSeriesPayload';
    createdSeries: Series;
};

export type CreateSeriesTeamInput = {
    /**  Team score advantage for series */
    scoreAdvantage?: Scalars['Int'];
    teamId: Scalars['ID'];
};

export type CreateSeriesTitleInput = {
    titleId: Scalars['ID'];
};

export type CreateSeriesTournamentInput = {
    tournamentId: Scalars['ID'];
};

export type CreateTeamInput = {
    /** Team primary color in correct hexadecimal value. */
    colorPrimary?: Scalars['HexColor'];
    /** Team secondary color in correct hexadecimal value. */
    colorSecondary?: Scalars['HexColor'];
    /** Team external links. */
    externalLinks?: Array<ExternalLinkInput>;
    /** Logo image encoded as base64 string. */
    logoData?: Maybe<Scalars['String']>;
    /** The name is unique and limited to 100 characters. */
    name: Scalars['String'];
    /** The shortened name of the team. It's limited to 20 characters and it's not unique. */
    nameShortened?: Maybe<Scalars['String']>;
    /** The organization the team has to be linked to. */
    organization?: Maybe<CreateTeamOrganizationInput>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The title of the team. */
    title?: Maybe<CreateTeamTitleInput>;
    /** @deprecated('Use title instead. If you use both, this field will be ignored.') The titles the team has to be linked to. */
    titles?: Array<CreateTeamTitleInput>;
};

export type CreateTeamOrganizationInput = {
    organizationId: Scalars['ID'];
};

export type CreateTeamPayload = {
    __typename?: 'CreateTeamPayload';
    createdTeam: Team;
};

export type CreateTeamTitleInput = {
    titleId: Scalars['ID'];
};

export type CreateTitleInput = {
    /** The name (e.g. counter strike global offensive) is unique and limited to 100 characters. */
    name: Scalars['String'];
    /** The shortened name (e.g. csgo) is unique and limited to 20 characters. */
    nameShortened: Scalars['String'];
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

export type CreateTitlePayload = {
    __typename?: 'CreateTitlePayload';
    createdTitle: Title;
};

export type CreateTournamentInput = {
    endDate?: Maybe<DateInput>;
    externalLinks?: Array<ExternalLinkInput>;
    logoData?: Maybe<Scalars['String']>;
    /**  Tournament name (e.g. United Masters League 2018-2019) is unique and limited to 150 chars */
    name: Scalars['String'];
    /**  Tournament nameShortened (e.g. UML 1819) is unique and limited to 30 chars. */
    nameShortened: Scalars['String'];
    parent?: Maybe<CreateTournamentParentInput>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    prizePool?: Maybe<MoneyInput>;
    startDate?: Maybe<DateInput>;
    tags?: Array<TagInput>;
    teams?: Array<CreateTournamentTeamInput>;
    titles?: Array<CreateTournamentTitleInput>;
};

export type CreateTournamentParentInput = {
    tournamentId: Scalars['ID'];
};

export type CreateTournamentPayload = {
    __typename?: 'CreateTournamentPayload';
    createdTournament: Tournament;
};

export type CreateTournamentTeamInput = {
    teamId: Scalars['ID'];
};

export type CreateTournamentTitleInput = {
    titleId: Scalars['ID'];
};

export type CreateUserPayload = {
    email: Scalars['String'];
    organisationId?: Maybe<Scalars['String']>;
    userType: Scalars['String'];
    username: Scalars['String'];
};

/** A source of damage with the amount and occurrence (available from version "3.17"). */
export type DamageDealtSource = {
    __typename?: 'DamageDealtSource';
    /** Amount of damage dealt with the source. */
    damageAmount: Scalars['Int'];
    /** Breakdown of different damage types. */
    damageTypes: Array<DamageDealtType>;
    /** GRID ID for this damage source */
    id: Scalars['ID'];
    /** Amount of times damage was dealt with the source. */
    occurrenceCount: Scalars['Int'];
    /** Source of the damage. */
    source: DamageSource;
};

/** A target of damage with the amount and occurrence (available from version "3.17"). */
export type DamageDealtTarget = {
    __typename?: 'DamageDealtTarget';
    /** Amount of damage dealt to the target. */
    damageAmount: Scalars['Int'];
    /** Breakdown of different damage types. */
    damageTypes: Array<DamageDealtType>;
    /** GRID ID for this damage target */
    id: Scalars['ID'];
    /** Amount of times damage was dealt to the target. */
    occurrenceCount: Scalars['Int'];
    /** Target of the damage. */
    target: DamageTarget;
};

/** A type of dealt damage (available from version "3.18"). */
export type DamageDealtType = {
    __typename?: 'DamageDealtType';
    /** Amount of damage dealt. */
    damageAmount: Scalars['Int'];
    /** GRID ID for this damage target */
    id: Scalars['ID'];
    /** Amount of times damage was dealt. */
    occurrenceCount: Scalars['Int'];
    /** Name of the type of damage */
    type: Scalars['String'];
};

/** A source of damage with a name (available from version "3.17"). */
export type DamageSource = {
    __typename?: 'DamageSource';
    /** Name of the damage source. */
    name: Scalars['String'];
};

/** A target of damage with name (available from version "3.17"). */
export type DamageTarget = {
    __typename?: 'DamageTarget';
    /** Name of the damage source. */
    name: Scalars['String'];
};

export type DashboardSeriesPayload = {
    __typename?: 'DashboardSeriesPayload';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPreviousPage?: Maybe<Scalars['Boolean']>;
    seriesGroupedByDate: Array<StaticSeriesDataGroup>;
    startCursor?: Maybe<Scalars['String']>;
    totalCount?: Maybe<Scalars['Int']>;
};

/** A data provider which provides external entity IDs. */
export type DataProvider = {
    __typename?: 'DataProvider';
    /** Description of the data provider. */
    description?: Maybe<Scalars['String']>;
    /** Unique name of the data provider */
    name: Scalars['String'];
};

export type DataProvidersPayload = {
    __typename?: 'DataProvidersPayload';
    description?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type DateInput = {
    /** ISO 8601 formatted date i.e. yyyy-MM-dd */
    date?: Maybe<Scalars['Date']>;
};

/** Filter by a datetime range. */
export type DateTimeFilter = {
    /** DateTime for the beginning of the time range (optional), formatted as an ISO date string. Time window includes the given value. */
    gte?: Maybe<Scalars['String']>;
    /** DateTime for the end of the time range (optional), formatted as an ISO date string. Time window includes the given value. */
    lte?: Maybe<Scalars['String']>;
};

export type DateTimeFilterInput = {
    and?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    between?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    contains?: Maybe<Scalars['DateTime']>;
    containsi?: Maybe<Scalars['DateTime']>;
    endsWith?: Maybe<Scalars['DateTime']>;
    eq?: Maybe<Scalars['DateTime']>;
    eqi?: Maybe<Scalars['DateTime']>;
    gt?: Maybe<Scalars['DateTime']>;
    gte?: Maybe<Scalars['DateTime']>;
    in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    lt?: Maybe<Scalars['DateTime']>;
    lte?: Maybe<Scalars['DateTime']>;
    ne?: Maybe<Scalars['DateTime']>;
    not?: Maybe<DateTimeFilterInput>;
    notContains?: Maybe<Scalars['DateTime']>;
    notContainsi?: Maybe<Scalars['DateTime']>;
    notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    notNull?: Maybe<Scalars['Boolean']>;
    null?: Maybe<Scalars['Boolean']>;
    or?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    startsWith?: Maybe<Scalars['DateTime']>;
};

/** An ability that is owned by a player (available from version "1.8"). */
export type DefaultAbility = Ability & {
    __typename?: 'DefaultAbility';
    /** GRID ID for this ability. */
    id: Scalars['ID'];
    /** The name of this ability. */
    name: Scalars['String'];
    /** Whether this ability can be activated or not. */
    ready: Scalars['Boolean'];
};

/** The response for the deleteContentCatalogEntity mutation. */
export type DeleteContentCatalogEntityPayload = {
    __typename?: 'DeleteContentCatalogEntityPayload';
    /** The version of the deleted content catalog entity. */
    contentCatalogVersionId: Scalars['ID'];
    /** The ID of the deleted content catalog entity. */
    id: Scalars['ID'];
};

/** The response for the deleteContentCatalogVersion mutation. */
export type DeleteContentCatalogVersionPayload = {
    __typename?: 'DeleteContentCatalogVersionPayload';
    /** The ID of the deleted content catalog version. */
    id: Scalars['ID'];
};

export type DeleteContentPermissionInput = {
    removed?: Maybe<Array<Maybe<ContentPermissionRuleInput>>>;
};

export type DeleteOrganizationInput = {
    id: Scalars['ID'];
};

export type DeleteOrganizationPayload = {
    __typename?: 'DeleteOrganizationPayload';
    id: Scalars['ID'];
};

export type DeletePlayerInput = {
    id: Scalars['ID'];
};

export type DeletePlayerPayload = {
    __typename?: 'DeletePlayerPayload';
    id: Scalars['ID'];
};

export type DeleteSeriesInput = {
    id: Scalars['ID'];
};

export type DeleteSeriesPayload = {
    __typename?: 'DeleteSeriesPayload';
    id: Scalars['ID'];
};

export type DeleteTeamInput = {
    id: Scalars['ID'];
};

export type DeleteTeamPayload = {
    __typename?: 'DeleteTeamPayload';
    id: Scalars['ID'];
};

export type DeleteTournamentInput = {
    id: Scalars['ID'];
};

export type DeleteTournamentPayload = {
    __typename?: 'DeleteTournamentPayload';
    id: Scalars['ID'];
};

export type DeleteUser = {
    __typename?: 'DeleteUser';
    UserId: Scalars['ID'];
};

export type DocumentationArticle = {
    __typename?: 'DocumentationArticle';
    category: Enum_Documentationarticle_Category;
    content?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    description: Scalars['String'];
    isVisibleInListView?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<Scalars['String']>;
    localizations?: Maybe<DocumentationArticleRelationResponseCollection>;
    metadata?: Maybe<Scalars['JSON']>;
    package: ComponentArticlesPackage;
    package_home_page: Scalars['Boolean'];
    publishedAt?: Maybe<Scalars['DateTime']>;
    slug?: Maybe<Scalars['String']>;
    steps_content?: Maybe<Array<Maybe<DocumentationArticleStepsContentDynamicZone>>>;
    tabs_content?: Maybe<Array<Maybe<DocumentationArticleTabsContentDynamicZone>>>;
    tags?: Maybe<Array<Maybe<ComponentArticlesTag>>>;
    title: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    versionNumber?: Maybe<Scalars['Int']>;
    versions?: Maybe<DocumentationArticleRelationResponseCollection>;
    vuid?: Maybe<Scalars['String']>;
};

export type DocumentationArticleLocalizationsArgs = {
    filters?: Maybe<DocumentationArticleFiltersInput>;
    pagination?: Maybe<PaginationArg>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentationArticleTagsArgs = {
    filters?: Maybe<ComponentArticlesTagFiltersInput>;
    pagination?: Maybe<PaginationArg>;
    sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentationArticleVersionsArgs = {
    filters?: Maybe<DocumentationArticleFiltersInput>;
    pagination?: Maybe<PaginationArg>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentationArticleEntity = {
    __typename?: 'DocumentationArticleEntity';
    attributes?: Maybe<DocumentationArticle>;
    id?: Maybe<Scalars['ID']>;
};

export type DocumentationArticleEntityResponse = {
    __typename?: 'DocumentationArticleEntityResponse';
    data?: Maybe<DocumentationArticleEntity>;
};

export type DocumentationArticleEntityResponseCollection = {
    __typename?: 'DocumentationArticleEntityResponseCollection';
    data: Array<DocumentationArticleEntity>;
    meta: ResponseCollectionMeta;
};

export type DocumentationArticleFiltersInput = {
    and?: Maybe<Array<Maybe<DocumentationArticleFiltersInput>>>;
    category?: Maybe<StringFilterInput>;
    content?: Maybe<StringFilterInput>;
    createdAt?: Maybe<DateTimeFilterInput>;
    description?: Maybe<StringFilterInput>;
    id?: Maybe<IdFilterInput>;
    isVisibleInListView?: Maybe<BooleanFilterInput>;
    locale?: Maybe<StringFilterInput>;
    localizations?: Maybe<DocumentationArticleFiltersInput>;
    metadata?: Maybe<JsonFilterInput>;
    not?: Maybe<DocumentationArticleFiltersInput>;
    or?: Maybe<Array<Maybe<DocumentationArticleFiltersInput>>>;
    package?: Maybe<ComponentArticlesPackageFiltersInput>;
    package_home_page?: Maybe<BooleanFilterInput>;
    publishedAt?: Maybe<DateTimeFilterInput>;
    slug?: Maybe<StringFilterInput>;
    tags?: Maybe<ComponentArticlesTagFiltersInput>;
    title?: Maybe<StringFilterInput>;
    updatedAt?: Maybe<DateTimeFilterInput>;
    versionNumber?: Maybe<IntFilterInput>;
    versions?: Maybe<DocumentationArticleFiltersInput>;
    vuid?: Maybe<StringFilterInput>;
};

export type DocumentationArticleInput = {
    category?: Maybe<Enum_Documentationarticle_Category>;
    content?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    isVisibleInListView?: Maybe<Scalars['Boolean']>;
    metadata?: Maybe<Scalars['JSON']>;
    package?: Maybe<ComponentArticlesPackageInput>;
    package_home_page?: Maybe<Scalars['Boolean']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    slug?: Maybe<Scalars['String']>;
    steps_content?: Maybe<Array<Scalars['DocumentationArticleStepsContentDynamicZoneInput']>>;
    tabs_content?: Maybe<Array<Scalars['DocumentationArticleTabsContentDynamicZoneInput']>>;
    tags?: Maybe<Array<Maybe<ComponentArticlesTagInput>>>;
    title?: Maybe<Scalars['String']>;
    versionNumber?: Maybe<Scalars['Int']>;
    versions?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vuid?: Maybe<Scalars['String']>;
};

export type DocumentationArticleRelationResponseCollection = {
    __typename?: 'DocumentationArticleRelationResponseCollection';
    data: Array<DocumentationArticleEntity>;
};

export type DocumentationArticleStepsContentDynamicZone = ComponentArticlesSectionStep | Error;

export type DocumentationArticleTabsContentDynamicZone = ComponentArticlesSectionTabs | Error;

export type DocumentationPage = {
    __typename?: 'DocumentationPage';
    category: Enum_Documentationpage_Category;
    content: Scalars['String'];
    created_at: Scalars['DateTime'];
    id: Scalars['ID'];
    order?: Maybe<Scalars['Int']>;
    permissions?: Maybe<Scalars['JSON']>;
    published_at?: Maybe<Scalars['DateTime']>;
    slug: Scalars['String'];
    title: Scalars['String'];
    updated_at: Scalars['DateTime'];
};

export type DocumentationPageAggregator = {
    __typename?: 'DocumentationPageAggregator';
    avg?: Maybe<DocumentationPageAggregatorAvg>;
    count?: Maybe<Scalars['Int']>;
    max?: Maybe<DocumentationPageAggregatorMax>;
    min?: Maybe<DocumentationPageAggregatorMin>;
    sum?: Maybe<DocumentationPageAggregatorSum>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type DocumentationPageAggregatorAvg = {
    __typename?: 'DocumentationPageAggregatorAvg';
    order?: Maybe<Scalars['Float']>;
};

export type DocumentationPageAggregatorMax = {
    __typename?: 'DocumentationPageAggregatorMax';
    order?: Maybe<Scalars['Float']>;
};

export type DocumentationPageAggregatorMin = {
    __typename?: 'DocumentationPageAggregatorMin';
    order?: Maybe<Scalars['Float']>;
};

export type DocumentationPageAggregatorSum = {
    __typename?: 'DocumentationPageAggregatorSum';
    order?: Maybe<Scalars['Float']>;
};

export type DocumentationPageConnection = {
    __typename?: 'DocumentationPageConnection';
    aggregate?: Maybe<DocumentationPageAggregator>;
    groupBy?: Maybe<DocumentationPageGroupBy>;
    values?: Maybe<Array<Maybe<DocumentationPage>>>;
};

export type DocumentationPageConnectionCategory = {
    __typename?: 'DocumentationPageConnectionCategory';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type DocumentationPageConnectionContent = {
    __typename?: 'DocumentationPageConnectionContent';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type DocumentationPageConnectionCreated_At = {
    __typename?: 'DocumentationPageConnectionCreated_at';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type DocumentationPageConnectionId = {
    __typename?: 'DocumentationPageConnectionId';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type DocumentationPageConnectionOrder = {
    __typename?: 'DocumentationPageConnectionOrder';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['Int']>;
};

export type DocumentationPageConnectionPermissions = {
    __typename?: 'DocumentationPageConnectionPermissions';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['JSON']>;
};

export type DocumentationPageConnectionPublished_At = {
    __typename?: 'DocumentationPageConnectionPublished_at';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type DocumentationPageConnectionSlug = {
    __typename?: 'DocumentationPageConnectionSlug';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type DocumentationPageConnectionTitle = {
    __typename?: 'DocumentationPageConnectionTitle';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type DocumentationPageConnectionUpdated_At = {
    __typename?: 'DocumentationPageConnectionUpdated_at';
    connection?: Maybe<DocumentationPageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type DocumentationPageGroupBy = {
    __typename?: 'DocumentationPageGroupBy';
    category?: Maybe<Array<Maybe<DocumentationPageConnectionCategory>>>;
    content?: Maybe<Array<Maybe<DocumentationPageConnectionContent>>>;
    created_at?: Maybe<Array<Maybe<DocumentationPageConnectionCreated_At>>>;
    id?: Maybe<Array<Maybe<DocumentationPageConnectionId>>>;
    order?: Maybe<Array<Maybe<DocumentationPageConnectionOrder>>>;
    permissions?: Maybe<Array<Maybe<DocumentationPageConnectionPermissions>>>;
    published_at?: Maybe<Array<Maybe<DocumentationPageConnectionPublished_At>>>;
    slug?: Maybe<Array<Maybe<DocumentationPageConnectionSlug>>>;
    title?: Maybe<Array<Maybe<DocumentationPageConnectionTitle>>>;
    updated_at?: Maybe<Array<Maybe<DocumentationPageConnectionUpdated_At>>>;
};

export type DocumentationPageInput = {
    category: Enum_Documentationpage_Category;
    content: Scalars['String'];
    created_by?: Maybe<Scalars['ID']>;
    order?: Maybe<Scalars['Int']>;
    permissions?: Maybe<Scalars['JSON']>;
    published_at?: Maybe<Scalars['DateTime']>;
    slug: Scalars['String'];
    title: Scalars['String'];
    updated_by?: Maybe<Scalars['ID']>;
};

/** A draft action occurrence such as a team banning a map or a player picking a champion. */
export type DraftAction = {
    __typename?: 'DraftAction';
    /** Entity being drafted. */
    draftable: Draftable;
    /** Entity performing the draft action. */
    drafter: Drafter;
    /** GRID draft action ID. */
    id: Scalars['ID'];
    /** Sequence number of the draft action. */
    sequenceNumber: Scalars['String'];
    /** Type of the draft action. */
    type: Scalars['String'];
};

/** Entity being drafted. */
export type Draftable = {
    __typename?: 'Draftable';
    /** GRID ID of the entity being drafted. */
    id: Scalars['ID'];
    /** Name of the entity being drafted. */
    name: Scalars['String'];
    /** Type of the entity being drafted. */
    type: Scalars['String'];
};

/** Entity performing a draft action. */
export type Drafter = {
    __typename?: 'Drafter';
    /** GRID ID of the entity performing a draft action. */
    id: Scalars['ID'];
    /** Type of entity performing a draft action. */
    type: Scalars['String'];
};

export enum Enum_Componentbuttonsbutton_Size {
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
    Xsmall = 'xsmall',
}

export enum Enum_Componentbuttonsbutton_Variant {
    Dark = 'dark',
    Light = 'light',
    Primary = 'primary',
    Secondary = 'secondary',
    Slate = 'slate',
    White = 'white',
}

export enum Enum_Componentcircuithubsectionimage_Position {
    Left = 'left',
    Right = 'right',
    Stretch = 'stretch',
}

export enum Enum_Componentcircuithubsectionstyle_Backgroundtype {
    DiagonalDown = 'diagonalDown',
    DiagonalUp = 'diagonalUp',
    None = 'none',
    Rectangle = 'rectangle',
}

export enum Enum_Componentcircuithubsectionstyle_Verticalspace {
    Both = 'both',
    Bottom = 'bottom',
    None = 'none',
    Top = 'top',
}

export enum Enum_Componentcircuithubtournamentconfig_Status {
    Completed = 'completed',
    Live = 'live',
    Upcoming = 'upcoming',
}

export enum Enum_Componentcircuithubvideo_Position {
    Left = 'left',
    Right = 'right',
    Stretch = 'stretch',
}

export enum Enum_Componentpagecomponentsgallery_Location {
    SponsorBarReplacement = 'sponsorBarReplacement',
    WatchTeamBottomLeft = 'watchTeamBottomLeft',
    WatchTeamBottomRight = 'watchTeamBottomRight',
    WatchTournamentBottomLeft = 'watchTournamentBottomLeft',
    WatchTournamentBottomMiddle = 'watchTournamentBottomMiddle',
    WatchTournamentBottomRight = 'watchTournamentBottomRight',
}

export enum Enum_Componentpagecomponentspromobanner_Location {
    BestOdds = 'bestOdds',
    Default = 'default',
}

export enum Enum_Componentpagecomponentsvisualisationswidget_Type {
    LiveLog = 'live_log',
    MapSchematic = 'map_schematic',
    PredictionTracker = 'prediction_tracker',
    SeriesComparison = 'series_comparison',
    SeriesList = 'series_list',
    SeriesScoreboard = 'series_scoreboard',
    SeriesTable = 'series_table',
    TeamScoreboard = 'team_scoreboard',
    Timeline = 'timeline',
    TournamentInformations = 'tournament_informations',
    TournamentRanking = 'tournament_ranking',
}

export enum Enum_Documentationarticle_Category {
    ApiDocumentation = 'API_Documentation',
    ApiReference = 'API_Reference',
    Generic = 'Generic',
    Recipe = 'Recipe',
    Tutorial = 'Tutorial',
}

export enum Enum_Documentationpage_Category {
    CentralDataFeed = 'Central_Data_Feed',
    Documentation = 'Documentation',
    ErrorHandling = 'Error_Handling',
    LiveDataFeed = 'Live_Data_Feed',
}

export enum Enum_Gallery_Cta_Position {
    BottomLeft = 'bottom_left',
    BottomRight = 'bottom_right',
    TopLeft = 'top_left',
    TopRight = 'top_right',
}

export enum Enum_Gallery_Layout {
    NoPreview = 'no_preview',
    PreviewBottom = 'preview_bottom',
    PreviewLeft = 'preview_left',
    PreviewRight = 'preview_right',
    PreviewTop = 'preview_top',
}

export enum Enum_Gallery_Share_Position {
    BottomLeft = 'bottom_left',
    BottomRight = 'bottom_right',
    TopLeft = 'top_left',
    TopRight = 'top_right',
}

export enum Enum_Merchandisebanner_Cta_Variant {
    DarkGray = 'darkGray',
    Ghost = 'ghost',
    LightGray = 'lightGray',
    Primary = 'primary',
    Secondary = 'secondary',
    White = 'white',
}

export enum Enum_Merchandisebanner_Variant {
    Tall = 'tall',
    Wide = 'wide',
}

export enum Enum_Page_Page_Type {
    Watch = 'watch',
    WatchTournament = 'watch_tournament',
}

export type EditContentPermissions = {
    __typename?: 'EditContentPermissions';
    organisationContentBundles?: Maybe<Array<Maybe<ContentBundle>>>;
    organisationContentPermissions?: Maybe<Array<Maybe<ContentPermissionRule>>>;
    userContentBundles?: Maybe<Array<Maybe<ContentBundle>>>;
    userContentPermissions?: Maybe<Array<Maybe<ContentPermissionRule>>>;
};

export type EditOrganisationPayload = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type EndSeriesByIdPayload = {
    id?: Maybe<Scalars['String']>;
};

export type Entity = {
    __typename?: 'Entity';
    id: Scalars['ID'];
    type: Scalars['String'];
};

export type EntityContentPermissionInput = {
    id: Scalars['ID'];
};

export type EntityContentPermissionsOrganisationsPayload = {
    __typename?: 'EntityContentPermissionsOrganisationsPayload';
    organisations: Array<ContentPermissionOrganisation>;
    users: Array<ContentPermissionUser>;
};

export type EntityFilter = {
    type?: Maybe<StringFilter>;
};

export type Error = {
    __typename?: 'Error';
    code: Scalars['String'];
    message: Scalars['String'];
    path?: Maybe<Scalars['String']>;
};

export enum ErrorDetail {
    /**
     * The deadline expired before the operation could complete.
     *
     * For operations that change the state of the system, this error
     * may be returned even if the operation has completed successfully.
     * For example, a successful response from a server could have been
     * delayed long enough for the deadline to expire.
     *
     * HTTP Mapping: 504 Gateway Timeout
     * Error Type: UNAVAILABLE
     */
    DeadlineExceeded = 'DEADLINE_EXCEEDED',
    /**
     * The server detected that the client is exhibiting a behavior that
     * might be generating excessive load.
     *
     * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
     * Error Type: UNAVAILABLE
     */
    EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
    /**
     * The requested field is not found in the schema.
     *
     * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
     * query is valid, but is unable to return a result (if, for example, a
     * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
     * returned by the server to signify that the requested field is not known to exist.
     * This may be returned in lieu of failing the entire query.
     * See also `PERMISSION_DENIED` for cases where the
     * requested field is invalid only for the given user or class of users.
     *
     * HTTP Mapping: 404 Not Found
     * Error Type: BAD_REQUEST
     */
    FieldNotFound = 'FIELD_NOT_FOUND',
    /**
     * The client specified an invalid argument.
     *
     * Note that this differs from `FAILED_PRECONDITION`.
     * `INVALID_ARGUMENT` indicates arguments that are problematic
     * regardless of the state of the system (e.g., a malformed file name).
     *
     * HTTP Mapping: 400 Bad Request
     * Error Type: BAD_REQUEST
     */
    InvalidArgument = 'INVALID_ARGUMENT',
    /**
     * The provided cursor is not valid.
     *
     * The most common usage for this error is when a client is paginating
     * through a list that uses stateful cursors. In that case, the provided
     * cursor may be expired.
     *
     * HTTP Mapping: 404 Not Found
     * Error Type: NOT_FOUND
     */
    InvalidCursor = 'INVALID_CURSOR',
    /**
     * Unable to perform operation because a required resource is missing.
     *
     * Example: Client is attempting to refresh a list, but the specified
     * list is expired. This requires an action by the client to get a new list.
     *
     * If the user is simply trying GET a resource that is not found,
     * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
     * is to be used particularly when the user is performing an operation
     * that requires a particular resource to exist.
     *
     * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
     * Error Type: FAILED_PRECONDITION
     */
    MissingResource = 'MISSING_RESOURCE',
    /**
     * Service Error.
     *
     * There is a problem with an upstream service.
     *
     * This may be returned if a gateway receives an unknown error from a service
     * or if a service is unreachable.
     * If a request times out which waiting on a response from a service,
     * `DEADLINE_EXCEEDED` may be returned instead.
     * If a service returns a more specific error Type, the specific error Type may
     * be returned instead.
     *
     * HTTP Mapping: 502 Bad Gateway
     * Error Type: UNAVAILABLE
     */
    ServiceError = 'SERVICE_ERROR',
    /**
     * Request failed due to network errors.
     *
     * HTTP Mapping: 503 Unavailable
     * Error Type: UNAVAILABLE
     */
    TcpFailure = 'TCP_FAILURE',
    /**
     * Request throttled based on server concurrency limits.
     *
     * HTTP Mapping: 503 Unavailable
     * Error Type: UNAVAILABLE
     */
    ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
    /**
     * Request throttled based on server CPU limits
     *
     * HTTP Mapping: 503 Unavailable.
     * Error Type: UNAVAILABLE
     */
    ThrottledCpu = 'THROTTLED_CPU',
    /**
     * The operation is not implemented or is not currently supported/enabled.
     *
     * HTTP Mapping: 501 Not Implemented
     * Error Type: BAD_REQUEST
     */
    Unimplemented = 'UNIMPLEMENTED',
    /**
     * Unknown error.
     *
     * This error should only be returned when no other error detail applies.
     * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
     *
     * HTTP Mapping: 500 Internal Server Error
     */
    Unknown = 'UNKNOWN',
}

export enum ErrorType {
    /**
     * Bad Request.
     *
     * There is a problem with the request.
     * Retrying the same request is not likely to succeed.
     * An example would be a query or argument that cannot be deserialized.
     *
     * HTTP Mapping: 400 Bad Request
     */
    BadRequest = 'BAD_REQUEST',
    /**
     * The operation was rejected because the system is not in a state
     * required for the operation's execution.  For example, the directory
     * to be deleted is non-empty, an rmdir operation is applied to
     * a non-directory, etc.
     *
     * Service implementers can use the following guidelines to decide
     * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
     *
     * - Use `UNAVAILABLE` if the client can retry just the failing call.
     * - Use `FAILED_PRECONDITION` if the client should not retry until
     * the system state has been explicitly fixed.  E.g., if an "rmdir"
     *      fails because the directory is non-empty, `FAILED_PRECONDITION`
     * should be returned since the client should not retry unless
     * the files are deleted from the directory.
     *
     * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
     */
    FailedPrecondition = 'FAILED_PRECONDITION',
    /**
     * Internal error.
     *
     * An unexpected internal error was encountered. This means that some
     * invariants expected by the underlying system have been broken.
     * This error code is reserved for serious errors.
     *
     * HTTP Mapping: 500 Internal Server Error
     */
    Internal = 'INTERNAL',
    /**
     * The requested entity was not found.
     *
     * This could apply to a resource that has never existed (e.g. bad resource id),
     * or a resource that no longer exists (e.g. cache expired.)
     *
     * Note to server developers: if a request is denied for an entire class
     * of users, such as gradual feature rollout or undocumented allowlist,
     * `NOT_FOUND` may be used. If a request is denied for some users within
     * a class of users, such as user-based access control, `PERMISSION_DENIED`
     * must be used.
     *
     * HTTP Mapping: 404 Not Found
     */
    NotFound = 'NOT_FOUND',
    /**
     * The caller does not have permission to execute the specified
     * operation.
     *
     * `PERMISSION_DENIED` must not be used for rejections
     * caused by exhausting some resource or quota.
     * `PERMISSION_DENIED` must not be used if the caller
     * cannot be identified (use `UNAUTHENTICATED`
     * instead for those errors).
     *
     * This error Type does not imply the
     * request is valid or the requested entity exists or satisfies
     * other pre-conditions.
     *
     * HTTP Mapping: 403 Forbidden
     */
    PermissionDenied = 'PERMISSION_DENIED',
    /**
     * The request does not have valid authentication credentials.
     *
     * This is intended to be returned only for routes that require
     * authentication.
     *
     * HTTP Mapping: 401 Unauthorized
     */
    Unauthenticated = 'UNAUTHENTICATED',
    /**
     * Currently Unavailable.
     *
     * The service is currently unavailable.  This is most likely a
     * transient condition, which can be corrected by retrying with
     * a backoff.
     *
     * HTTP Mapping: 503 Unavailable
     */
    Unavailable = 'UNAVAILABLE',
    /**
     * Unknown error.
     *
     * For example, this error may be returned when
     * an error code received from another address space belongs to
     * an error space that is not known in this address space.  Also
     * errors raised by APIs that do not return enough error information
     * may be converted to this error.
     *
     * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
     * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
     * by an implementation as being equivalent to INTERNAL.
     *
     * When possible, a more specific error should be provided.
     *
     * HTTP Mapping: 520 Unknown Error
     */
    Unknown = 'UNKNOWN',
}

export type EsportsOrganizationsProps = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    name?: Maybe<StringFilter>;
};

export type Event = {
    __typename?: 'Event';
    action: Action;
    actor: Entity;
    data: Scalars['JSON'];
    gameTime: Scalars['String'];
    highlight: Scalars['Boolean'];
    id: Scalars['ID'];
    revertedEventId?: Maybe<Scalars['ID']>;
    sentenceChunks?: Maybe<Array<SentenceChunk>>;
    target: Entity;
    transaction: Transaction;
    type: Scalars['String'];
};

export type EventConnection = {
    __typename?: 'EventConnection';
    edges: Array<EventEdge>;
    pageInfo: PageInfo;
};

export type EventEdge = {
    __typename?: 'EventEdge';
    cursor: Scalars['String'];
    node: Event;
};

export type EventFilter = {
    type?: Maybe<StringFilter>;
};

export type EventsFilter = {
    action?: Maybe<Array<ActionFilter>>;
    actor?: Maybe<Array<EntityFilter>>;
    entity?: Maybe<Array<EntityFilter>>;
    event?: Maybe<Array<EventFilter>>;
    target?: Maybe<Array<EntityFilter>>;
};

export type ExtendedSeriesInfo = {
    __typename?: 'ExtendedSeriesInfo';
    format?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    scheduledStartTime?: Maybe<Scalars['String']>;
    scheduledStartTimeEpochSeconds?: Maybe<Scalars['String']>;
    teams?: Maybe<Array<Maybe<BaseTeamInfo>>>;
    title?: Maybe<BaseTitleInfo>;
    tournament?: Maybe<BaseTournamentInfo>;
};

/** An external entity representing the entity it is embedded in. */
export type ExternalEntity = {
    __typename?: 'ExternalEntity';
    /** ID representing this entity */
    id: Scalars['ID'];
};

/** The field for external id value. */
export type ExternalEntityInput = {
    /** The external id value.  */
    id: Scalars['ID'];
};

/** A link to an external entity given via an ID (available from version "1.2"). */
export type ExternalLink = {
    __typename?: 'ExternalLink';
    /** A data provider which provides external entity IDs. */
    dataProvider: DataProvider;
    /** An external entity representing the entity it is embedded in. */
    externalEntity: ExternalEntity;
};

/** Filter for external link data points. */
export type ExternalLinkFilter = {
    dataProviderName: StringFilter;
    externalIds: IdFilter;
};

/** The fields the external entity consist of. */
export type ExternalLinkInput = {
    /** The data provider name. Strict control over possible values. */
    dataProviderName: Scalars['String'];
    /** The external id value. */
    externalEntityInput: ExternalEntityInput;
};

export type ExternalSeriesLink = {
    __typename?: 'ExternalSeriesLink';
    dataProvider: DataProvider;
    externalEntity: ExternalEntity;
    isPaused: Scalars['Boolean'];
};

export type FileInfoInput = {
    alternativeText?: Maybe<Scalars['String']>;
    caption?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

/** Union Type of all registered slug content types */
export type FindSlugResponse = DocumentationArticleEntityResponse;

export type ForgotPasswordPayload = {
    email: Scalars['String'];
    recaptchaToken?: Maybe<Scalars['String']>;
};

export type Gallery = {
    __typename?: 'Gallery';
    created_at: Scalars['DateTime'];
    cta_position?: Maybe<Enum_Gallery_Cta_Position>;
    facebook_social_share?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    images?: Maybe<Array<Maybe<ComponentImagesGalleryImages>>>;
    layout?: Maybe<Enum_Gallery_Layout>;
    name?: Maybe<Scalars['String']>;
    share_position?: Maybe<Enum_Gallery_Share_Position>;
    sites?: Maybe<Array<Maybe<Site>>>;
    title?: Maybe<Scalars['String']>;
    twitter_social_share?: Maybe<Scalars['Boolean']>;
    updated_at: Scalars['DateTime'];
};

export type GallerySitesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type GalleryAggregator = {
    __typename?: 'GalleryAggregator';
    count?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type GalleryConnection = {
    __typename?: 'GalleryConnection';
    aggregate?: Maybe<GalleryAggregator>;
    groupBy?: Maybe<GalleryGroupBy>;
    values?: Maybe<Array<Maybe<Gallery>>>;
};

export type GalleryConnectionCreated_At = {
    __typename?: 'GalleryConnectionCreated_at';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type GalleryConnectionCta_Position = {
    __typename?: 'GalleryConnectionCta_position';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['String']>;
};

export type GalleryConnectionFacebook_Social_Share = {
    __typename?: 'GalleryConnectionFacebook_social_share';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['Boolean']>;
};

export type GalleryConnectionId = {
    __typename?: 'GalleryConnectionId';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type GalleryConnectionLayout = {
    __typename?: 'GalleryConnectionLayout';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['String']>;
};

export type GalleryConnectionName = {
    __typename?: 'GalleryConnectionName';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['String']>;
};

export type GalleryConnectionShare_Position = {
    __typename?: 'GalleryConnectionShare_position';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['String']>;
};

export type GalleryConnectionTitle = {
    __typename?: 'GalleryConnectionTitle';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['String']>;
};

export type GalleryConnectionTwitter_Social_Share = {
    __typename?: 'GalleryConnectionTwitter_social_share';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['Boolean']>;
};

export type GalleryConnectionUpdated_At = {
    __typename?: 'GalleryConnectionUpdated_at';
    connection?: Maybe<GalleryConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type GalleryGroupBy = {
    __typename?: 'GalleryGroupBy';
    created_at?: Maybe<Array<Maybe<GalleryConnectionCreated_At>>>;
    cta_position?: Maybe<Array<Maybe<GalleryConnectionCta_Position>>>;
    facebook_social_share?: Maybe<Array<Maybe<GalleryConnectionFacebook_Social_Share>>>;
    id?: Maybe<Array<Maybe<GalleryConnectionId>>>;
    layout?: Maybe<Array<Maybe<GalleryConnectionLayout>>>;
    name?: Maybe<Array<Maybe<GalleryConnectionName>>>;
    share_position?: Maybe<Array<Maybe<GalleryConnectionShare_Position>>>;
    title?: Maybe<Array<Maybe<GalleryConnectionTitle>>>;
    twitter_social_share?: Maybe<Array<Maybe<GalleryConnectionTwitter_Social_Share>>>;
    updated_at?: Maybe<Array<Maybe<GalleryConnectionUpdated_At>>>;
};

export type GalleryInput = {
    created_by?: Maybe<Scalars['ID']>;
    cta_position?: Maybe<Enum_Gallery_Cta_Position>;
    facebook_social_share?: Maybe<Scalars['Boolean']>;
    images?: Maybe<Array<Maybe<ComponentImagesGalleryImageInput>>>;
    layout?: Maybe<Enum_Gallery_Layout>;
    name?: Maybe<Scalars['String']>;
    share_position?: Maybe<Enum_Gallery_Share_Position>;
    sites?: Maybe<Array<Maybe<Scalars['ID']>>>;
    title?: Maybe<Scalars['String']>;
    twitter_social_share?: Maybe<Scalars['Boolean']>;
    updated_by?: Maybe<Scalars['ID']>;
};

/** Filter for series by game data points. */
export type GameFilter = {
    /** Filter by external link data points. */
    externalLinks?: Maybe<ExternalLinkFilter>;
    /** Filter by game IDs. */
    id?: Maybe<IdFilter>;
    /** Filter by game map. */
    map?: Maybe<MapFilter>;
};

/** Data points for a Player, aggregated for a Game */
export type GamePlayerState = {
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<Ability>;
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** CS2 data points for a Player, aggregated for a Game. */
export type GamePlayerStateCs2 = GamePlayerState & {
    __typename?: 'GamePlayerStateCs2';
    /** A list of abilities that are owned by this Player in this Game. */
    abilities: Array<DefaultAbility>;
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The amount of current armor. */
    currentArmor: Scalars['Int'];
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs. */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game. */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** CSGO data points for a Player, aggregated for a Game. */
export type GamePlayerStateCsgo = GamePlayerState & {
    __typename?: 'GamePlayerStateCsgo';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<DefaultAbility>;
    /** Indicates whether the player is alive (available from version "1.5"). */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The amount of current armor. */
    currentArmor: Scalars['Int'];
    /** The current health of the player (available from version "1.5"). */
    currentHealth: Scalars['Int'];
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs (available from version "2.2"). */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player (available from version "1.5"). */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Default data points for a Player, aggregated for a Game */
export type GamePlayerStateDefault = GamePlayerState & {
    __typename?: 'GamePlayerStateDefault';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<DefaultAbility>;
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Dota data points for a Player, aggregated for a Game. */
export type GamePlayerStateDota = GamePlayerState & {
    __typename?: 'GamePlayerStateDota';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<DefaultAbility>;
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The current health of the player (available from version "1.6"). */
    currentHealth: Scalars['Int'];
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** The amount of experience points gathered by this player. */
    experiencePoints: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs. (available from version "3.6") */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player (available from version "1.6"). */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** LoL data points for a Player, aggregated for a Game. */
export type GamePlayerStateLol = GamePlayerState & {
    __typename?: 'GamePlayerStateLol';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<DefaultAbility>;
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The amount of current armor. */
    currentArmor: Scalars['Int'];
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** The amount of experience points gathered by this player. */
    experiencePoints: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** Respawn clock state to indicate when this player respawns (available from version "3.3"). */
    respawnClock: ClockState;
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** The total amount of money that was earned by this player (available from version "3.2"). */
    totalMoneyEarned: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** PUBG data points for a Player, aggregated for a Game. */
export type GamePlayerStatePubg = GamePlayerState & {
    __typename?: 'GamePlayerStatePubg';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<DefaultAbility>;
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** R6 data points for a Player, aggregated for a Game */
export type GamePlayerStateR6 = GamePlayerState & {
    __typename?: 'GamePlayerStateR6';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<DefaultAbility>;
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** The amount of healing dealt by team. */
    healingDealt: Scalars['Int'];
    /** The amount of healing received by team. */
    healingReceived: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Number of times of knocking down an enemy for this player (available from version "3.9"). */
    knockdownsGiven: Scalars['Int'];
    /** Number of times of being knocked down for this player (available from version "3.9"). */
    knockdownsReceived: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Valorant data points for a Player, aggregated for a Game. */
export type GamePlayerStateValorant = GamePlayerState & {
    __typename?: 'GamePlayerStateValorant';
    /** A list of abilities that are owned by this Player in this Game (available from version "1.8"). */
    abilities: Array<AbilityValorant>;
    /** Indicates whether the player is alive (available from version "1.9"). */
    alive: Scalars['Boolean'];
    /** In-game character (ie champion, class) of the Player in this Game */
    character?: Maybe<Character>;
    /** The amount of current armor (available from version "1.9"). */
    currentArmor: Scalars['Int'];
    /** The current health of the player (available from version "1.9"). */
    currentHealth: Scalars['Int'];
    /** Number of deaths for this Player in this Game. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** The Player's inventory */
    inventory: PlayerInventory;
    /** Number of enemy kill assists provided by this Player in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of this Player’s loadout. */
    loadoutValue: Scalars['Int'];
    /** The max amount of health of the player (available from version "1.9"). */
    maxHealth: Scalars['Int'];
    /**
     * Amount of money this Player is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Player. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Player in this Game. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Game */
    participationStatus: ParticipationStatus;
    /** Player Coordinates on the map */
    position?: Maybe<Coordinates>;
    /** Number of selfkills (suicides) for this Player in this Game. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of team headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** Number of ultimate points of this Player. (available from version "3.20") */
    ultimatePoints: Scalars['Int'];
    /** A list of unit kills that happened by this Player in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Data points for a Game. */
export type GameState = {
    __typename?: 'GameState';
    /** Clock state to indicate time of the game (available from version "1.3") */
    clock?: Maybe<ClockState>;
    /** A list of DraftAction, containing information about draft actions in this Game. */
    draftActions: Array<DraftAction>;
    /** Duration of the Game (available from version "3.16"). */
    duration: Scalars['Duration'];
    /** A list of ExternalLink, containing information about links to externally provided IDs (available from version "1.2"). */
    externalLinks: Array<ExternalLink>;
    /** Indicates whether this Game has finished. */
    finished: Scalars['Boolean'];
    /** GRID ID of this Game. */
    id: Scalars['ID'];
    /** Information on the map of this Game. */
    map: MapState;
    /** A list of NonPlayerCharacterState, containing information about the state of NPCs in this Game. */
    nonPlayerCharacters: Array<NonPlayerCharacterState>;
    /** Indicates whether this Game is paused. */
    paused: Scalars['Boolean'];
    /** A list of SegmentStates, containing information of the segments in this Game (available from version "1.4"). */
    segments: Array<SegmentState>;
    /** SequenceNumber of the Game state update. */
    sequenceNumber: Scalars['Int'];
    /** Indicates whether this Game has started. */
    started: Scalars['Boolean'];
    /** DateTime value when this Game was started (available from version "3.7"). */
    startedAt?: Maybe<Scalars['DateTime']>;
    /** A list of StructureState, containing information about the structures that are available in this Game. */
    structures: Array<StructureState>;
    /** A list of GameTeamState, containing information on the teams participating in this Game. */
    teams: Array<GameTeamState>;
    /** titleVersion indicates the game version when available (available from version "3.12") */
    titleVersion?: Maybe<TitleVersion>;
    /** type indicates the game type (available from version "3.21") */
    type?: Maybe<GameType>;
};

/** Generic filter that can be used to query for Games that have/haven’t started and are/aren’t finished. */
export type GameStateFilter = {
    /** Filter on whether a Game has finished. */
    finished?: Maybe<Scalars['Boolean']>;
    /** Filter on whether a Game has started. */
    started?: Maybe<Scalars['Boolean']>;
};

/** Data points for a Team, aggregated for a Game. */
export type GameTeamState = {
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** CS2 data points for a Team, aggregated for a Game. */
export type GameTeamStateCs2 = GameTeamState & {
    __typename?: 'GameTeamStateCs2';
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** CSGO data points for a Team, aggregated for a Game. */
export type GameTeamStateCsgo = GameTeamState & {
    __typename?: 'GameTeamStateCsgo';
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

export type GameTeamStateDefault = GameTeamState & {
    __typename?: 'GameTeamStateDefault';
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** Dota data points for a Team, aggregated for a Game. */
export type GameTeamStateDota = GameTeamState & {
    __typename?: 'GameTeamStateDota';
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** The amount of experience points gathered by this team. */
    experiencePoints: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** LoL data points for a Team, aggregated for a Game. */
export type GameTeamStateLol = GameTeamState & {
    __typename?: 'GameTeamStateLol';
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** The amount of experience points gathered by this team. */
    experiencePoints: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** The total amount of money that was earned by this team (available from version "3.2"). */
    totalMoneyEarned: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** PUBG data points for a Team, aggregated for a Game. */
export type GameTeamStatePubg = GameTeamState & {
    __typename?: 'GameTeamStatePubg';
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

export type GameTeamStateR6 = GameTeamState & {
    __typename?: 'GameTeamStateR6';
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** The amount of healing dealt by team. */
    healingDealt: Scalars['Int'];
    /** The amount of healing received by team. */
    healingReceived: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Number of times of knocking down an enemy for this team (available from version "3.9"). */
    knockdownsGiven: Scalars['Int'];
    /** Number of times of being knocked down for this team (available from version "3.9"). */
    knockdownsReceived: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** Valorant data points for a Team, aggregated for a Game. */
export type GameTeamStateValorant = GameTeamState & {
    __typename?: 'GameTeamStateValorant';
    /** Number of deaths for this Team in this Game. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Game. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Game. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Game. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Game. */
    kills: Scalars['Int'];
    /** Total value (worth in money) of all the loadouts owned by Players that are members of this Team. */
    loadoutValue: Scalars['Int'];
    /**
     * Amount of money this Team is holding in cash.
     * Depending on the Title this may represent in-game gold, credits, gems etc.
     */
    money: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Game (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this Team. */
    name: Scalars['String'];
    /** Sum of money and inventoryValue for this Team. */
    netWorth: Scalars['Int'];
    /** A list of objectives that were fulfilled by this Team in this Game. */
    objectives: Array<Objective>;
    /** A list of GamePlayerState, containing information about the Players that are members of this Team. */
    players: Array<GamePlayerState>;
    /** Score for this Team in this Game. This is used in the Score After format. */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Game. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Game ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of structures captured by this Team in this Game. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Team in this Game. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of unit kills that happened by this Team in this Game (available from version "3.1"). */
    unitKills: Array<UnitKill>;
    /** A list of enemy kills, which were executed with a specific weapon by this Team in this Game. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Team in this Game.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Game. */
    won: Scalars['Boolean'];
};

/** The type of game. */
export enum GameType {
    /** A competitive non-esports series. */
    Competitive = 'COMPETITIVE',
    /** An esports game. */
    Esports = 'ESPORTS',
    /** A regular non-competitive game. */
    Regular = 'REGULAR',
    /** A practice competitive game. */
    Scrim = 'SCRIM',
}

export type GetContentCatalogItemsPayload = {
    __typename?: 'GetContentCatalogItemsPayload';
    edges?: Maybe<Array<Node>>;
};

export type GetContentCatalogVersionsPayload = {
    __typename?: 'GetContentCatalogVersionsPayload';
    edges?: Maybe<Array<Node>>;
};

export type GetContentPermissions = {
    __typename?: 'GetContentPermissions';
    organisationContentBundles?: Maybe<Array<Maybe<ContentBundle>>>;
    organisationContentPermissions?: Maybe<Array<Maybe<ContentPermissionRule>>>;
    userContentBundles?: Maybe<Array<Maybe<ContentBundle>>>;
    userContentPermissions?: Maybe<Array<Maybe<ContentPermissionRule>>>;
};

export type GetSeriesFilesPayload = {
    __typename?: 'GetSeriesFilesPayload';
    files: Array<AvailableFile>;
};

export type GetUserVerifyRegistrationPayload = {
    __typename?: 'GetUserVerifyRegistrationPayload';
    isSuccessful: Scalars['Boolean'];
    statusCode: Scalars['Int'];
};

export type IdFilterInput = {
    and?: Maybe<Array<Maybe<Scalars['ID']>>>;
    between?: Maybe<Array<Maybe<Scalars['ID']>>>;
    contains?: Maybe<Scalars['ID']>;
    containsi?: Maybe<Scalars['ID']>;
    endsWith?: Maybe<Scalars['ID']>;
    eq?: Maybe<Scalars['ID']>;
    eqi?: Maybe<Scalars['ID']>;
    gt?: Maybe<Scalars['ID']>;
    gte?: Maybe<Scalars['ID']>;
    in?: Maybe<Array<Maybe<Scalars['ID']>>>;
    lt?: Maybe<Scalars['ID']>;
    lte?: Maybe<Scalars['ID']>;
    ne?: Maybe<Scalars['ID']>;
    not?: Maybe<IdFilterInput>;
    notContains?: Maybe<Scalars['ID']>;
    notContainsi?: Maybe<Scalars['ID']>;
    notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
    notNull?: Maybe<Scalars['Boolean']>;
    null?: Maybe<Scalars['Boolean']>;
    or?: Maybe<Array<Maybe<Scalars['ID']>>>;
    startsWith?: Maybe<Scalars['ID']>;
};

export type IpWhitelistPayload = {
    __typename?: 'IPWhitelistPayload';
    allowedIpRanges: Array<WhitelistedIp>;
};

export type IdContext = {
    gridId?: Maybe<Scalars['String']>;
    mappedId?: Maybe<Scalars['String']>;
};

/** ID filter for entity identifiers. */
export type IdFilter = {
    /** Array of IDs to look for. */
    in?: Maybe<Array<Scalars['ID']>>;
};

export type InputId = {
    id: Scalars['ID'];
};

export type IntFilterInput = {
    and?: Maybe<Array<Maybe<Scalars['Int']>>>;
    between?: Maybe<Array<Maybe<Scalars['Int']>>>;
    contains?: Maybe<Scalars['Int']>;
    containsi?: Maybe<Scalars['Int']>;
    endsWith?: Maybe<Scalars['Int']>;
    eq?: Maybe<Scalars['Int']>;
    eqi?: Maybe<Scalars['Int']>;
    gt?: Maybe<Scalars['Int']>;
    gte?: Maybe<Scalars['Int']>;
    in?: Maybe<Array<Maybe<Scalars['Int']>>>;
    lt?: Maybe<Scalars['Int']>;
    lte?: Maybe<Scalars['Int']>;
    ne?: Maybe<Scalars['Int']>;
    not?: Maybe<IntFilterInput>;
    notContains?: Maybe<Scalars['Int']>;
    notContainsi?: Maybe<Scalars['Int']>;
    notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
    notNull?: Maybe<Scalars['Boolean']>;
    null?: Maybe<Scalars['Boolean']>;
    or?: Maybe<Array<Maybe<Scalars['Int']>>>;
    startsWith?: Maybe<Scalars['Int']>;
};

export type IpWhitelistAllowInput = {
    allowIpRange: Scalars['String'];
};

export type IpWhitelistDenyInput = {
    allowedIpRanges: Array<Scalars['String']>;
};

/** An item stack that is part of a player's inventory. */
export type ItemStack = {
    __typename?: 'ItemStack';
    /** The amount of equipped items. */
    equipped: Scalars['Int'];
    /** GRID ID for this item. */
    id: Scalars['ID'];
    /** The name of this item. */
    name: Scalars['String'];
    /** The amount of items in this stack (includes equipped and stashed items). */
    quantity: Scalars['Int'];
    /** The amount of stashed items. */
    stashed: Scalars['Int'];
};

export type JsonFilterInput = {
    and?: Maybe<Array<Maybe<Scalars['JSON']>>>;
    between?: Maybe<Array<Maybe<Scalars['JSON']>>>;
    contains?: Maybe<Scalars['JSON']>;
    containsi?: Maybe<Scalars['JSON']>;
    endsWith?: Maybe<Scalars['JSON']>;
    eq?: Maybe<Scalars['JSON']>;
    eqi?: Maybe<Scalars['JSON']>;
    gt?: Maybe<Scalars['JSON']>;
    gte?: Maybe<Scalars['JSON']>;
    in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
    lt?: Maybe<Scalars['JSON']>;
    lte?: Maybe<Scalars['JSON']>;
    ne?: Maybe<Scalars['JSON']>;
    not?: Maybe<JsonFilterInput>;
    notContains?: Maybe<Scalars['JSON']>;
    notContainsi?: Maybe<Scalars['JSON']>;
    notIn?: Maybe<Array<Maybe<Scalars['JSON']>>>;
    notNull?: Maybe<Scalars['Boolean']>;
    null?: Maybe<Scalars['Boolean']>;
    or?: Maybe<Array<Maybe<Scalars['JSON']>>>;
    startsWith?: Maybe<Scalars['JSON']>;
};

/** Kill assist information. */
export type KillAssistFromPlayer = {
    __typename?: 'KillAssistFromPlayer';
    /** GRID ID for this assist. */
    id: Scalars['ID'];
    /** Number of kill assists received from the assisting Player. */
    killAssistsReceived: Scalars['Int'];
    /** GRID Player ID assisting. */
    playerId: Scalars['ID'];
};

export type MachineDataSource = {
    __typename?: 'MachineDataSource';
    id: Scalars['ID'];
};

/** ID filter for machine data source ID. */
export type MachineDataSourceFilter = {
    id: Scalars['ID'];
};

export type MachineDataSourceInput = {
    /** Can explictly be set to null to remove machine source link */
    id?: Maybe<Scalars['ID']>;
};

/** Filter for map data points. */
export type MapFilter = {
    /** Filter by map name. */
    name?: Maybe<StringFilter>;
};

/** Map information */
export type MapState = {
    __typename?: 'MapState';
    /** Map Bounds information */
    bounds?: Maybe<Bounds>;
    /** GRID Map ID. */
    id: Scalars['String'];
    /** Map name */
    name: Scalars['String'];
};

export type MerchandiseBanner = {
    __typename?: 'MerchandiseBanner';
    background_image?: Maybe<UploadFile>;
    created_at: Scalars['DateTime'];
    cta_link?: Maybe<Scalars['String']>;
    cta_text?: Maybe<Scalars['String']>;
    cta_variant?: Maybe<Enum_Merchandisebanner_Cta_Variant>;
    foreground_image?: Maybe<UploadFile>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    text_line_1?: Maybe<Scalars['String']>;
    text_line_2?: Maybe<Scalars['String']>;
    updated_at: Scalars['DateTime'];
    variant: Enum_Merchandisebanner_Variant;
};

export type Metadata = {
    commandId: Scalars['String'];
    sequenceNumber: Scalars['Int'];
    unixTimestampMs: Scalars['Int'];
};

/** A monetary value. */
export type Money = {
    __typename?: 'Money';
    /** The amount in USD. */
    amount: Scalars['Decimal'];
};

export type MoneyInput = {
    /** The amount of the prize pool. */
    amount?: Maybe<Scalars['Decimal']>;
};

export type Morph =
    | BaseSeriesInfo
    | BaseTeamInfo
    | BaseTitleInfo
    | BaseTournamentInfo
    | Bestodds
    | BestoddsProvider
    | BettingProviders
    | Brand
    | CircuitHub
    | CircuitHubAggregator
    | CircuitHubConnection
    | CircuitHubConnectionBanner
    | CircuitHubConnectionCreated_At
    | CircuitHubConnectionFooter
    | CircuitHubConnectionId
    | CircuitHubConnectionNavigation
    | CircuitHubConnectionPublished_At
    | CircuitHubConnectionSite
    | CircuitHubConnectionTourLogo
    | CircuitHubConnectionUpdated_At
    | CircuitHubGroupBy
    | ComponentButtonsButton
    | ComponentCircuitHubBackButton
    | ComponentCircuitHubBasicSection
    | ComponentCircuitHubExternalContent
    | ComponentCircuitHubFooter
    | ComponentCircuitHubPartnersSection
    | ComponentCircuitHubPointsSection
    | ComponentCircuitHubSectionImage
    | ComponentCircuitHubSectionPoint
    | ComponentCircuitHubSectionStyle
    | ComponentCircuitHubSectionTab
    | ComponentCircuitHubSectionText
    | ComponentCircuitHubTabsSection
    | ComponentCircuitHubTournamentConfig
    | ComponentCircuitHubTournaments
    | ComponentCircuitHubVideo
    | ComponentFeaturesFeatures
    | ComponentGenericNavigation
    | ComponentGenericNavigationItem
    | ComponentGenericOverrides
    | ComponentImagesGalleryImages
    | ComponentImagesImage
    | ComponentPageComponentsBestOdds
    | ComponentPageComponentsGallery
    | ComponentPageComponentsMeta
    | ComponentPageComponentsPromoBanner
    | ComponentPageComponentsSponsors
    | ComponentPageComponentsVisualisationsWidget
    | ComponentSocialSocials
    | ContentPage
    | ContentPageAggregator
    | ContentPageConnection
    | ContentPageConnectionBackbutton
    | ContentPageConnectionCircuit_Hub
    | ContentPageConnectionCreated_At
    | ContentPageConnectionFooter
    | ContentPageConnectionId
    | ContentPageConnectionMeta
    | ContentPageConnectionNavigation
    | ContentPageConnectionPublished_At
    | ContentPageConnectionTitle
    | ContentPageConnectionUpdated_At
    | ContentPageGroupBy
    | DocumentationPage
    | DocumentationPageAggregator
    | DocumentationPageAggregatorAvg
    | DocumentationPageAggregatorMax
    | DocumentationPageAggregatorMin
    | DocumentationPageAggregatorSum
    | DocumentationPageConnection
    | DocumentationPageConnectionCategory
    | DocumentationPageConnectionContent
    | DocumentationPageConnectionCreated_At
    | DocumentationPageConnectionId
    | DocumentationPageConnectionOrder
    | DocumentationPageConnectionPermissions
    | DocumentationPageConnectionPublished_At
    | DocumentationPageConnectionSlug
    | DocumentationPageConnectionTitle
    | DocumentationPageConnectionUpdated_At
    | DocumentationPageGroupBy
    | ExtendedSeriesInfo
    | Gallery
    | GalleryAggregator
    | GalleryConnection
    | GalleryConnectionCreated_At
    | GalleryConnectionCta_Position
    | GalleryConnectionFacebook_Social_Share
    | GalleryConnectionId
    | GalleryConnectionLayout
    | GalleryConnectionName
    | GalleryConnectionShare_Position
    | GalleryConnectionTitle
    | GalleryConnectionTwitter_Social_Share
    | GalleryConnectionUpdated_At
    | GalleryGroupBy
    | MerchandiseBanner
    | Organisation
    | Page
    | PageAggregator
    | PageAggregatorAvg
    | PageAggregatorMax
    | PageAggregatorMin
    | PageAggregatorSum
    | PageConnection
    | PageConnectionCircuitHub
    | PageConnectionCreated_At
    | PageConnectionId
    | PageConnectionMeta
    | PageConnectionName
    | PageConnectionOverrides
    | PageConnectionPage_Type
    | PageConnectionPriority
    | PageConnectionSite
    | PageConnectionUpdated_At
    | PageGroupBy
    | ProductUpdates
    | ProductUpdatesAggregator
    | ProductUpdatesConnection
    | ProductUpdatesConnectionContent
    | ProductUpdatesConnectionCreated_At
    | ProductUpdatesConnectionDate
    | ProductUpdatesConnectionId
    | ProductUpdatesConnectionPublished_At
    | ProductUpdatesConnectionTitle
    | ProductUpdatesConnectionUpdated_At
    | ProductUpdatesGroupBy
    | Site
    | Sponsor
    | TwitchChannels
    | TwitchChannelsAggregator
    | TwitchChannelsConnection
    | TwitchChannelsConnectionChannel_Id
    | TwitchChannelsConnectionCreated_At
    | TwitchChannelsConnectionId
    | TwitchChannelsConnectionName
    | TwitchChannelsConnectionTournament_Id
    | TwitchChannelsConnectionUpdated_At
    | TwitchChannelsGroupBy
    | UploadFile
    | UserPermissionsPasswordPayload
    | UsersPermissionsLoginPayload
    | UsersPermissionsMe
    | UsersPermissionsMeRole
    | UsersPermissionsPermission
    | UsersPermissionsRole
    | UsersPermissionsUser
    | WatchHubCard
    | Watchhub
    | CreateCircuitHubPayload
    | CreateContentPagePayload
    | CreateDocumentationPagePayload
    | CreateGalleryPayload
    | CreatePagePayload
    | CreateProductUpdatePayload
    | CreateTwitchChannelPayload
    | DeleteCircuitHubPayload
    | DeleteContentPagePayload
    | DeleteDocumentationPagePayload
    | DeleteGalleryPayload
    | DeletePagePayload
    | DeleteProductUpdatePayload
    | DeleteTwitchChannelPayload
    | UpdateCircuitHubPayload
    | UpdateContentPagePayload
    | UpdateDocumentationPagePayload
    | UpdateGalleryPayload
    | UpdatePagePayload
    | UpdateProductUpdatePayload
    | UpdateTwitchChannelPayload;

/** A multikill caused by a player or a team (available from version "1.7"). */
export type Multikill = {
    __typename?: 'Multikill';
    /** Amount of times a specific multikill has happened */
    count: Scalars['Int'];
    /** GRID ID for this multikill */
    id: Scalars['ID'];
    /** The type of multikill */
    numberOfKills: Scalars['Int'];
};

export type Mutation = {
    __typename?: 'Mutation';
    addIpToOrganisationWhitelist?: Maybe<IpWhitelistPayload>;
    addIpToUserWhitelist?: Maybe<IpWhitelistPayload>;
    addTeamSeriesWin?: Maybe<AddTeamSeriesWin>;
    addUser?: Maybe<User>;
    banMapForTeam?: Maybe<Veto>;
    /** Creates a new version for a content catalog item. */
    createContentCatalogItem: CreateContentCatalogItemPayload;
    /** Creates a new content catalog version. */
    createContentCatalogVersion: CreateContentCatalogVersionPayload;
    createDocumentationArticleLocalization?: Maybe<DocumentationArticleEntityResponse>;
    createOrganisation?: Maybe<Organisation>;
    /** Creates a new organization. */
    createOrganization: CreateOrganizationPayload;
    /** Creates a new player. */
    createPlayer: CreatePlayerPayload;
    /** Creates a new GRID Series. */
    createSeries: CreateSeriesPayload;
    createSlugifySlug?: Maybe<SlugifySlugEntityResponse>;
    /** Creates a new team. */
    createTeam: CreateTeamPayload;
    /** Creates a new esport title. */
    createTitle: CreateTitlePayload;
    /** Creates a new GRID tournament. */
    createTournament: CreateTournamentPayload;
    createUser?: Maybe<User>;
    /** Deletes a specific version of a content catalog entity. */
    deleteContentCatalogEntity: DeleteContentCatalogEntityPayload;
    /** Deletes a specific content catalog version. */
    deleteContentCatalogVersion: DeleteContentCatalogVersionPayload;
    deleteContentPermission?: Maybe<GetContentPermissions>;
    deleteContentPermissions?: Maybe<ContentPermissionsPayload>;
    /** Deletes an organization. */
    deleteOrganization: DeleteOrganizationPayload;
    /** Deletes a player. */
    deletePlayer: DeletePlayerPayload;
    /** Deletes a GRID Series. */
    deleteSeries: DeleteSeriesPayload;
    deleteSlugifySlug?: Maybe<SlugifySlugEntityResponse>;
    /** Deletes a Team. */
    deleteTeam: DeleteTeamPayload;
    /** Deletes a GRID tournament. */
    deleteTournament: DeleteTournamentPayload;
    deleteUserById?: Maybe<DeleteUser>;
    editContentPermissions?: Maybe<EditContentPermissions>;
    editOrganisation?: Maybe<Organisation>;
    endSeriesById?: Maybe<Series>;
    executeSdkCommand?: Maybe<SdkCommandExecutionResponse>;
    forgotPassword?: Maybe<User>;
    login: UsersPermissionsLoginPayload;
    pickMapForSeries?: Maybe<Veto>;
    pickMapForTeam?: Maybe<Veto>;
    processSingleStatsExplorerMessage?: Maybe<SingleMessageResponse>;
    regenerateApiKey?: Maybe<ApiKeysPayload>;
    register?: Maybe<User>;
    removeIpFromOrganisationWhitelist?: Maybe<IpWhitelistPayload>;
    removeIpFromUserWhitelist?: Maybe<IpWhitelistPayload>;
    /** Updates the AutomaticSeriesUpdate value */
    setAutomaticSeriesUpdate: SetAutomaticSeriesUpdatePayload;
    setContentPermission?: Maybe<GetContentPermissions>;
    setContentPermissions?: Maybe<ContentPermissionsPayload>;
    /** Updates a specific version of a content catalog item. */
    updateContentCatalogItem: UpdateContentCatalogItemPayload;
    /** Updates a specific content catalog version */
    updateContentCatalogVersion: UpdateContentCatalogVersionPayload;
    /** Updates an existing organization. */
    updateOrganization: UpdateOrganizationPayload;
    /** Updates an existing player. */
    updatePlayer: UpdatePlayerPayload;
    /** Updates an existing GRID Series. */
    updateSeries: UpdateSeriesPayload;
    updateSlugifySlug?: Maybe<SlugifySlugEntityResponse>;
    /** Updates an existing team. */
    updateTeam: UpdateTeamPayload;
    /** Updates an existing esport title. */
    updateTitle: UpdateTitlePayload;
    /** Updates an existing GRID tournament. */
    updateTournament: UpdateTournamentPayload;
    updateUserById?: Maybe<User>;
    updateUserRoleGroup?: Maybe<UserRoleGroupsPayload>;
    updateUsers?: Maybe<Array<Maybe<User>>>;
};

export type MutationAddIpToOrganisationWhitelistArgs = {
    id: Scalars['String'];
    input?: Maybe<IpWhitelistAllowInput>;
};

export type MutationAddIpToUserWhitelistArgs = {
    id: Scalars['String'];
    input?: Maybe<IpWhitelistAllowInput>;
};

export type MutationAddTeamSeriesWinArgs = {
    id: Scalars['ID'];
    input?: Maybe<AddTeamSeriesWinPayload>;
};

export type MutationAddUserArgs = {
    input?: Maybe<AddUserPayload>;
};

export type MutationBanMapForTeamArgs = {
    input?: Maybe<TeamVetoPayload>;
    seriesId: Scalars['String'];
};

export type MutationCreateContentCatalogItemArgs = {
    createContentCatalogItemInput: CreateContentCatalogItemInput;
};

export type MutationCreateContentCatalogVersionArgs = {
    createContentCatalogVersionInput: CreateContentCatalogVersionInput;
};

export type MutationCreateDocumentationArticleLocalizationArgs = {
    data?: Maybe<DocumentationArticleInput>;
    id?: Maybe<Scalars['ID']>;
    locale?: Maybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOrganisationArgs = {
    input?: Maybe<CreateOrganisationPayload>;
};

export type MutationCreateOrganizationArgs = {
    createOrganizationInput: CreateOrganizationInput;
};

export type MutationCreatePlayerArgs = {
    createPlayerInput: CreatePlayerInput;
};

export type MutationCreateSeriesArgs = {
    createSeriesInput: CreateSeriesInput;
};

export type MutationCreateSlugifySlugArgs = {
    data: SlugifySlugInput;
};

export type MutationCreateTeamArgs = {
    createTeamInput: CreateTeamInput;
};

export type MutationCreateTitleArgs = {
    createTitleInput: CreateTitleInput;
};

export type MutationCreateTournamentArgs = {
    createTournamentInput: CreateTournamentInput;
};

export type MutationCreateUserArgs = {
    input?: Maybe<CreateUserPayload>;
};

export type MutationDeleteContentCatalogEntityArgs = {
    contentCatalogVersionId: Scalars['ID'];
    id: Scalars['ID'];
};

export type MutationDeleteContentCatalogVersionArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteContentPermissionArgs = {
    input?: Maybe<DeleteContentPermissionInput>;
    subjectId: Scalars['String'];
    subjectType: Scalars['String'];
};

export type MutationDeleteContentPermissionsArgs = {
    id?: Maybe<Scalars['String']>;
    input: Array<OrganisationInput>;
    parentEntity?: Maybe<Scalars['String']>;
    targetEntity?: Maybe<Scalars['String']>;
};

export type MutationDeleteOrganizationArgs = {
    deleteOrganizationInput: DeleteOrganizationInput;
};

export type MutationDeletePlayerArgs = {
    deletePlayerInput: DeletePlayerInput;
};

export type MutationDeleteSeriesArgs = {
    deleteSeriesInput: DeleteSeriesInput;
};

export type MutationDeleteSlugifySlugArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteTeamArgs = {
    deleteTeamInput: DeleteTeamInput;
};

export type MutationDeleteTournamentArgs = {
    deleteTournamentInput: DeleteTournamentInput;
};

export type MutationDeleteUserByIdArgs = {
    id: Scalars['ID'];
};

export type MutationEditContentPermissionsArgs = {
    entityType: Scalars['String'];
    id: Scalars['String'];
    input?: Maybe<PermissionChanges>;
};

export type MutationEditOrganisationArgs = {
    input?: Maybe<EditOrganisationPayload>;
};

export type MutationEndSeriesByIdArgs = {
    id: Scalars['ID'];
    input?: Maybe<EndSeriesByIdPayload>;
};

export type MutationExecuteSdkCommandArgs = {
    input: SdkCommandExecutionPayload;
    seriesId: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
    input?: Maybe<ForgotPasswordPayload>;
};

export type MutationLoginArgs = {
    input: UsersPermissionsLoginInput;
};

export type MutationPickMapForSeriesArgs = {
    input?: Maybe<SeriesVetoPayload>;
    seriesId: Scalars['String'];
};

export type MutationPickMapForTeamArgs = {
    input?: Maybe<TeamVetoPayload>;
    seriesId: Scalars['String'];
};

export type MutationProcessSingleStatsExplorerMessageArgs = {
    input: SingleMessageRequest;
};

export type MutationRegenerateApiKeyArgs = {
    input: Scalars['String'];
    userId: Scalars['String'];
};

export type MutationRegisterArgs = {
    input?: Maybe<RegisterUserPayload>;
};

export type MutationRemoveIpFromOrganisationWhitelistArgs = {
    id: Scalars['String'];
    input?: Maybe<IpWhitelistDenyInput>;
};

export type MutationRemoveIpFromUserWhitelistArgs = {
    id: Scalars['String'];
    input?: Maybe<IpWhitelistDenyInput>;
};

export type MutationSetAutomaticSeriesUpdateArgs = {
    setAutomaticSeriesUpdateInput: SetAutomaticSeriesUpdateInput;
};

export type MutationSetContentPermissionArgs = {
    input?: Maybe<SetContentPermissionInput>;
    subjectId: Scalars['String'];
    subjectType: Scalars['String'];
};

export type MutationSetContentPermissionsArgs = {
    id?: Maybe<Scalars['String']>;
    input: Array<OrganisationInput>;
    parentEntity?: Maybe<Scalars['String']>;
    targetEntity?: Maybe<Scalars['String']>;
};

export type MutationUpdateContentCatalogItemArgs = {
    updateContentCatalogItemInput: UpdateContentCatalogItemInput;
};

export type MutationUpdateContentCatalogVersionArgs = {
    updateContentCatalogVersionInput: UpdateContentCatalogVersionInput;
};

export type MutationUpdateOrganizationArgs = {
    updateOrganizationInput: UpdateOrganizationInput;
};

export type MutationUpdatePlayerArgs = {
    updatePlayerInput: UpdatePlayerInput;
};

export type MutationUpdateSeriesArgs = {
    updateSeriesInput: UpdateSeriesInput;
};

export type MutationUpdateSlugifySlugArgs = {
    data: SlugifySlugInput;
    id: Scalars['ID'];
};

export type MutationUpdateTeamArgs = {
    updateTeamInput: UpdateTeamInput;
};

export type MutationUpdateTitleArgs = {
    updateTitleInput: UpdateTitleInput;
};

export type MutationUpdateTournamentArgs = {
    updateTournamentInput: UpdateTournamentInput;
};

export type MutationUpdateUserByIdArgs = {
    id: Scalars['ID'];
    input?: Maybe<UpdateUserPayload>;
};

export type MutationUpdateUserRoleGroupArgs = {
    input?: Maybe<RoleGroupInput>;
    userId?: Maybe<Scalars['String']>;
};

export type MutationUpdateUsersArgs = {
    input?: Maybe<Array<Maybe<UpdateUsersPayload>>>;
};

export type NavigationItem = {
    __typename?: 'NavigationItem';
    createdAt?: Maybe<Scalars['String']>;
    createdBy?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    created_by?: Maybe<Scalars['String']>;
    externalPath?: Maybe<Scalars['String']>;
    icon_url?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
    items?: Maybe<Array<Maybe<NavigationItem>>>;
    master?: Maybe<Scalars['Int']>;
    menuAttached: Scalars['Boolean'];
    order: Scalars['Int'];
    parent?: Maybe<NavigationItem>;
    path?: Maybe<Scalars['String']>;
    related?: Maybe<NavigationItemRelatedData>;
    title: Scalars['String'];
    type: Scalars['String'];
    uiRouterKey: Scalars['String'];
    updatedAt?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['String']>;
};

export type NavigationItemRelated = DocumentationArticle;

export type NavigationItemRelatedData = {
    __typename?: 'NavigationItemRelatedData';
    attributes?: Maybe<NavigationItemRelated>;
    id: Scalars['Int'];
};

export enum NavigationRenderType {
    Flat = 'FLAT',
    Rfr = 'RFR',
    Tree = 'TREE',
}

export type Node = {
    __typename?: 'Node';
    id: Scalars['String'];
    name: Scalars['String'];
    private: Scalars['Boolean'];
    title: Title;
};

/** Data points for Non Playing Characters (NPCs). */
export type NonPlayerCharacterState = {
    __typename?: 'NonPlayerCharacterState';
    /** Indicates whether the NPC is alive. */
    alive: Scalars['Boolean'];
    /** GRID ID of the NPC. */
    id: Scalars['ID'];
    /** NPC Coordinates on the map. */
    position?: Maybe<Coordinates>;
    /** Respawn clock state to indicate when the NPC respawns (available from version "1.3"). */
    respawnClock?: Maybe<ClockState>;
    /** Side that controls the NPC */
    side: Scalars['String'];
    /** Type of the NPC */
    type: Scalars['String'];
};

/** Filter by a date range. If both 'gte' and 'lte' are null, it'll fetch the entities for which the field the filter is applied to is null. */
export type NullableDateFilter = {
    /** Date for the beginning of the time range (optional), formatted as an ISO date string. Time window includes the given value. */
    gte?: Maybe<Scalars['Date']>;
    /** Date for the end of the time range (optional), formatted as an ISO date string. Time window includes the given value. */
    lte?: Maybe<Scalars['Date']>;
};

/** ID filter with nullable value. */
export type NullableIdFilter = {
    /** ID to look for, can be nullable. */
    id?: Maybe<Scalars['ID']>;
};

/** An objective that shall be finished. */
export type Objective = {
    __typename?: 'Objective';
    /** Mark that the objective was completed for the first time on the current level(from version 3.11). */
    completedFirst: Scalars['Boolean'];
    /** Amount of times this objective was completed. */
    completionCount: Scalars['Int'];
    /** GRID ID for this objective. */
    id: Scalars['ID'];
    /** The objective type. */
    type: Scalars['String'];
};

/** Order in which the results will be provided. */
export enum OrderDirection {
    /** Orders the results ascending. */
    Asc = 'ASC',
    /** Orders the results descending. */
    Desc = 'DESC',
}

export type Organisation = {
    __typename?: 'Organisation';
    created_at: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    teams?: Maybe<Scalars['String']>;
    tournaments?: Maybe<Scalars['String']>;
    updated_at: Scalars['DateTime'];
    userCount: Scalars['Int'];
};

export type OrganisationEdge = {
    __typename?: 'OrganisationEdge';
    cursor: Scalars['String'];
    node: Organisation;
};

export type OrganisationInput = {
    id: Scalars['ID'];
};

export type OrganisationsPayload = {
    __typename?: 'OrganisationsPayload';
    organisations: PaginatedOrganisations;
};

/** An organization. */
export type Organization = OrganizationInterface & {
    __typename?: 'Organization';
    /** The ID of an organization */
    id: Scalars['ID'];
    /** The name of the organization. */
    name: Scalars['String'];
    /** The owners of the organization. */
    owners: Array<Owner>;
    /** The visibility status of the organization. */
    private: Scalars['Boolean'];
    /** The teams that belong to the organization. */
    teams?: Maybe<Array<TeamRelation>>;
};

/** The result of querying a list of organizations. It provides pagination information. */
export type OrganizationConnection = {
    __typename?: 'OrganizationConnection';
    /** All players edges for query on a given page. */
    edges: Array<OrganizationEdge>;
    /** Information about current page being pulled with query. */
    pageInfo: PageInfo;
    /** Total count of players in the API. */
    totalCount: Scalars['Int'];
};

/** Organization and cursor information. */
export type OrganizationEdge = {
    __typename?: 'OrganizationEdge';
    /** Organizations cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** Organizations. */
    node: Organization;
};

/** Filter for organization by data points. */
export type OrganizationFilter = {
    /** Filter by the organization's name. */
    name?: Maybe<StringFilter>;
    /** Filter by owners. */
    owners?: Maybe<OwnerFilter>;
    /** Filter by organization visibility. */
    private?: Maybe<BooleanFilter>;
};

/** An interface for organization types. */
export type OrganizationInterface = {
    /** The ID of the organization. */
    id: Scalars['ID'];
    /** The name of the organization. */
    name: Scalars['String'];
    /** The owners of the organization. */
    owners: Array<Owner>;
};

/** An organization relation. Represents a relation between an Organization and its Teams. */
export type OrganizationRelation = OrganizationInterface & {
    __typename?: 'OrganizationRelation';
    /** The ID of the organization. */
    id: Scalars['ID'];
    /** The name of the organization. */
    name: Scalars['String'];
    /** The owners of the organization. */
    owners: Array<Owner>;
};

/** An owner. */
export type Owner = {
    __typename?: 'Owner';
    /** The ID of the owner. */
    id: Scalars['ID'];
    /** The type of the owner. */
    type: OwnerType;
};

/** Filter by owner data points. */
export type OwnerFilter = {
    /** Array of IDs to look for. */
    id?: Maybe<IdFilter>;
};

/** The type of an owner. */
export enum OwnerType {
    /** An organization. */
    Organization = 'ORGANIZATION',
    /** A user or service account. */
    User = 'USER',
}

export type Page = {
    __typename?: 'Page';
    circuitHub?: Maybe<CircuitHub>;
    components?: Maybe<Array<Maybe<PageComponentsDynamicZone>>>;
    created_at: Scalars['DateTime'];
    id: Scalars['ID'];
    meta?: Maybe<ComponentPageComponentsMeta>;
    name?: Maybe<Scalars['String']>;
    overrides?: Maybe<ComponentGenericOverrides>;
    page_type?: Maybe<Enum_Page_Page_Type>;
    priority?: Maybe<Scalars['Int']>;
    site?: Maybe<Site>;
    updated_at: Scalars['DateTime'];
};

export type PageAggregator = {
    __typename?: 'PageAggregator';
    avg?: Maybe<PageAggregatorAvg>;
    count?: Maybe<Scalars['Int']>;
    max?: Maybe<PageAggregatorMax>;
    min?: Maybe<PageAggregatorMin>;
    sum?: Maybe<PageAggregatorSum>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type PageAggregatorAvg = {
    __typename?: 'PageAggregatorAvg';
    priority?: Maybe<Scalars['Float']>;
};

export type PageAggregatorMax = {
    __typename?: 'PageAggregatorMax';
    priority?: Maybe<Scalars['Float']>;
};

export type PageAggregatorMin = {
    __typename?: 'PageAggregatorMin';
    priority?: Maybe<Scalars['Float']>;
};

export type PageAggregatorSum = {
    __typename?: 'PageAggregatorSum';
    priority?: Maybe<Scalars['Float']>;
};

export type PageComponentsDynamicZone =
    | ComponentCircuitHubTournamentConfig
    | ComponentPageComponentsBestOdds
    | ComponentPageComponentsGallery
    | ComponentPageComponentsPromoBanner
    | ComponentPageComponentsSponsors
    | ComponentPageComponentsVisualisationsWidget;

export type PageConnection = {
    __typename?: 'PageConnection';
    aggregate?: Maybe<PageAggregator>;
    groupBy?: Maybe<PageGroupBy>;
    values?: Maybe<Array<Maybe<Page>>>;
};

export type PageConnectionCircuitHub = {
    __typename?: 'PageConnectionCircuitHub';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type PageConnectionCreated_At = {
    __typename?: 'PageConnectionCreated_at';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type PageConnectionId = {
    __typename?: 'PageConnectionId';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type PageConnectionMeta = {
    __typename?: 'PageConnectionMeta';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type PageConnectionName = {
    __typename?: 'PageConnectionName';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type PageConnectionOverrides = {
    __typename?: 'PageConnectionOverrides';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type PageConnectionPage_Type = {
    __typename?: 'PageConnectionPage_type';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['String']>;
};

export type PageConnectionPriority = {
    __typename?: 'PageConnectionPriority';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['Int']>;
};

export type PageConnectionSite = {
    __typename?: 'PageConnectionSite';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type PageConnectionUpdated_At = {
    __typename?: 'PageConnectionUpdated_at';
    connection?: Maybe<PageConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type PageGroupBy = {
    __typename?: 'PageGroupBy';
    circuitHub?: Maybe<Array<Maybe<PageConnectionCircuitHub>>>;
    created_at?: Maybe<Array<Maybe<PageConnectionCreated_At>>>;
    id?: Maybe<Array<Maybe<PageConnectionId>>>;
    meta?: Maybe<Array<Maybe<PageConnectionMeta>>>;
    name?: Maybe<Array<Maybe<PageConnectionName>>>;
    overrides?: Maybe<Array<Maybe<PageConnectionOverrides>>>;
    page_type?: Maybe<Array<Maybe<PageConnectionPage_Type>>>;
    priority?: Maybe<Array<Maybe<PageConnectionPriority>>>;
    site?: Maybe<Array<Maybe<PageConnectionSite>>>;
    updated_at?: Maybe<Array<Maybe<PageConnectionUpdated_At>>>;
};

/** Information about current paginated data set position. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** The cursor of the last edge of the page. */
    endCursor?: Maybe<Scalars['Cursor']>;
    /** If there is a page after the one being returned. */
    hasNextPage: Scalars['Boolean'];
    /** If there is a page before the one being returned. */
    hasPreviousPage: Scalars['Boolean'];
    /** The cursor of the first edge of the page. */
    startCursor?: Maybe<Scalars['Cursor']>;
};

export type PageInput = {
    circuitHub?: Maybe<Scalars['ID']>;
    components?: Maybe<Array<Scalars['PageComponentsDynamicZoneInput']>>;
    created_by?: Maybe<Scalars['ID']>;
    meta: ComponentPageComponentsMetaInput;
    name?: Maybe<Scalars['String']>;
    overrides: ComponentGenericOverrideInput;
    page_type?: Maybe<Enum_Page_Page_Type>;
    priority?: Maybe<Scalars['Int']>;
    site?: Maybe<Scalars['ID']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type PaginatedOrganisations = {
    __typename?: 'PaginatedOrganisations';
    edges: Array<OrganisationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PaginatedUsers = {
    __typename?: 'PaginatedUsers';
    edges: Array<UserEdge>;
    filterOptions?: Maybe<UsersFilterOptions>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type Pagination = {
    __typename?: 'Pagination';
    page: Scalars['Int'];
    pageCount: Scalars['Int'];
    pageSize: Scalars['Int'];
    total: Scalars['Int'];
};

export type PaginationArg = {
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    start?: Maybe<Scalars['Int']>;
};

/** Participation status of an entity (ie Player). */
export enum ParticipationStatus {
    /** Entity (ie Player) actively participating. */
    Active = 'active',
    /** Entity (ie Player) not actively participating anymore. */
    Inactive = 'inactive',
}

export type PermissionChanges = {
    added?: Maybe<Array<Maybe<ContentPermissionRuleInput>>>;
    removed?: Maybe<Array<Maybe<ContentPermissionRuleInput>>>;
};

export type PermissionEntity = {
    __typename?: 'PermissionEntity';
    key: Scalars['String'];
    text: Scalars['String'];
    value: Scalars['String'];
};

export type PermissionEntityInput = {
    key: Scalars['String'];
    text?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

/** A player */
export type Player = {
    __typename?: 'Player';
    /** The AutomaticUpdates (INACTIVE/ACTIVE) status for the player. */
    automaticUpdatesStatus: AutomaticUpdatesStatus;
    /** The external player IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The ID of a player */
    id: Scalars['ID'];
    /** The name of the player. */
    nickname: Scalars['String'];
    /** The owners of the player. */
    owners: Array<Owner>;
    /** The visibility status of the player. */
    private: Scalars['Boolean'];
    /** The team where the player currently plays. */
    team?: Maybe<Team>;
    /** The title which is played by the player. */
    title: Title;
};

/** The result of querying a list of players. It provides pagination information. */
export type PlayerConnection = {
    __typename?: 'PlayerConnection';
    /** All players edges for query on a given page. */
    edges: Array<PlayerEdge>;
    /** Information about current page being pulled with query. */
    pageInfo: PageInfo;
    /** Total count of players in the API. */
    totalCount: Scalars['Int'];
};

/** Player and cursor information. */
export type PlayerEdge = {
    __typename?: 'PlayerEdge';
    /** Players cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** Players. */
    node: Player;
};

/** Filter for player data points. */
export type PlayerFilter = {
    /** Filter by the player's nickname. */
    nickname?: Maybe<StringFilter>;
    /** Filter by owners. */
    owners?: Maybe<OwnerFilter>;
    /** Filter by player visibility. */
    private?: Maybe<BooleanFilter>;
    /** Filter by a specific team ID. If this is null, no filter will be applied. If `teamId.id` is null, then players without team will be fetched. */
    teamIdFilter?: Maybe<NullableIdFilter>;
    /** Filter by a specific title ID. */
    titleId?: Maybe<Scalars['ID']>;
};

/** The inventory of a Player. */
export type PlayerInventory = {
    __typename?: 'PlayerInventory';
    /** The items that are contained in the Player's inventory. */
    items: Array<ItemStack>;
};

/** The player type. */
export enum PlayerType {
    /** An esports player. */
    Esports = 'ESPORTS',
    /** A non-esports player. */
    Regular = 'REGULAR',
}

export type ProductCoverageLevel = {
    __typename?: 'ProductCoverageLevel';
    serviceLevel: Scalars['String'];
};

export type ProductCoverageLevels = {
    __typename?: 'ProductCoverageLevels';
    liveDataFeed?: Maybe<ProductCoverageLevel>;
    videoRapid?: Maybe<ProductCoverageLevel>;
};

/** The availability of a product for a given series. */
export type ProductServiceLevel = {
    __typename?: 'ProductServiceLevel';
    /** The name of the product. */
    productName: Scalars['String'];
    /** The availability level for the product. */
    serviceLevel: ServiceLevel;
};

/** Filters for series by product and/or by service level. */
export type ProductServiceLevelFilter = {
    /** The name of the product to filter by. */
    productName: Scalars['String'];
    /** The availability level to filter by. */
    serviceLevel: ServiceLevel;
};

/** The availability of a product for a given series. */
export type ProductServiceLevelInput = {
    /** The name of the product. */
    productName: Scalars['String'];
    /** The availability level for the product. */
    serviceLevel: ServiceLevel;
};

export type ProductUpdateInput = {
    content: Scalars['String'];
    created_by?: Maybe<Scalars['ID']>;
    date: Scalars['Date'];
    published_at?: Maybe<Scalars['DateTime']>;
    title: Scalars['String'];
    updated_by?: Maybe<Scalars['ID']>;
};

export type ProductUpdates = {
    __typename?: 'ProductUpdates';
    content: Scalars['String'];
    created_at: Scalars['DateTime'];
    date: Scalars['Date'];
    id: Scalars['ID'];
    published_at?: Maybe<Scalars['DateTime']>;
    title: Scalars['String'];
    updated_at: Scalars['DateTime'];
};

export type ProductUpdatesAggregator = {
    __typename?: 'ProductUpdatesAggregator';
    count?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type ProductUpdatesConnection = {
    __typename?: 'ProductUpdatesConnection';
    aggregate?: Maybe<ProductUpdatesAggregator>;
    groupBy?: Maybe<ProductUpdatesGroupBy>;
    values?: Maybe<Array<Maybe<ProductUpdates>>>;
};

export type ProductUpdatesConnectionContent = {
    __typename?: 'ProductUpdatesConnectionContent';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['String']>;
};

export type ProductUpdatesConnectionCreated_At = {
    __typename?: 'ProductUpdatesConnectionCreated_at';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type ProductUpdatesConnectionDate = {
    __typename?: 'ProductUpdatesConnectionDate';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ProductUpdatesConnectionId = {
    __typename?: 'ProductUpdatesConnectionId';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type ProductUpdatesConnectionPublished_At = {
    __typename?: 'ProductUpdatesConnectionPublished_at';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type ProductUpdatesConnectionTitle = {
    __typename?: 'ProductUpdatesConnectionTitle';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['String']>;
};

export type ProductUpdatesConnectionUpdated_At = {
    __typename?: 'ProductUpdatesConnectionUpdated_at';
    connection?: Maybe<ProductUpdatesConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type ProductUpdatesGroupBy = {
    __typename?: 'ProductUpdatesGroupBy';
    content?: Maybe<Array<Maybe<ProductUpdatesConnectionContent>>>;
    created_at?: Maybe<Array<Maybe<ProductUpdatesConnectionCreated_At>>>;
    date?: Maybe<Array<Maybe<ProductUpdatesConnectionDate>>>;
    id?: Maybe<Array<Maybe<ProductUpdatesConnectionId>>>;
    published_at?: Maybe<Array<Maybe<ProductUpdatesConnectionPublished_At>>>;
    title?: Maybe<Array<Maybe<ProductUpdatesConnectionTitle>>>;
    updated_at?: Maybe<Array<Maybe<ProductUpdatesConnectionUpdated_At>>>;
};

export type Profile = {
    __typename?: 'Profile';
    apiKey: Scalars['String'];
    roles?: Maybe<Array<Scalars['String']>>;
    theme: Scalars['String'];
};

export enum PublicationState {
    Live = 'LIVE',
    Preview = 'PREVIEW',
}

/** All available GraphQL Queries. */
export type Query = {
    __typename?: 'Query';
    _service: _Service;
    /** Get multiple series by query specifications. */
    allSeries: SeriesConnection;
    circuitHub?: Maybe<CircuitHub>;
    circuitHubByDomain?: Maybe<CircuitHub>;
    circuitHubs?: Maybe<Array<Maybe<CircuitHub>>>;
    circuitHubsConnection?: Maybe<CircuitHubConnection>;
    /** Get multiple content catalog entities by query specification. */
    contentCatalogEntities: ContentCatalogEntityConnection;
    /** Get a Content Catalog Entity by ID. */
    contentCatalogEntity?: Maybe<ContentCatalogEntity>;
    /** Get the GRID Content Catalog Entity ID by an external ID. */
    contentCatalogEntityIdByExternalId?: Maybe<Scalars['ID']>;
    /** Get a Content Catalog Version by ID. */
    contentCatalogVersion?: Maybe<ContentCatalogVersion>;
    /** Get the GRID Content Catalog Version ID by an external ID. */
    contentCatalogVersionIdByExternalId?: Maybe<Scalars['ID']>;
    /** Get multiple content catalog versions by query specification. */
    contentCatalogVersions: ContentCatalogVersionConnection;
    contentPage?: Maybe<ContentPage>;
    contentPages?: Maybe<Array<Maybe<ContentPage>>>;
    contentPagesConnection?: Maybe<ContentPageConnection>;
    /** Get supported data providers. */
    dataProviders: Array<DataProvider>;
    /** Describe which rules of passed users content permissions grants read access to passed entity. */
    describeContentPermissionMatches?: Maybe<ContentPermissionRulesMatchDescription>;
    documentationArticle?: Maybe<DocumentationArticleEntityResponse>;
    documentationArticles?: Maybe<DocumentationArticleEntityResponseCollection>;
    documentationPage?: Maybe<DocumentationPage>;
    documentationPageBySlug: DocumentationPage;
    documentationPages?: Maybe<Array<Maybe<DocumentationPage>>>;
    documentationPagesConnection?: Maybe<DocumentationPageConnection>;
    events: EventConnection;
    findSlug?: Maybe<FindSlugResponse>;
    galleries?: Maybe<Array<Maybe<Gallery>>>;
    galleriesConnection?: Maybe<GalleryConnection>;
    gallery?: Maybe<Gallery>;
    /** Get the GRID Game ID by an external ID. */
    gameIdByExternalId?: Maybe<Scalars['ID']>;
    getAllContentPermissionsOrganisations?: Maybe<EntityContentPermissionsOrganisationsPayload>;
    getBestodds: Array<Maybe<Bestodds>>;
    getContentCatalogItems?: Maybe<GetContentCatalogItemsPayload>;
    getContentCatalogVersions?: Maybe<GetContentCatalogVersionsPayload>;
    getContentPermissionForOrganisations: Array<ContentPermissionSubjectProps>;
    getContentPermissions?: Maybe<GetContentPermissions>;
    getContentPermissionsOrganisations?: Maybe<EntityContentPermissionsOrganisationsPayload>;
    getCurrentSeries?: Maybe<BaseSeriesInfo>;
    getDashboardSeries?: Maybe<DashboardSeriesPayload>;
    getDataProviders?: Maybe<DataProvidersPayload>;
    getEsportsOrganizations?: Maybe<OrganisationsPayload>;
    getGalleriesForPage?: Maybe<Array<Maybe<Gallery>>>;
    getIpWhitelistForOrganisation?: Maybe<IpWhitelistPayload>;
    getIpWhitelistForUser?: Maybe<IpWhitelistPayload>;
    getMyApiKey?: Maybe<Profile>;
    getMyProfile?: Maybe<Profile>;
    getOrganisationById: Organisation;
    getOrganisations?: Maybe<OrganisationsPayload>;
    getPromoBannersForPage?: Maybe<Array<Maybe<MerchandiseBanner>>>;
    getSeriesFiles?: Maybe<GetSeriesFilesPayload>;
    getTeams?: Maybe<Array<Maybe<TeamPayload>>>;
    getTournaments?: Maybe<Array<Maybe<TournamentPayload>>>;
    getUserApiKeys?: Maybe<ApiKeysPayload>;
    getUserById: User;
    getUserRoleGroups?: Maybe<UserRoleGroupsPayload>;
    getUserVerifyRegistration?: Maybe<GetUserVerifyRegistrationPayload>;
    getUsers?: Maybe<UsersPayload>;
    getWatchHubCards: Array<Maybe<WatchHubCard>>;
    hasContentPermission?: Maybe<ContentPermissionsPayload>;
    /** Query the SeriesState of the latest series that this player, given by its ID, has participated in. */
    latestSeriesStateByPlayerId?: Maybe<SeriesState>;
    me?: Maybe<UsersPermissionsMe>;
    /** Get an organization by ID. */
    organization?: Maybe<Organization>;
    /** Get multiple organizations by query specifications. */
    organizations?: Maybe<OrganizationConnection>;
    page?: Maybe<Page>;
    pages?: Maybe<Array<Maybe<Page>>>;
    pagesByDomain?: Maybe<Array<Maybe<Page>>>;
    pagesConnection?: Maybe<PageConnection>;
    /** Get a player by ID. */
    player?: Maybe<Player>;
    /** Get a player by its external ID. */
    playerByExternalPlayer?: Maybe<Player>;
    /** Get the GRID Player ID by an external ID. */
    playerIdByExternalId?: Maybe<Scalars['ID']>;
    /** Get multiple players by query specifications. */
    players?: Maybe<PlayerConnection>;
    productUpdate?: Maybe<ProductUpdates>;
    productUpdates?: Maybe<Array<Maybe<ProductUpdates>>>;
    productUpdatesConnection?: Maybe<ProductUpdatesConnection>;
    renderNavigation: Array<Maybe<NavigationItem>>;
    renderNavigationChild: Array<Maybe<NavigationItem>>;
    /** Returns requesting users Algolia search api key */
    searchApiKey?: Maybe<SearchApiKeyResponse>;
    /** Get a series by ID. */
    series?: Maybe<Series>;
    /** Get a series by its external ID. */
    seriesByExternalSeries?: Maybe<Series>;
    /** Get supported series formats. */
    seriesFormats: Array<SeriesFormat>;
    /** Get the GRID Series ID by an external ID. */
    seriesIdByExternalId?: Maybe<Scalars['ID']>;
    /** Query the SeriesState of a specified Series ID. */
    seriesState?: Maybe<SeriesState>;
    siteByDomain?: Maybe<Site>;
    slugifySlug?: Maybe<SlugifySlugEntityResponse>;
    slugifySlugs?: Maybe<SlugifySlugEntityResponseCollection>;
    /** Lists all supported tags by particular GRID's entities. */
    tagsSupportedByEntity: Array<TagSupport>;
    /** Get a team by ID. */
    team?: Maybe<Team>;
    /** Get team by its external team ID. */
    teamByExternalTeam?: Maybe<Team>;
    /** Get the GRID Team ID by an external ID. */
    teamIdByExternalId?: Maybe<Scalars['ID']>;
    /** Get multiple teams by query specifications. */
    teams: TeamConnection;
    /** Get a title by ID. */
    title?: Maybe<Title>;
    /** Get all titles. */
    titles: Array<Title>;
    /** Get a tournament by ID. */
    tournament?: Maybe<Tournament>;
    /** Get a tournament by its external ID. */
    tournamentByExternalTournament?: Maybe<Tournament>;
    /** Get the GRID Tournament ID by an external ID. */
    tournamentIdByExternalId?: Maybe<Scalars['ID']>;
    /** Get multiple tournaments by query specifications. */
    tournaments: TournamentConnection;
    twitchChannel?: Maybe<TwitchChannels>;
    twitchChannels?: Maybe<Array<Maybe<TwitchChannels>>>;
    twitchChannelsConnection?: Maybe<TwitchChannelsConnection>;
};

/** All available GraphQL Queries. */
export type QueryAllSeriesArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<SeriesFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    orderBy?: SeriesOrderBy;
    orderDirection?: OrderDirection;
};

/** All available GraphQL Queries. */
export type QueryCircuitHubArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryCircuitHubByDomainArgs = {
    domain: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryCircuitHubsArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryCircuitHubsConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryContentCatalogEntitiesArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    contentCatalogVersionId?: Maybe<Scalars['ID']>;
    filter?: Maybe<ContentCatalogEntityFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** All available GraphQL Queries. */
export type QueryContentCatalogEntityArgs = {
    contentCatalogVersionId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryContentCatalogEntityIdByExternalIdArgs = {
    contentCatalogVersionId?: Maybe<Scalars['ID']>;
    dataProviderName: Scalars['String'];
    externalEntityId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryContentCatalogVersionArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryContentCatalogVersionIdByExternalIdArgs = {
    dataProviderName: Scalars['String'];
    externalEntityId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryContentCatalogVersionsArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<ContentCatalogVersionFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<Array<ContentCatalogVersionOrder>>;
};

/** All available GraphQL Queries. */
export type QueryContentPageArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryContentPagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryContentPagesConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryDescribeContentPermissionMatchesArgs = {
    entityId: Scalars['ID'];
    entityType: ContentPermissionRuleMatchDescriptionEntityType;
    userId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryDocumentationArticleArgs = {
    id?: Maybe<Scalars['ID']>;
    locale?: Maybe<Scalars['I18NLocaleCode']>;
};

/** All available GraphQL Queries. */
export type QueryDocumentationArticlesArgs = {
    filters?: Maybe<DocumentationArticleFiltersInput>;
    locale?: Maybe<Scalars['I18NLocaleCode']>;
    pagination?: Maybe<PaginationArg>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** All available GraphQL Queries. */
export type QueryDocumentationPageArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryDocumentationPageBySlugArgs = {
    slug: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryDocumentationPagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryDocumentationPagesConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryEventsArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<EventsFilter>;
    seriesId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryFindSlugArgs = {
    modelName?: Maybe<Scalars['String']>;
    publicationState?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGalleriesArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryGalleriesConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryGalleryArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryGameIdByExternalIdArgs = {
    dataProviderName: Scalars['String'];
    externalGameId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryGetAllContentPermissionsOrganisationsArgs = {
    entityId?: Maybe<Scalars['String']>;
    entityType?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGetBestoddsArgs = {
    id: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetContentPermissionsArgs = {
    entityType: Scalars['String'];
    id: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetContentPermissionsOrganisationsArgs = {
    entityId?: Maybe<Scalars['String']>;
    entityType?: Maybe<Scalars['String']>;
    queryString?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGetCurrentSeriesArgs = {
    domain?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['String']>;
    seriesId?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGetDashboardSeriesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    dashboard: Scalars['String'];
    endTime: Scalars['String'];
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    productName?: Maybe<Scalars['String']>;
    searchId?: Maybe<Scalars['String']>;
    showTestSeries: Scalars['Boolean'];
    startTime: Scalars['String'];
    teamIds?: Maybe<Scalars['String']>;
    titleIds?: Maybe<Scalars['String']>;
    tournamentIds?: Maybe<Scalars['String']>;
    tournamentIncludeChildren?: Maybe<Scalars['Boolean']>;
};

/** All available GraphQL Queries. */
export type QueryGetEsportsOrganizationsArgs = {
    organizations?: Maybe<EsportsOrganizationsProps>;
};

/** All available GraphQL Queries. */
export type QueryGetGalleriesForPageArgs = {
    pageId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetIpWhitelistForOrganisationArgs = {
    id: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetIpWhitelistForUserArgs = {
    id: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetOrganisationByIdArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryGetOrganisationsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    queryString?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGetPromoBannersForPageArgs = {
    pageId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetSeriesFilesArgs = {
    id: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetUserApiKeysArgs = {
    userId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetUserByIdArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryGetUserRoleGroupsArgs = {
    userId?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGetUserVerifyRegistrationArgs = {
    verificationId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryGetUsersArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    organisationId?: Maybe<Scalars['String']>;
    queryString?: Maybe<Scalars['String']>;
    userType?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryGetWatchHubCardsArgs = {
    domain: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryHasContentPermissionArgs = {
    seriesID?: Maybe<Scalars['String']>;
};

/** All available GraphQL Queries. */
export type QueryLatestSeriesStateByPlayerIdArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryOrganizationArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryOrganizationsArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<OrganizationFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** All available GraphQL Queries. */
export type QueryPageArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryPagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryPagesByDomainArgs = {
    domain: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryPagesConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryPlayerArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryPlayerByExternalPlayerArgs = {
    dataProviderName: Scalars['String'];
    externalPlayerId: Scalars['ID'];
    titleId?: Maybe<Scalars['ID']>;
};

/** All available GraphQL Queries. */
export type QueryPlayerIdByExternalIdArgs = {
    dataProviderName: Scalars['String'];
    externalPlayerId: Scalars['ID'];
    titleId?: Maybe<Scalars['ID']>;
};

/** All available GraphQL Queries. */
export type QueryPlayersArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<PlayerFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** All available GraphQL Queries. */
export type QueryProductUpdateArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryProductUpdatesArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryProductUpdatesConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryRenderNavigationArgs = {
    locale?: Maybe<Scalars['I18NLocaleCode']>;
    menuOnly?: Maybe<Scalars['Boolean']>;
    navigationIdOrSlug: Scalars['String'];
    path?: Maybe<Scalars['String']>;
    type?: Maybe<NavigationRenderType>;
};

/** All available GraphQL Queries. */
export type QueryRenderNavigationChildArgs = {
    childUiKey: Scalars['String'];
    id: Scalars['String'];
    menuOnly?: Maybe<Scalars['Boolean']>;
    type?: Maybe<NavigationRenderType>;
};

/** All available GraphQL Queries. */
export type QuerySeriesArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QuerySeriesByExternalSeriesArgs = {
    dataProviderName: Scalars['String'];
    externalSeriesId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QuerySeriesIdByExternalIdArgs = {
    dataProviderName: Scalars['String'];
    externalSeriesId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QuerySeriesStateArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QuerySiteByDomainArgs = {
    domain: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QuerySlugifySlugArgs = {
    id?: Maybe<Scalars['ID']>;
};

/** All available GraphQL Queries. */
export type QuerySlugifySlugsArgs = {
    filters?: Maybe<SlugifySlugFiltersInput>;
    pagination?: Maybe<PaginationArg>;
    sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** All available GraphQL Queries. */
export type QueryTeamArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryTeamByExternalTeamArgs = {
    dataProviderName: Scalars['String'];
    externalTeamId: Scalars['String'];
    titleId?: Maybe<Scalars['ID']>;
};

/** All available GraphQL Queries. */
export type QueryTeamIdByExternalIdArgs = {
    dataProviderName: Scalars['String'];
    externalTeamId: Scalars['ID'];
    titleId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryTeamsArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<TeamFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** All available GraphQL Queries. */
export type QueryTitleArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryTitlesArgs = {
    filter?: Maybe<TitleFilter>;
};

/** All available GraphQL Queries. */
export type QueryTournamentArgs = {
    id: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryTournamentByExternalTournamentArgs = {
    dataProviderName: Scalars['String'];
    externalTournamentId: Scalars['String'];
};

/** All available GraphQL Queries. */
export type QueryTournamentIdByExternalIdArgs = {
    dataProviderName: Scalars['String'];
    externalTournamentId: Scalars['ID'];
};

/** All available GraphQL Queries. */
export type QueryTournamentsArgs = {
    after?: Maybe<Scalars['Cursor']>;
    before?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<TournamentFilter>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** All available GraphQL Queries. */
export type QueryTwitchChannelArgs = {
    id: Scalars['ID'];
    publicationState?: Maybe<PublicationState>;
};

/** All available GraphQL Queries. */
export type QueryTwitchChannelsArgs = {
    limit?: Maybe<Scalars['Int']>;
    publicationState?: Maybe<PublicationState>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** All available GraphQL Queries. */
export type QueryTwitchChannelsConnectionArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type RegisterUserPayload = {
    email: Scalars['String'];
    howDidYouLearnAboutUs: Scalars['String'];
    name: Scalars['String'];
    password: Scalars['String'];
    recaptchaToken?: Maybe<Scalars['String']>;
    theme?: Maybe<Scalars['String']>;
    userRole: Scalars['String'];
    username?: Maybe<Scalars['String']>;
};

export type ResponseCollectionMeta = {
    __typename?: 'ResponseCollectionMeta';
    pagination: Pagination;
};

export type RoleGroupInput = {
    id?: Maybe<Scalars['String']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
};

export type SdkActionContext = {
    type: Scalars['String'];
};

export type SdkCommandContext = {
    metadata: Metadata;
    seriesId: IdContext;
};

export type SdkCommandExecutionPayload = {
    actionContext: SdkActionContext;
    commandContext: SdkCommandContext;
};

export type SdkCommandExecutionResponse = {
    __typename?: 'SdkCommandExecutionResponse';
    status?: Maybe<Scalars['String']>;
};

export type SearchApiKey = {
    __typename?: 'SearchApiKey';
    apiKey: Scalars['String'];
    indexName: Scalars['String'];
};

export type SearchApiKeyResponse = {
    __typename?: 'SearchApiKeyResponse';
    data?: Maybe<SearchApiKey>;
};

/** Data points for a Player, aggregated for a Segment (available from version "1.4") */
export type SegmentPlayerState = {
    /** Number of deaths for this Player in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Segment (available from version "3.8"). */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Segment. */
    selfkills: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
};

/** CS2 data points for a Player, aggregated for a Segment. */
export type SegmentPlayerStateCs2 = SegmentPlayerState & {
    __typename?: 'SegmentPlayerStateCs2';
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** The amount of current armor. */
    currentArmor: Scalars['Int'];
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Player in this Segment. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs. */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Segment. */
    kills: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Segment (available from version "3.8"). */
    participationStatus: ParticipationStatus;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Player in this Segment. */
    selfkills: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
};

/** CSGO data points for a Player, aggregated for a Segment. */
export type SegmentPlayerStateCsgo = SegmentPlayerState & {
    __typename?: 'SegmentPlayerStateCsgo';
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** The amount of current armor. */
    currentArmor: Scalars['Int'];
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Player in this Segment. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs (available from version "2.2"). */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Segment. */
    kills: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Segment (available from version "3.8"). */
    participationStatus: ParticipationStatus;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Player in this Segment. */
    selfkills: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
};

/** Deftault data points for a Player, aggregated for a Segment */
export type SegmentPlayerStateDefault = SegmentPlayerState & {
    __typename?: 'SegmentPlayerStateDefault';
    /** Number of deaths for this Player in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Segment (available from version "3.8"). */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Segment. */
    selfkills: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
};

/** R6 data points for a Player, aggregated for a Segment */
export type SegmentPlayerStateR6 = SegmentPlayerState & {
    __typename?: 'SegmentPlayerStateR6';
    /** Indicates whether the player is alive. */
    alive: Scalars['Boolean'];
    /** The current health of the player. */
    currentHealth: Scalars['Int'];
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Player in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** The amount of healing dealt by team. */
    healingDealt: Scalars['Int'];
    /** The amount of healing received by team. */
    healingReceived: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Segment. */
    kills: Scalars['Int'];
    /** Number of times of knocking down an enemy for this player (available from version "3.9"). */
    knockdownsGiven: Scalars['Int'];
    /** Number of times of being knocked down for this player (available from version "3.9"). */
    knockdownsReceived: Scalars['Int'];
    /** The max amount of health of the player. */
    maxHealth: Scalars['Int'];
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Segment (available from version "3.8"). */
    participationStatus: ParticipationStatus;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Player in this Segment. */
    selfkills: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
};

/** Valorant data points for a Player, aggregated for a Segment. */
export type SegmentPlayerStateValorant = SegmentPlayerState & {
    __typename?: 'SegmentPlayerStateValorant';
    /** Indicates whether the player is alive (available from version "1.9"). */
    alive: Scalars['Boolean'];
    /** The amount of current armor (available from version "1.9"). */
    currentArmor: Scalars['Int'];
    /** The current health of the player (available from version "1.9"). */
    currentHealth: Scalars['Int'];
    /** Number of deaths for this Player in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Segment. */
    kills: Scalars['Int'];
    /** The max amount of health of the player (available from version "1.9"). */
    maxHealth: Scalars['Int'];
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Segment (available from version "3.8"). */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Segment. */
    selfkills: Scalars['Int'];
    /** Number of team headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
};

/** The state of a Segment (e.g. a round) (available from version "1.4"). */
export type SegmentState = {
    __typename?: 'SegmentState';
    /** A list of DraftAction, containing information about draft actions in this Segment. (available from version "2.3") */
    draftActions: Array<DraftAction>;
    /** Duration of the Game (available from version "3.16"). */
    duration: Scalars['Duration'];
    /** Indicates whether this Series has finished. */
    finished: Scalars['Boolean'];
    /** GRID ID of this Segment. */
    id: Scalars['ID'];
    /** A list of SegmentStates, containing information of the segments in this Segment. */
    segments: Array<SegmentState>;
    /** The sequence number of this Segment. */
    sequenceNumber: Scalars['Int'];
    /** Indicates whether this Series has started. */
    started: Scalars['Boolean'];
    /** DateTime value when this Segment was started (available from version "3.12"). */
    startedAt?: Maybe<Scalars['DateTime']>;
    /** A list of SegmentTeamState, containing information on the teams participating in this Segment. */
    teams: Array<SegmentTeamState>;
    /** The type of this Segment. */
    type: Scalars['String'];
};

/** Data points for a Team, aggregated for a Segment (available from version "1.4"). */
export type SegmentTeamState = {
    /** Number of deaths for this Team in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** A list of SegmentPlayerState, containing information about the Players that are members of this Team. */
    players: Array<SegmentPlayerState>;
    /** Number of selfkills (suicides) for this Team in this Segment. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Segment ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /**
     * Number of teamkill assists provided by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Team in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Segment. */
    won: Scalars['Boolean'];
};

/** CS2 data points for a Team, aggregated for a Segment. */
export type SegmentTeamStateCs2 = SegmentTeamState & {
    __typename?: 'SegmentTeamStateCs2';
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Team in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** A list of SegmentPlayerState, containing information about the Players that are members of this Team. */
    players: Array<SegmentPlayerState>;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Segment. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Segment ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Team in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
    /** If this team won the round, contains the reason for winning - otherwise an empty string. */
    winType: Scalars['String'];
    /** Indicates whether this Team has won this Segment. */
    won: Scalars['Boolean'];
};

/** CSGO data points for a Team, aggregated for a Segment. */
export type SegmentTeamStateCsgo = SegmentTeamState & {
    __typename?: 'SegmentTeamStateCsgo';
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Team in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** A list of SegmentPlayerState, containing information about the Players that are members of this Team. */
    players: Array<SegmentPlayerState>;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Segment. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Segment ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Team in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
    /** If this team won the round, contains the reason for winning - otherwise an empty string. */
    winType: Scalars['String'];
    /** Indicates whether this Team has won this Segment. */
    won: Scalars['Boolean'];
};

export type SegmentTeamStateDefault = SegmentTeamState & {
    __typename?: 'SegmentTeamStateDefault';
    /** Number of deaths for this Team in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** A list of SegmentPlayerState, containing information about the Players that are members of this Team. */
    players: Array<SegmentPlayerState>;
    /** Number of selfkills (suicides) for this Team in this Segment. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Segment ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Team in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this Team has won this Segment. */
    won: Scalars['Boolean'];
};

export type SegmentTeamStateR6 = SegmentTeamState & {
    __typename?: 'SegmentTeamStateR6';
    /** The amount of damage dealt. */
    damageDealt: Scalars['Int'];
    /** A list of damage sources (available from version "3.17"). */
    damageDealtSources: Array<DamageDealtSource>;
    /** A list of damage targets (available from version "3.17"). */
    damageDealtTargets: Array<DamageDealtTarget>;
    /** The amount of total damage taken. */
    damageTaken: Scalars['Int'];
    /** Number of deaths for this Team in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** The amount of healing dealt by team. */
    healingDealt: Scalars['Int'];
    /** The amount of healing received by team. */
    healingReceived: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Segment. */
    kills: Scalars['Int'];
    /** Number of times of knocking down an enemy for this team (available from version "3.9"). */
    knockdownsGiven: Scalars['Int'];
    /** Number of times of being knocked down for this team (available from version "3.9"). */
    knockdownsReceived: Scalars['Int'];
    /** Name of this Team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** A list of SegmentPlayerState, containing information about the Players that are members of this Team. */
    players: Array<SegmentPlayerState>;
    /** The amount of damage dealt to self. */
    selfdamageDealt: Scalars['Int'];
    /** The amount of damage taken from self. */
    selfdamageTaken: Scalars['Int'];
    /** Number of selfkills (suicides) for this Team in this Segment. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Segment ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /** The amount of damage dealt to team. */
    teamdamageDealt: Scalars['Int'];
    /** The amount of damage taken from team. */
    teamdamageTaken: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Team in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
    /** If this team won the round, contains the reason for winning - otherwise an empty string (available from version "3.4"). */
    winType: Scalars['String'];
    /** Indicates whether this Team has won this Segment. */
    won: Scalars['Boolean'];
};

/** Valorant data points for a Team, aggregated for a Segment. */
export type SegmentTeamStateValorant = SegmentTeamState & {
    __typename?: 'SegmentTeamStateValorant';
    /** Number of deaths for this Team in this Segment. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Team. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Team in this Segment. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Team in this Segment. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Segment. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Team in this Segment. */
    kills: Scalars['Int'];
    /** Name of this Team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Segment. */
    objectives: Array<Objective>;
    /** A list of SegmentPlayerState, containing information about the Players that are members of this Team. */
    players: Array<SegmentPlayerState>;
    /** Number of selfkills (suicides) for this Team in this Segment. */
    selfkills: Scalars['Int'];
    /** Side that this Team has taken in this Segment ie Red or Blue, Terrorists or Counter-Terrorists. */
    side: Scalars['String'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Team in this Segment.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Segment. */
    weaponKills: Array<WeaponKill>;
    /** A list of team kills, which were executed with a specific weapon by this Team in this Segment. */
    weaponTeamkills: Array<WeaponKill>;
    /** If this team won the round, contains the reason for winning - otherwise an empty string (available from version "1.9"). */
    winType: Scalars['String'];
    /** Indicates whether this Team has won this Segment. */
    won: Scalars['Boolean'];
};

export type SentenceChunk = {
    __typename?: 'SentenceChunk';
    color?: Maybe<Scalars['String']>;
    strikethrough: Scalars['Boolean'];
    text: Scalars['String'];
};

/** A series. */
export type Series = {
    __typename?: 'Series';
    /** The AutomaticUpdates (INACTIVE/ACTIVE) status for the series. */
    automaticSeriesUpdatesStatus: AutomaticUpdatesStatus;
    /** The broadcast stream delay in seconds. */
    broadcastDelayInSeconds: Scalars['Int'];
    /** The external series IDs and providers. */
    externalLinks: Array<ExternalSeriesLink>;
    /** The format of the series. */
    format: SeriesFormat;
    /** The ID of a series. */
    id: Scalars['ID'];
    /** The machine data source ID for the series. */
    machineDataSource?: Maybe<MachineDataSource>;
    /** The owners of the series. */
    owners: Array<Owner>;
    /** The visibility status of the series. */
    private: Scalars['Boolean'];
    /** The service level for the series. */
    productServiceLevels: Array<ProductServiceLevel>;
    /** The scheduled start time of the series. */
    startTimeScheduled: Scalars['DateTime'];
    /** The list of stream URLs available for the series. */
    streams: Array<VideoStream>;
    /** The teams participating in the series. */
    teams: Array<TeamParticipant>;
    /** The title being played in the series. */
    title: Title;
    /** The tournament in which the series is being played. */
    tournament: Tournament;
    /** The type of the series. */
    type: SeriesType;
};

/** The result of querying a list of series. It provides pagination information. */
export type SeriesConnection = {
    __typename?: 'SeriesConnection';
    /** All series edges for query on a given page. */
    edges: Array<SeriesEdge>;
    /** Information about current page being pulled with query. */
    pageInfo: PageInfo;
    /** Total count of series in the API. */
    totalCount: Scalars['Int'];
};

/** Series and cursor information. */
export type SeriesEdge = {
    __typename?: 'SeriesEdge';
    /** Series cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** Series. */
    node: Series;
};

/** Filter for series data points. */
export type SeriesFilter = {
    /** Filter by external link data points. */
    externalLinks?: Maybe<ExternalLinkFilter>;
    /** Filter by live data points. */
    live?: Maybe<SeriesLiveFilter>;
    /** Filter by participating player IDs. */
    livePlayerIds?: Maybe<IdFilter>;
    /** Filter by data source. */
    machineDataSource?: Maybe<MachineDataSourceFilter>;
    /** Filter by owners. */
    owners?: Maybe<OwnerFilter>;
    /** Filter by series visibility. */
    private?: Maybe<BooleanFilter>;
    /** Filter by product service levels. */
    productServiceLevels?: Maybe<ProductServiceLevelFilter>;
    /** Filter by a time range. */
    startTimeScheduled?: Maybe<DateTimeFilter>;
    /** @deprecated('Use teamIds filter instead.') Filter by team ID. */
    teamId?: Maybe<Scalars['ID']>;
    /** Filter by team IDs. */
    teamIds?: Maybe<IdFilter>;
    /** @deprecated('Use titleIds filter instead.') Filter by title ID. */
    titleId?: Maybe<Scalars['ID']>;
    /** Filter by title IDs. */
    titleIds?: Maybe<IdFilter>;
    /** Filter by tournament data points. */
    tournament?: Maybe<SeriesTournamentFilter>;
    /** @deprecated('Use the tournament filter instead.') Filter by tournament ID. */
    tournamentId?: Maybe<Scalars['ID']>;
    /** @deprecated('Use the tournament filter instead.') Filter by tournament IDs. */
    tournamentIds?: Maybe<IdFilter>;
    /** @deprecated('Use types filter instead.') Filter by series type. */
    type?: Maybe<SeriesType>;
    /** Filter by series types. */
    types?: Maybe<Array<SeriesType>>;
};

/** The format of the series, such as: BO1, BO3, SA2. */
export type SeriesFormat = {
    __typename?: 'SeriesFormat';
    /** The ID of the format. */
    id?: Maybe<Scalars['ID']>;
    /** The name of a format. */
    name: Scalars['String'];
    /** The shortened name of a format (max. 5 characters). */
    nameShortened: Scalars['String'];
};

/** Filter for series by live data points. */
export type SeriesLiveFilter = {
    /** Filter by game. */
    games?: Maybe<GameFilter>;
};

/** Field to order results by. */
export enum SeriesOrderBy {
    /** Orders the series result by ID. */
    Id = 'ID',
    /** Orders the series result by the scheduled start time. */
    StartTimeScheduled = 'StartTimeScheduled',
}

/** Data points for a Player, aggregated for a Series. */
export type SeriesPlayerState = {
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** CS2 data points for a Player, aggregated for a Series. */
export type SeriesPlayerStateCs2 = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStateCs2';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs. */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** CSGO data points for a Player, aggregated for a Series. */
export type SeriesPlayerStateCsgo = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStateCsgo';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs (available from version "2.2"). */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Default data points for a Player, aggregated for a Series. */
export type SeriesPlayerStateDefault = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStateDefault';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Dota data points for a Player, aggregated for a Series. */
export type SeriesPlayerStateDota = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStateDota';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** A list of ExternalLink, containing information about links to externally provided IDs. (available from version "3.6") */
    externalLinks: Array<ExternalLink>;
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** PUBG data points for a Player, aggregated for a Series. */
export type SeriesPlayerStatePubg = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStatePubg';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** R6 data points for a Player, aggregated for a Series */
export type SeriesPlayerStateR6 = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStateR6';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** Number of times of knocking down an enemy for this player (available from version "3.9"). */
    knockdownsGiven: Scalars['Int'];
    /** Number of times of being knocked down for this player (available from version "3.9"). */
    knockdownsReceived: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Valorant data points for a Player, aggregated for a Series. */
export type SeriesPlayerStateValorant = SeriesPlayerState & {
    __typename?: 'SeriesPlayerStateValorant';
    /** Number of deaths for this Player in this Series. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this Player. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this Player in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this Player in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists received by this Player in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this Player in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Player in this Series (available from version "1.7") */
    multikills: Array<Multikill>;
    /** Name of this Player. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this Player in this Series. */
    objectives: Array<Objective>;
    /** Indicates the participation status of a player for this Series */
    participationStatus: ParticipationStatus;
    /** Number of selfkills (suicides) for this Player in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this Player in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this Player in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of team headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists received by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this Player in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this Player in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
};

/** Data points for a Series. */
export type SeriesState = {
    __typename?: 'SeriesState';
    /** A list of DraftAction, containing information about draft actions in this Series. */
    draftActions: Array<DraftAction>;
    /** Duration of the whole Series (available from version "3.14"). */
    duration: Scalars['Duration'];
    /** Indicates whether this Series has finished. */
    finished: Scalars['Boolean'];
    /** Format of this Series e.g. Best of 3 (Bo3). */
    format: Scalars['String'];
    /** A list of GameState, containing information about the Games in this Series */
    games: Array<GameState>;
    /** GRID ID of this Series. */
    id: Scalars['ID'];
    /** Indicates whether this Series has started. */
    started: Scalars['Boolean'];
    /** DateTime value when this Series was started (available from version "2.1"). */
    startedAt?: Maybe<Scalars['DateTime']>;
    /** A list of SeriesTeamState, containing information about the Teams participating in this Series. */
    teams: Array<SeriesTeamState>;
    /** Esports Title of this Series (available from version "1.1"). */
    title: Title;
    /** DateTime value when this Series data was last updated. */
    updatedAt: Scalars['DateTime'];
    /** Indicates whether this Series data is considered accurate. */
    valid: Scalars['Boolean'];
    /** Version of the data model returned. */
    version: Scalars['Version'];
};

/** Data points for a Series. */
export type SeriesStateGamesArgs = {
    filter?: Maybe<GameStateFilter>;
};

/** Data points for a Team, aggregated for a Series. */
export type SeriesTeamState = {
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** CS2 data points for a Team, aggregated for a Series. */
export type SeriesTeamStateCs2 = SeriesTeamState & {
    __typename?: 'SeriesTeamStateCs2';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** CSGO data points for a Team, aggregated for a Series. */
export type SeriesTeamStateCsgo = SeriesTeamState & {
    __typename?: 'SeriesTeamStateCsgo';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** ### THESE SHOULD BE IDENTICAL WITH INTERFACES #### */
export type SeriesTeamStateDefault = SeriesTeamState & {
    __typename?: 'SeriesTeamStateDefault';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** Dota data points for a Team, aggregated for a Series. */
export type SeriesTeamStateDota = SeriesTeamState & {
    __typename?: 'SeriesTeamStateDota';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** PUBG data points for a Team, aggregated for a Series. */
export type SeriesTeamStatePubg = SeriesTeamState & {
    __typename?: 'SeriesTeamStatePubg';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

export type SeriesTeamStateR6 = SeriesTeamState & {
    __typename?: 'SeriesTeamStateR6';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of player acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** GRID ID of this team.. */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** Number of times of knocking down an enemy for this team (available from version "3.9"). */
    knockdownsGiven: Scalars['Int'];
    /** Number of times of being knocked down for this team (available from version "3.9"). */
    knockdownsReceived: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** Valorant data points for a Team, aggregated for a Series. */
export type SeriesTeamStateValorant = SeriesTeamState & {
    __typename?: 'SeriesTeamStateValorant';
    /** Number of deaths for this team in this Series. */
    deaths: Scalars['Int'];
    /** Indication of team acquiring first kill. */
    firstKill: Scalars['Boolean'];
    /** Number of enemy headshots for this Player. */
    headshots: Scalars['Int'];
    /** ### BASE FIELDS #### */
    id: Scalars['ID'];
    /** Number of enemy kill assists provided by this team in this Series. */
    killAssistsGiven: Scalars['Int'];
    /** Number of enemy kill assists received by this team in this Series. */
    killAssistsReceived: Scalars['Int'];
    /** A list of enemy KillAssistFromPlayer, containing information on kill assists from a Player, received by this Team in this Series. */
    killAssistsReceivedFromPlayer: Array<KillAssistFromPlayer>;
    /** Number of enemy kills for this team in this Series. */
    kills: Scalars['Int'];
    /** A list of multi kills that happened by this Team in this Series (available from version "1.7"). */
    multikills: Array<Multikill>;
    /** Name of this team. */
    name: Scalars['String'];
    /** A list of objectives that were fulfilled by this team in this Series. */
    objectives: Array<Objective>;
    /** A list of SeriesPlayerState, containing information about the Players that are members of this team. */
    players: Array<SeriesPlayerState>;
    /** Score for this team in this Series. This is used for the Score After format and Best-of (#games won). */
    score: Scalars['Int'];
    /** Number of selfkills (suicides) for this team in this Series. */
    selfkills: Scalars['Int'];
    /** Number of structures captured by this team in this Series. */
    structuresCaptured: Scalars['Int'];
    /** Number of structures destroyed by this team in this Series. */
    structuresDestroyed: Scalars['Int'];
    /** Number of allied headshots for this Player. */
    teamHeadshots: Scalars['Int'];
    /**
     * Number of teamkill assists provided by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsGiven: Scalars['Int'];
    /**
     * Number of teamkill assists received by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceived: Scalars['Int'];
    /**
     * A list of KillAssistFromPlayer, containing information on teamkill assists from a Player, received by this Team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkillAssistsReceivedFromPlayer: Array<TeamkillAssistFromPlayer>;
    /**
     * Number of teamkills for this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    teamkills: Scalars['Int'];
    /** A list of enemy kills, which were executed with a specific weapon by this team in this Series. */
    weaponKills: Array<WeaponKill>;
    /**
     * A list of teamkills, which were executed with a specific weapon by this team in this Series.
     * A teamkill is the occurrence of killing an allied member.
     */
    weaponTeamkills: Array<WeaponKill>;
    /** Indicates whether this team has won this Series. */
    won: Scalars['Boolean'];
};

/** Filter for series by tournament data points. */
export type SeriesTournamentFilter = {
    /** Filter by tournament end date. */
    endDate?: Maybe<NullableDateFilter>;
    /** Filter by tournament IDs. */
    id?: Maybe<IdFilter>;
    /** Include children tournaments in the filter. */
    includeChildren?: Maybe<BooleanFilter>;
    /** Filter by tournament name. */
    name?: Maybe<StringFilter>;
    /** Filter by tournament shortened name. */
    nameShortened?: Maybe<StringFilter>;
    /** Filter by tournament start date. */
    startDate?: Maybe<NullableDateFilter>;
    /** Filter by tournament titles data points. */
    title?: Maybe<SeriesTournamentTitleFilter>;
};

/** Filter for series by the tournament title data points. */
export type SeriesTournamentTitleFilter = {
    /** Array of IDs to look for. */
    id?: Maybe<IdFilter>;
};

/** The type of series being returned. */
export enum SeriesType {
    /** A competitive non-esports series. */
    Competitive = 'COMPETITIVE',
    /** A competitive esports series. */
    Esports = 'ESPORTS',
    /** A test game that gets played repeatedly in a loop. */
    Loopfeed = 'LOOPFEED',
    /** A practice series. */
    Scrim = 'SCRIM',
}

export type SeriesVetoPayload = {
    mapId: Scalars['String'];
};

/** The availability level for a given product. */
export enum ServiceLevel {
    /** The product service is fully available. */
    Full = 'FULL',
    /** Some functionalities of the product service may be limited or unavailable. */
    Limited = 'LIMITED',
    /** The product service is not available. */
    None = 'NONE',
}

export type SetAutomaticSeriesUpdateInput = {
    automaticUpdatesStatus: AutomaticUpdatesStatus;
    id: Scalars['ID'];
};

export type SetAutomaticSeriesUpdatePayload = {
    __typename?: 'SetAutomaticSeriesUpdatePayload';
    updatedSeries: Series;
};

export type SetContentPermissionInput = {
    added?: Maybe<Array<Maybe<ContentPermissionRuleInput>>>;
};

export type SingleMessageRequest = {
    content: Scalars['String'];
};

export type SingleMessageResponse = {
    __typename?: 'SingleMessageResponse';
    body: Scalars['String'];
};

export type Site = {
    __typename?: 'Site';
    betting_provider?: Maybe<BettingProviders>;
    brand?: Maybe<Brand>;
    created_at: Scalars['DateTime'];
    domain?: Maybe<Scalars['String']>;
    favicon?: Maybe<UploadFile>;
    features?: Maybe<Array<Maybe<ComponentFeaturesFeatures>>>;
    galleries?: Maybe<Array<Maybe<Gallery>>>;
    google_analytics_4_code?: Maybe<Scalars['String']>;
    google_analytics_code?: Maybe<Scalars['String']>;
    header_cta_link?: Maybe<Scalars['String']>;
    header_cta_text?: Maybe<Scalars['String']>;
    homepage?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    merchandise_banner?: Maybe<MerchandiseBanner>;
    name?: Maybe<Scalars['String']>;
    organisation?: Maybe<Organisation>;
    override_current_series_id?: Maybe<Scalars['String']>;
    pages?: Maybe<Array<Maybe<Page>>>;
    primary_sponsor?: Maybe<Sponsor>;
    site_title?: Maybe<Scalars['String']>;
    socials?: Maybe<ComponentSocialSocials>;
    sponsors?: Maybe<Array<Maybe<Sponsor>>>;
    titles?: Maybe<Scalars['String']>;
    updated_at: Scalars['DateTime'];
};

export type SiteGalleriesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type SitePagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type SiteSponsorsArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type SlugifySlug = {
    __typename?: 'SlugifySlug';
    count?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    slug?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SlugifySlugEntity = {
    __typename?: 'SlugifySlugEntity';
    attributes?: Maybe<SlugifySlug>;
    id?: Maybe<Scalars['ID']>;
};

export type SlugifySlugEntityResponse = {
    __typename?: 'SlugifySlugEntityResponse';
    data?: Maybe<SlugifySlugEntity>;
};

export type SlugifySlugEntityResponseCollection = {
    __typename?: 'SlugifySlugEntityResponseCollection';
    data: Array<SlugifySlugEntity>;
    meta: ResponseCollectionMeta;
};

export type SlugifySlugFiltersInput = {
    and?: Maybe<Array<Maybe<SlugifySlugFiltersInput>>>;
    count?: Maybe<IntFilterInput>;
    createdAt?: Maybe<DateTimeFilterInput>;
    id?: Maybe<IdFilterInput>;
    not?: Maybe<SlugifySlugFiltersInput>;
    or?: Maybe<Array<Maybe<SlugifySlugFiltersInput>>>;
    slug?: Maybe<StringFilterInput>;
    updatedAt?: Maybe<DateTimeFilterInput>;
};

export type SlugifySlugInput = {
    count?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
};

export type Sponsor = {
    __typename?: 'Sponsor';
    alt_text?: Maybe<Scalars['String']>;
    created_at: Scalars['DateTime'];
    id: Scalars['ID'];
    image?: Maybe<UploadFile>;
    link?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    sites?: Maybe<Array<Maybe<Site>>>;
    updated_at: Scalars['DateTime'];
};

export type SponsorSitesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type StaticSeriesData = {
    __typename?: 'StaticSeriesData';
    rapidView: StaticSeriesRapidData;
    series: StaticSeriesSeriesData;
    teams: Array<StaticSeriesTeamData>;
    title: StaticSeriesTitleData;
    tournament: StaticSeriesTournamentData;
};

export type StaticSeriesDataGroup = {
    __typename?: 'StaticSeriesDataGroup';
    date: Scalars['String'];
    series: Array<StaticSeriesData>;
};

export type StaticSeriesRapidData = {
    __typename?: 'StaticSeriesRapidData';
    status: Scalars['String'];
};

export type StaticSeriesSeriesData = {
    __typename?: 'StaticSeriesSeriesData';
    coverage: Scalars['String'];
    format: Scalars['String'];
    id: Scalars['String'];
    multiViewUrl?: Maybe<Scalars['String']>;
    productServiceLevels?: Maybe<ProductCoverageLevels>;
    scheduledStartTime: Scalars['String'];
    traderViewUrl?: Maybe<Scalars['String']>;
};

export type StaticSeriesTeamData = {
    __typename?: 'StaticSeriesTeamData';
    iconUrl: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
    preSeriesPrediction: Scalars['String'];
};

export type StaticSeriesTitleData = {
    __typename?: 'StaticSeriesTitleData';
    id: Scalars['String'];
    shortName: Scalars['String'];
};

export type StaticSeriesTournamentData = {
    __typename?: 'StaticSeriesTournamentData';
    iconUrl?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    name: Scalars['String'];
};

/** String filter for character-based fields. */
export type StringFilter = {
    /** Case-insensitive string filter to match field containing it. */
    contains?: Maybe<Scalars['String']>;
    eq?: Maybe<Scalars['String']>;
    /** Case-insensitive string filter to match field exactly it. */
    equals?: Maybe<Scalars['String']>;
};

export type StringFilterInput = {
    and?: Maybe<Array<Maybe<Scalars['String']>>>;
    between?: Maybe<Array<Maybe<Scalars['String']>>>;
    contains?: Maybe<Scalars['String']>;
    containsi?: Maybe<Scalars['String']>;
    endsWith?: Maybe<Scalars['String']>;
    eq?: Maybe<Scalars['String']>;
    eqi?: Maybe<Scalars['String']>;
    gt?: Maybe<Scalars['String']>;
    gte?: Maybe<Scalars['String']>;
    in?: Maybe<Array<Maybe<Scalars['String']>>>;
    lt?: Maybe<Scalars['String']>;
    lte?: Maybe<Scalars['String']>;
    ne?: Maybe<Scalars['String']>;
    not?: Maybe<StringFilterInput>;
    notContains?: Maybe<Scalars['String']>;
    notContainsi?: Maybe<Scalars['String']>;
    notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
    notNull?: Maybe<Scalars['Boolean']>;
    null?: Maybe<Scalars['Boolean']>;
    or?: Maybe<Array<Maybe<Scalars['String']>>>;
    startsWith?: Maybe<Scalars['String']>;
};

/** Data points for Structures in a Game. */
export type StructureState = {
    __typename?: 'StructureState';
    /** The current health of the Structure (available from version "1.6"). */
    currentHealth: Scalars['Int'];
    /** Indicates whether the structure has been destroyed. */
    destroyed: Scalars['Boolean'];
    /** GRID Structure ID. */
    id: Scalars['ID'];
    /** The max amount of health of the Structure (available from version "1.6"). */
    maxHealth: Scalars['Int'];
    /** Structure Coordinates on the map. */
    position?: Maybe<Coordinates>;
    /** Respawn clock state to indicate when a structure respawns (available from version "3.19"). */
    respawnClock?: Maybe<ClockState>;
    /** Side that controls the Structure. */
    side: Scalars['String'];
    /** GRID Team ID that controls the Structure. */
    teamId: Scalars['ID'];
    /** Type of the Structure. */
    type: Scalars['String'];
};

/** Custom data field bound to a GRID entity. */
export type Tag = {
    __typename?: 'Tag';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type TagInput = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type TagSupport = {
    __typename?: 'TagSupport';
    /** Represents a GRID entity. */
    entity?: Maybe<TagSupportedEntity>;
    /** All tags supported by a GRID entity. */
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** List of entities supporting Tags. */
export enum TagSupportedEntity {
    Tournament = 'TOURNAMENT',
}

/** A team */
export type Team = TeamInterface & {
    __typename?: 'Team';
    /** The AutomaticUpdates (INACTIVE/ACTIVE) status for the team. */
    automaticUpdatesStatus: AutomaticUpdatesStatus;
    /** The primary color of the team. */
    colorPrimary: Scalars['HexColor'];
    /** The secondary color of the team. */
    colorSecondary: Scalars['HexColor'];
    /** The external team IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The ID of a team */
    id: Scalars['ID'];
    /** The team logo URL. */
    logoUrl: Scalars['Url'];
    /** The name of the team. */
    name: Scalars['String'];
    /** The shortened name of a team. */
    nameShortened?: Maybe<Scalars['String']>;
    /** The organization the team plays for. */
    organization?: Maybe<OrganizationRelation>;
    /** The owners of the team. */
    owners: Array<Owner>;
    /** The visibility status of the team. */
    private: Scalars['Boolean'];
    /** Title the team is related to. 'titles' field will be removed soon and every team will have a single title. */
    title: Title;
    /** @deprecated('Use title field instead.') The titles the team is related to. */
    titles: Array<Title>;
};

/** The result of querying a list of teams. It provides pagination information. */
export type TeamConnection = {
    __typename?: 'TeamConnection';
    /** All team edges for query on a given page. */
    edges: Array<TeamEdge>;
    /** Information about current page being pulled with query. */
    pageInfo: PageInfo;
    /** Total count of teams in the API. */
    totalCount: Scalars['Int'];
};

/** Team and cursor information. */
export type TeamEdge = {
    __typename?: 'TeamEdge';
    /** Team cursor value cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** Team. */
    node: Team;
};

/** Filter for team data points. */
export type TeamFilter = {
    /** Filter by the team's name. */
    name?: Maybe<StringFilter>;
    /** Filter by the team's shortened name. */
    nameShortened?: Maybe<StringFilter>;
    /** Filter by a specific organization. */
    organizationId?: Maybe<Scalars['ID']>;
    /** Filter by owners. */
    owners?: Maybe<OwnerFilter>;
    /** Filter by team visibility. */
    private?: Maybe<BooleanFilter>;
    /** Filter by a specific title. */
    titleId?: Maybe<Scalars['ID']>;
};

/** An interface for team types. */
export type TeamInterface = {
    /** The AutomaticUpdates (INACTIVE/ACTIVE) status for the team. */
    automaticUpdatesStatus: AutomaticUpdatesStatus;
    /** The primary color of the team. */
    colorPrimary: Scalars['HexColor'];
    /** The secondary color of the team. */
    colorSecondary: Scalars['HexColor'];
    /** The external team IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The ID of the team. */
    id: Scalars['ID'];
    /** The team logo URL. */
    logoUrl: Scalars['Url'];
    /** The name of the team. */
    name: Scalars['String'];
    /** The shortened name of a team. */
    nameShortened?: Maybe<Scalars['String']>;
    /** The owners of the team. */
    owners: Array<Owner>;
    /** The titles the team is related to. */
    titles: Array<Title>;
};

/** Team participating in the series. */
export type TeamParticipant = {
    __typename?: 'TeamParticipant';
    /** The team base information. */
    baseInfo: Team;
    /** The team score advantage for the series. */
    scoreAdvantage: Scalars['Int'];
};

export type TeamPayload = {
    __typename?: 'TeamPayload';
    id: Scalars['String'];
    name: Scalars['String'];
};

/** A team relation. Represents a relation between a Team and its Organization. */
export type TeamRelation = TeamInterface & {
    __typename?: 'TeamRelation';
    /** The AutomaticUpdates (INACTIVE/ACTIVE) status for the team. */
    automaticUpdatesStatus: AutomaticUpdatesStatus;
    /** The primary color of the team. */
    colorPrimary: Scalars['HexColor'];
    /** The secondary color of the team. */
    colorSecondary: Scalars['HexColor'];
    /** The external team IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The ID of the team. */
    id: Scalars['ID'];
    /** The team logo URL. */
    logoUrl: Scalars['Url'];
    /** The name of the team. */
    name: Scalars['String'];
    /** The shortened name of a team. */
    nameShortened?: Maybe<Scalars['String']>;
    /** The owners of the team. */
    owners: Array<Owner>;
    /** The titles the team is related to. */
    titles: Array<Title>;
};

export type TeamVetoPayload = {
    mapId: Scalars['String'];
    teamId: Scalars['String'];
};

/** Teamkill assist information. */
export type TeamkillAssistFromPlayer = {
    __typename?: 'TeamkillAssistFromPlayer';
    /** GRID ID for this assist. */
    id: Scalars['ID'];
    /** GRID Player ID assisting. */
    playerId: Scalars['ID'];
    /** Number of teamkill assists received from the assisting player. */
    teamkillAssistsReceived: Scalars['Int'];
};

/** An esports Title (available from version "1.1"). */
export type Title = {
    __typename?: 'Title';
    /** The ID of the title. */
    id: Scalars['ID'];
    /** The name of the title. */
    name: Scalars['String'];
    /** Unique, short name description of the esports Title. */
    nameShortened: Scalars['String'];
    /** The owners of the title. */
    owners: Array<Owner>;
    /** The visibility status of the title. */
    private?: Maybe<Scalars['Boolean']>;
};

/** Filter for titles. */
export type TitleFilter = {
    /** Filter by title visibility. */
    private?: Maybe<BooleanFilter>;
};

/** Title version information */
export type TitleVersion = {
    __typename?: 'TitleVersion';
    /** Version name */
    name: Scalars['String'];
};

/** A tournament. */
export type Tournament = {
    __typename?: 'Tournament';
    /** The AutomaticUpdates (INACTIVE/ACTIVE) status for the tournament. */
    automaticUpdatesStatus: AutomaticUpdatesStatus;
    /** The children tournament of the tournament. */
    children: Array<Tournament>;
    /** The end date of the tournament. */
    endDate?: Maybe<Scalars['Date']>;
    /** The external tournament IDs and providers. */
    externalLinks: Array<ExternalLink>;
    /** The ID of a tournament. */
    id: Scalars['ID'];
    /** The tournament logo URL. */
    logoUrl: Scalars['Url'];
    /** The name of the tournament. */
    name: Scalars['String'];
    /** The shortened name of the tournament (max. 30 characters). */
    nameShortened: Scalars['String'];
    /** The owners of the tournament. */
    owners: Array<Owner>;
    /** The parent tournament of the tournament. */
    parent?: Maybe<Tournament>;
    /** The visibility status of the tournament. */
    private: Scalars['Boolean'];
    /** The tournament prize pool in USD. */
    prizePool?: Maybe<Money>;
    /** The start date of the tournament. */
    startDate?: Maybe<Scalars['Date']>;
    /** The tags associated with the tournament. */
    tags: Array<Tag>;
    /** The teams linked to the tournament. */
    teams: Array<Team>;
    /** The titles linked to the tournament. */
    titles: Array<Title>;
};

/** The result of querying a list of tournaments. It provides pagination information. */
export type TournamentConnection = {
    __typename?: 'TournamentConnection';
    /** All tournament edges for query on a given page. */
    edges: Array<TournamentEdge>;
    /** Information about current page being pulled with query. */
    pageInfo: PageInfo;
    /** Total count of tournaments in the API. */
    totalCount: Scalars['Int'];
};

/** Tournament and cursor information. */
export type TournamentEdge = {
    __typename?: 'TournamentEdge';
    /** Tournament cursor value for the edge. */
    cursor: Scalars['Cursor'];
    /** Tournament. */
    node: Tournament;
};

/** Filter for tournament data points. */
export type TournamentFilter = {
    /** Filter by tournament end date. */
    endDate?: Maybe<NullableDateFilter>;
    /** Filter tournaments that have children. If false, only tournaments at the bottom-most level of the hierarchy are returned. */
    hasChildren?: Maybe<BooleanFilter>;
    /** Filter tournaments that have parents. If false, only tournaments at the top-most level of the hierarchy are returned. */
    hasParent?: Maybe<BooleanFilter>;
    /** Filter by tournament name. */
    name?: Maybe<StringFilter>;
    /** Filter by tournament shortened name. */
    nameShortened?: Maybe<StringFilter>;
    /** Filter by owners. */
    owners?: Maybe<OwnerFilter>;
    /** Filter by tournaments visibility. */
    private?: Maybe<BooleanFilter>;
    /** Filter by tournament start date. */
    startDate?: Maybe<NullableDateFilter>;
    /** Filter by tournament titles. */
    title?: Maybe<TournamentTitleFilter>;
    /** @deprecated('Use the titles filter instead.') Limits results to specific title. */
    titleId?: Maybe<Scalars['ID']>;
};

export type TournamentPayload = {
    __typename?: 'TournamentPayload';
    id: Scalars['String'];
    name: Scalars['String'];
    short_name: Scalars['String'];
};

/** Filter for tournaments by title data points. */
export type TournamentTitleFilter = {
    /** Filter by tournament IDs. */
    id?: Maybe<IdFilter>;
};

export type Transaction = {
    __typename?: 'Transaction';
    id: Scalars['ID'];
    occurredAt: Scalars['DateTime'];
    sequenceNumber: Scalars['Int'];
    seriesId: Scalars['String'];
};

export type TwitchChannelInput = {
    channel_id?: Maybe<Scalars['String']>;
    created_by?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    tournament_id?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type TwitchChannels = {
    __typename?: 'TwitchChannels';
    channel_id?: Maybe<Scalars['String']>;
    created_at: Scalars['DateTime'];
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    tournament_id?: Maybe<Scalars['String']>;
    updated_at: Scalars['DateTime'];
};

export type TwitchChannelsAggregator = {
    __typename?: 'TwitchChannelsAggregator';
    count?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type TwitchChannelsConnection = {
    __typename?: 'TwitchChannelsConnection';
    aggregate?: Maybe<TwitchChannelsAggregator>;
    groupBy?: Maybe<TwitchChannelsGroupBy>;
    values?: Maybe<Array<Maybe<TwitchChannels>>>;
};

export type TwitchChannelsConnectionChannel_Id = {
    __typename?: 'TwitchChannelsConnectionChannel_id';
    connection?: Maybe<TwitchChannelsConnection>;
    key?: Maybe<Scalars['String']>;
};

export type TwitchChannelsConnectionCreated_At = {
    __typename?: 'TwitchChannelsConnectionCreated_at';
    connection?: Maybe<TwitchChannelsConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type TwitchChannelsConnectionId = {
    __typename?: 'TwitchChannelsConnectionId';
    connection?: Maybe<TwitchChannelsConnection>;
    key?: Maybe<Scalars['ID']>;
};

export type TwitchChannelsConnectionName = {
    __typename?: 'TwitchChannelsConnectionName';
    connection?: Maybe<TwitchChannelsConnection>;
    key?: Maybe<Scalars['String']>;
};

export type TwitchChannelsConnectionTournament_Id = {
    __typename?: 'TwitchChannelsConnectionTournament_id';
    connection?: Maybe<TwitchChannelsConnection>;
    key?: Maybe<Scalars['String']>;
};

export type TwitchChannelsConnectionUpdated_At = {
    __typename?: 'TwitchChannelsConnectionUpdated_at';
    connection?: Maybe<TwitchChannelsConnection>;
    key?: Maybe<Scalars['DateTime']>;
};

export type TwitchChannelsGroupBy = {
    __typename?: 'TwitchChannelsGroupBy';
    channel_id?: Maybe<Array<Maybe<TwitchChannelsConnectionChannel_Id>>>;
    created_at?: Maybe<Array<Maybe<TwitchChannelsConnectionCreated_At>>>;
    id?: Maybe<Array<Maybe<TwitchChannelsConnectionId>>>;
    name?: Maybe<Array<Maybe<TwitchChannelsConnectionName>>>;
    tournament_id?: Maybe<Array<Maybe<TwitchChannelsConnectionTournament_Id>>>;
    updated_at?: Maybe<Array<Maybe<TwitchChannelsConnectionUpdated_At>>>;
};

/** A unit kill caused by a player or a team (available from version "3.1"). */
export type UnitKill = {
    __typename?: 'UnitKill';
    /** Amount of times a specific unit was killed */
    count: Scalars['Int'];
    /** GRID ID for this unit kill */
    id: Scalars['ID'];
    /** The name of unit that got killed */
    unitName: Scalars['String'];
};

/** The fields used to create a new content catalog item or a version of an existing item. */
export type UpdateContentCatalogItemInput = {
    /** The content catalog version. */
    contentCatalogVersionId: Scalars['ID'];
    /** The new cost of the item. */
    cost?: Maybe<Scalars['Float']>;
    /** The external item IDs. Overrides all previous values. */
    externalLinks?: Maybe<Array<ExternalLinkInput>>;
    /** The UUID of an existing item. */
    id: Scalars['ID'];
    /** The new base64 encoded image of the item. */
    imageData?: Maybe<Scalars['String']>;
    /** The new name of the item. */
    name?: Maybe<Scalars['String']>;
    /** The new owners of the entity */
    owners?: Maybe<Array<UpdateContentCatalogItemOwnerInput>>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

/** The fields used to update item's owners */
export type UpdateContentCatalogItemOwnerInput = {
    /** The owner's ID. */
    ownerId: Scalars['ID'];
};

/** The response for the updateContentCatalogItem mutation. */
export type UpdateContentCatalogItemPayload = {
    __typename?: 'UpdateContentCatalogItemPayload';
    /** The updated content catalog version information. */
    updatedContentCatalogItem: ContentCatalogItem;
};

/** The fields used to update a content catalog version. */
export type UpdateContentCatalogVersionInput = {
    /** The unique identifier of the content catalog version. */
    contentCatalogVersionId: Scalars['ID'];
    /** The new owners of the entity */
    owners?: Maybe<Array<UpdateContentCatalogVersionOwnerInput>>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

/** The fields used to update item's owners */
export type UpdateContentCatalogVersionOwnerInput = {
    /** The owner's ID. */
    ownerId: Scalars['ID'];
};

/** The response for the updateContentCatalogVersion mutation. */
export type UpdateContentCatalogVersionPayload = {
    __typename?: 'UpdateContentCatalogVersionPayload';
    /** The updated content catalog version information. */
    updatedContentCatalogVersion: ContentCatalogVersion;
};

export type UpdateOrganizationInput = {
    /** The ID of the organization to update. */
    id: Scalars['ID'];
    /** The new name for the organization. */
    name?: Maybe<Scalars['String']>;
    /** Owners of the organization */
    owners?: Maybe<Array<UpdateOrganizationOwnerInput>>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The new list of teams for the organization. */
    teams?: Maybe<Array<UpdateOrganizationTeamInput>>;
};

export type UpdateOrganizationOwnerInput = {
    ownerId: Scalars['ID'];
};

export type UpdateOrganizationPayload = {
    __typename?: 'UpdateOrganizationPayload';
    updatedOrganization: Organization;
};

export type UpdateOrganizationTeamInput = {
    teamId: Scalars['ID'];
};

export type UpdatePlayerInput = {
    automaticUpdatesStatus?: Maybe<AutomaticUpdatesStatus>;
    /**
     * If the list is left empty (no argument provided) then no changes will be applied to the existing data.
     * If a value is provided (or an empty list) then this will overwrite the existing data.
     */
    externalLinks?: Maybe<Array<ExternalLinkInput>>;
    id: Scalars['ID'];
    nickname?: Maybe<Scalars['String']>;
    /** Owners of the player */
    owners?: Maybe<Array<UpdatePlayerOwnerInput>>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    team?: Maybe<UpdatePlayerTeamInput>;
    title?: Maybe<UpdatePlayerTitleInput>;
    /** The type of the player. */
    type?: Maybe<PlayerType>;
};

export type UpdatePlayerOwnerInput = {
    ownerId: Scalars['ID'];
};

export type UpdatePlayerPayload = {
    __typename?: 'UpdatePlayerPayload';
    updatedPlayer: Player;
};

export type UpdatePlayerTeamInput = {
    /**  Can explicitly be set to null to remove team from player */
    teamId?: Maybe<Scalars['ID']>;
};

export type UpdatePlayerTitleInput = {
    titleId: Scalars['ID'];
};

/**
 * Used to reference an existing format.
 * Creating a series users should not be able to create new or change the values of the existing format types.
 */
export type UpdateSeriesFormatInput = {
    /** Series format identifier. Takes precedence when both are passed. Check available formats via SeriesFormat query. */
    id?: Maybe<Scalars['ID']>;
    /** @deprecated('Use ID instead.') Series format short name. */
    name?: Maybe<Scalars['String']>;
};

export type UpdateSeriesInput = {
    /**  The broadcast stream delay in seconds */
    broadcastDelayInSeconds?: Maybe<Scalars['Int']>;
    /**
     * If the list is left empty (no argument provided) then no changes will be applied to the existing data.
     * If a value is provided (or an empty list) then this will overwrite the existing data.
     */
    externalLinks?: Maybe<Array<ExternalLinkInput>>;
    format?: Maybe<UpdateSeriesFormatInput>;
    id: Scalars['ID'];
    /**
     * The machine data source id for this series
     * can explicitly be set to null
     */
    machineDataSource?: Maybe<MachineDataSourceInput>;
    /**  Owners of the series */
    owners?: Maybe<Array<UpdateSeriesOwnerInput>>;
    /**  The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /**
     * If the list is left empty (no argument provided) then no changes will be applied to the existing data.
     * If a value is provided (or an empty list) then this will overwrite the existing data.
     */
    productServiceLevels?: Maybe<Array<ProductServiceLevelInput>>;
    startTimeScheduled?: Maybe<Scalars['DateTime']>;
    /**  Url of video feed stream. */
    streamUrl?: Maybe<UpdateSeriesStreamUrlInput>;
    /**
     * If the list is left empty (no argument provided) then no changes will be applied to the existing data.
     * If a value is provided (or an empty list) then this will overwrite the existing data.
     */
    teams?: Maybe<Array<UpdateSeriesTeamInput>>;
    title?: Maybe<UpdateSeriesTitleInput>;
    tournament?: Maybe<UpdateSeriesTournamentInput>;
};

export type UpdateSeriesOwnerInput = {
    ownerId: Scalars['ID'];
};

export type UpdateSeriesPayload = {
    __typename?: 'UpdateSeriesPayload';
    updatedSeries: Series;
};

export type UpdateSeriesStreamUrlInput = {
    /**  Url pointing to a video stream. */
    url: Scalars['String'];
};

export type UpdateSeriesTeamInput = {
    /**  Team score advantage for series */
    scoreAdvantage?: Scalars['Int'];
    teamId: Scalars['ID'];
};

export type UpdateSeriesTitleInput = {
    titleId: Scalars['ID'];
};

export type UpdateSeriesTournamentInput = {
    tournamentId: Scalars['ID'];
};

export type UpdateTeamInput = {
    /** Status of auto integration */
    automaticUpdatesStatus?: Maybe<AutomaticUpdatesStatus>;
    /** Team primary color in correct hexadecimal value */
    colorPrimary?: Maybe<Scalars['HexColor']>;
    /** Team secondary color in correct hexadecimal value */
    colorSecondary?: Maybe<Scalars['HexColor']>;
    /** Team external links */
    externalLinks?: Maybe<Array<ExternalLinkInput>>;
    /** The id of the team to be updated. */
    id: Scalars['ID'];
    /** Logo image encoded as base64 string */
    logoData?: Maybe<Scalars['String']>;
    /** The name is unique and limited to 100 characters */
    name?: Maybe<Scalars['String']>;
    /** The new shortened name of the team. It's limited to 20 characters and it's not unique. */
    nameShortened?: Maybe<Scalars['String']>;
    /** The organization the team has to be linked to */
    organization?: Maybe<UpdateTeamOrganizationInput>;
    /** Owners of the team */
    owners?: Maybe<Array<UpdateTeamOwnerInput>>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    /** The title of the team. */
    title?: Maybe<UpdateTeamTitleInput>;
    /** @deprecated('Use title instead. If you use both, this field will be ignored.') The titles the team has to be linked to */
    titles?: Maybe<Array<UpdateTeamTitleInput>>;
};

export type UpdateTeamOrganizationInput = {
    organizationId?: Maybe<Scalars['ID']>;
};

export type UpdateTeamOwnerInput = {
    ownerId: Scalars['ID'];
};

export type UpdateTeamPayload = {
    __typename?: 'UpdateTeamPayload';
    updatedTeam: Team;
};

export type UpdateTeamTitleInput = {
    titleId: Scalars['ID'];
};

export type UpdateTitleInput = {
    /** The id of the title to be updated. */
    id: Scalars['ID'];
    /** The name (e.g. counter strike global offensive) is unique and limited to 100 characters. */
    name?: Maybe<Scalars['String']>;
    /** The shortened name (e.g. csgo) is unique and limited to 20 characters. */
    nameShortened?: Maybe<Scalars['String']>;
    /** Owners of the title */
    owners?: Maybe<Array<UpdateTitleOwnerInput>>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
};

export type UpdateTitleOwnerInput = {
    ownerId: Scalars['ID'];
};

export type UpdateTitlePayload = {
    __typename?: 'UpdateTitlePayload';
    updatedTitle: Title;
};

export type UpdateTournamentInput = {
    automaticUpdatesStatus?: Maybe<AutomaticUpdatesStatus>;
    endDate?: Maybe<DateInput>;
    externalLinks?: Maybe<Array<ExternalLinkInput>>;
    id: Scalars['ID'];
    logoData?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    nameShortened?: Maybe<Scalars['String']>;
    /** Owners of the tournament */
    owners?: Maybe<Array<UpdateTournamentOwnerInput>>;
    parent?: Maybe<UpdateTournamentParentInput>;
    /** The visibility of this entity to other users. */
    private?: Maybe<Scalars['Boolean']>;
    prizePool?: Maybe<MoneyInput>;
    startDate?: Maybe<DateInput>;
    tags?: Maybe<Array<TagInput>>;
    teams?: Maybe<Array<UpdateTournamentTeamInput>>;
    titles?: Maybe<Array<UpdateTournamentTitleInput>>;
};

export type UpdateTournamentOwnerInput = {
    ownerId: Scalars['ID'];
};

export type UpdateTournamentParentInput = {
    tournamentId?: Maybe<Scalars['ID']>;
};

export type UpdateTournamentPayload = {
    __typename?: 'UpdateTournamentPayload';
    updatedTournament: Tournament;
};

export type UpdateTournamentTeamInput = {
    teamId: Scalars['ID'];
};

export type UpdateTournamentTitleInput = {
    titleId: Scalars['ID'];
};

export type UpdateUserPayload = {
    email?: Maybe<Scalars['String']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    organisationId?: Maybe<Scalars['String']>;
    userType?: Maybe<Scalars['String']>;
    username?: Maybe<Scalars['String']>;
};

export type UpdateUsersPayload = {
    email?: Maybe<Scalars['String']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    username?: Maybe<Scalars['String']>;
};

export type UploadFile = {
    __typename?: 'UploadFile';
    alternativeText?: Maybe<Scalars['String']>;
    caption?: Maybe<Scalars['String']>;
    created_at: Scalars['DateTime'];
    ext?: Maybe<Scalars['String']>;
    formats?: Maybe<Scalars['JSON']>;
    hash: Scalars['String'];
    height?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    mime: Scalars['String'];
    name: Scalars['String'];
    previewUrl?: Maybe<Scalars['String']>;
    provider: Scalars['String'];
    provider_metadata?: Maybe<Scalars['JSON']>;
    related?: Maybe<Array<Maybe<Morph>>>;
    size: Scalars['Float'];
    updated_at: Scalars['DateTime'];
    url: Scalars['String'];
    width?: Maybe<Scalars['Int']>;
};

export type UploadFileRelatedArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type User = {
    __typename?: 'User';
    createdAt: Scalars['Int'];
    email: Scalars['String'];
    id: Scalars['String'];
    isEnabled: Scalars['Boolean'];
    lastActiveAt: Scalars['Int'];
    organisationName?: Maybe<Scalars['String']>;
    userType?: Maybe<Scalars['String']>;
    username: Scalars['String'];
};

export type UserEdge = {
    __typename?: 'UserEdge';
    cursor: Scalars['String'];
    node: User;
};

export type UserPermissionsPasswordPayload = {
    __typename?: 'UserPermissionsPasswordPayload';
    ok: Scalars['Boolean'];
};

export type UserRoleGroup = {
    __typename?: 'UserRoleGroup';
    disabled?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
};

export type UserRoleGroupsPayload = {
    __typename?: 'UserRoleGroupsPayload';
    roleGroups: Array<Maybe<UserRoleGroup>>;
    services?: Maybe<Array<Maybe<UserRoleGroup>>>;
};

export type UserTypeFilter = {
    __typename?: 'UserTypeFilter';
    id: Scalars['String'];
    name: Scalars['String'];
};

export type UsersFilterOptions = {
    __typename?: 'UsersFilterOptions';
    userTypes?: Maybe<Array<UserTypeFilter>>;
};

export type UsersPayload = {
    __typename?: 'UsersPayload';
    users: PaginatedUsers;
};

export type UsersPermissionsLoginInput = {
    identifier: Scalars['String'];
    password: Scalars['String'];
    provider?: Maybe<Scalars['String']>;
};

export type UsersPermissionsLoginPayload = {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt?: Maybe<Scalars['String']>;
    user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
    __typename?: 'UsersPermissionsMe';
    blocked?: Maybe<Scalars['Boolean']>;
    confirmed?: Maybe<Scalars['Boolean']>;
    email: Scalars['String'];
    id: Scalars['ID'];
    role?: Maybe<UsersPermissionsMeRole>;
    username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
    __typename?: 'UsersPermissionsMeRole';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPermission = {
    __typename?: 'UsersPermissionsPermission';
    action: Scalars['String'];
    controller: Scalars['String'];
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    policy?: Maybe<Scalars['String']>;
    role?: Maybe<UsersPermissionsRole>;
    type: Scalars['String'];
};

export type UsersPermissionsRegisterInput = {
    email: Scalars['String'];
    password: Scalars['String'];
    username: Scalars['String'];
};

export type UsersPermissionsRole = {
    __typename?: 'UsersPermissionsRole';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    permissions?: Maybe<Array<Maybe<UsersPermissionsPermission>>>;
    type?: Maybe<Scalars['String']>;
    users?: Maybe<Array<Maybe<UsersPermissionsUser>>>;
};

export type UsersPermissionsRolePermissionsArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleUsersArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

export type UsersPermissionsUser = {
    __typename?: 'UsersPermissionsUser';
    blocked?: Maybe<Scalars['Boolean']>;
    confirmed?: Maybe<Scalars['Boolean']>;
    created_at: Scalars['DateTime'];
    email: Scalars['String'];
    id: Scalars['ID'];
    provider?: Maybe<Scalars['String']>;
    role?: Maybe<UsersPermissionsRole>;
    updated_at: Scalars['DateTime'];
    username: Scalars['String'];
};

export type Veto = {
    __typename?: 'Veto';
    id?: Maybe<Scalars['String']>;
};

/** Live video stream details. */
export type VideoStream = {
    __typename?: 'VideoStream';
    /** URL link to live video stream. */
    url: Scalars['String'];
};

export type WatchHubCard = {
    __typename?: 'WatchHubCard';
    pageId?: Maybe<Scalars['String']>;
    primaryTeamId?: Maybe<Scalars['String']>;
    scheduledStartTime?: Maybe<Scalars['String']>;
    series?: Maybe<ExtendedSeriesInfo>;
    status?: Maybe<Scalars['String']>;
    teams: Array<Maybe<BaseTeamInfo>>;
    tournament?: Maybe<Scalars['String']>;
};

export type Watchhub = {
    __typename?: 'Watchhub';
    created_at: Scalars['DateTime'];
    enabled_pages?: Maybe<Array<Maybe<Page>>>;
    id: Scalars['ID'];
    updated_at: Scalars['DateTime'];
};

export type WatchhubEnabled_PagesArgs = {
    limit?: Maybe<Scalars['Int']>;
    sort?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Int']>;
    where?: Maybe<Scalars['JSON']>;
};

/** A kill that was executed with the named weapon. */
export type WeaponKill = {
    __typename?: 'WeaponKill';
    /** Amount of times a kill happened with the named weapon. */
    count?: Maybe<Scalars['Int']>;
    /** GRID ID for this weapon kill. */
    id: Scalars['ID'];
    /** Name of the weapon used for this kill. */
    weaponName: Scalars['String'];
};

export type WhitelistedIp = {
    __typename?: 'WhitelistedIp';
    ip: Scalars['String'];
    type: Scalars['String'];
};

export type _Service = {
    __typename?: '_Service';
    sdl: Scalars['String'];
};

export type CreateCircuitHubInput = {
    data?: Maybe<CircuitHubInput>;
};

export type CreateCircuitHubPayload = {
    __typename?: 'createCircuitHubPayload';
    circuitHub?: Maybe<CircuitHub>;
};

export type CreateContentPageInput = {
    data?: Maybe<ContentPageInput>;
};

export type CreateContentPagePayload = {
    __typename?: 'createContentPagePayload';
    contentPage?: Maybe<ContentPage>;
};

export type CreateDocumentationPageInput = {
    data?: Maybe<DocumentationPageInput>;
};

export type CreateDocumentationPagePayload = {
    __typename?: 'createDocumentationPagePayload';
    documentationPage?: Maybe<DocumentationPage>;
};

export type CreateGalleryInput = {
    data?: Maybe<GalleryInput>;
};

export type CreateGalleryPayload = {
    __typename?: 'createGalleryPayload';
    gallery?: Maybe<Gallery>;
};

export type CreatePageInput = {
    data?: Maybe<PageInput>;
};

export type CreatePagePayload = {
    __typename?: 'createPagePayload';
    page?: Maybe<Page>;
};

export type CreateProductUpdateInput = {
    data?: Maybe<ProductUpdateInput>;
};

export type CreateProductUpdatePayload = {
    __typename?: 'createProductUpdatePayload';
    productUpdate?: Maybe<ProductUpdates>;
};

export type CreateTwitchChannelInput = {
    data?: Maybe<TwitchChannelInput>;
};

export type CreateTwitchChannelPayload = {
    __typename?: 'createTwitchChannelPayload';
    twitchChannel?: Maybe<TwitchChannels>;
};

export type DeleteCircuitHubInput = {
    where?: Maybe<InputId>;
};

export type DeleteCircuitHubPayload = {
    __typename?: 'deleteCircuitHubPayload';
    circuitHub?: Maybe<CircuitHub>;
};

export type DeleteContentPageInput = {
    where?: Maybe<InputId>;
};

export type DeleteContentPagePayload = {
    __typename?: 'deleteContentPagePayload';
    contentPage?: Maybe<ContentPage>;
};

export type DeleteDocumentationPageInput = {
    where?: Maybe<InputId>;
};

export type DeleteDocumentationPagePayload = {
    __typename?: 'deleteDocumentationPagePayload';
    documentationPage?: Maybe<DocumentationPage>;
};

export type DeleteGalleryInput = {
    where?: Maybe<InputId>;
};

export type DeleteGalleryPayload = {
    __typename?: 'deleteGalleryPayload';
    gallery?: Maybe<Gallery>;
};

export type DeletePageInput = {
    where?: Maybe<InputId>;
};

export type DeletePagePayload = {
    __typename?: 'deletePagePayload';
    page?: Maybe<Page>;
};

export type DeleteProductUpdateInput = {
    where?: Maybe<InputId>;
};

export type DeleteProductUpdatePayload = {
    __typename?: 'deleteProductUpdatePayload';
    productUpdate?: Maybe<ProductUpdates>;
};

export type DeleteTwitchChannelInput = {
    where?: Maybe<InputId>;
};

export type DeleteTwitchChannelPayload = {
    __typename?: 'deleteTwitchChannelPayload';
    twitchChannel?: Maybe<TwitchChannels>;
};

export type EditCircuitHubInput = {
    banner?: Maybe<Scalars['ID']>;
    content_pages?: Maybe<Array<Maybe<Scalars['ID']>>>;
    created_by?: Maybe<Scalars['ID']>;
    footer?: Maybe<EditComponentCircuitHubFooterInput>;
    navigation?: Maybe<EditComponentGenericNavigationInput>;
    pages?: Maybe<Array<Maybe<Scalars['ID']>>>;
    published_at?: Maybe<Scalars['DateTime']>;
    sections: Array<Scalars['CircuitHubSectionsDynamicZoneInput']>;
    site?: Maybe<Scalars['ID']>;
    tourLogo?: Maybe<EditComponentImagesImageInput>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type EditComponentButtonsButtonInput = {
    href?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    size?: Maybe<Enum_Componentbuttonsbutton_Size>;
    text?: Maybe<Scalars['String']>;
    variant?: Maybe<Enum_Componentbuttonsbutton_Variant>;
};

export type EditComponentCircuitHubBackButtonInput = {
    id?: Maybe<Scalars['ID']>;
    text?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubBasicSectionInput = {
    action?: Maybe<EditComponentButtonsButtonInput>;
    id?: Maybe<Scalars['ID']>;
    image?: Maybe<EditComponentCircuitHubSectionImageInput>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<EditComponentCircuitHubSectionStyleInput>;
    text?: Maybe<EditComponentCircuitHubSectionTextInput>;
    video?: Maybe<EditComponentCircuitHubVideoInput>;
};

export type EditComponentCircuitHubExternalContentInput = {
    id?: Maybe<Scalars['ID']>;
    url?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubFooterInput = {
    id?: Maybe<Scalars['ID']>;
    linksColumnLeft?: Maybe<EditComponentGenericNavigationInput>;
    linksColumnRight?: Maybe<EditComponentGenericNavigationInput>;
};

export type EditComponentCircuitHubPartnersSectionInput = {
    action?: Maybe<EditComponentButtonsButtonInput>;
    id?: Maybe<Scalars['ID']>;
    partners?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<EditComponentCircuitHubSectionStyleInput>;
    title?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubPointsSectionInput = {
    id?: Maybe<Scalars['ID']>;
    points?: Maybe<Array<Maybe<EditComponentCircuitHubSectionPointInput>>>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<EditComponentCircuitHubSectionStyleInput>;
};

export type EditComponentCircuitHubSectionImageInput = {
    content?: Maybe<EditComponentImagesImageInput>;
    id?: Maybe<Scalars['ID']>;
    position?: Maybe<Enum_Componentcircuithubsectionimage_Position>;
};

export type EditComponentCircuitHubSectionPointInput = {
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    textBottom?: Maybe<Scalars['String']>;
    textTop?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubSectionStyleInput = {
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundType?: Maybe<Enum_Componentcircuithubsectionstyle_Backgroundtype>;
    id?: Maybe<Scalars['ID']>;
    verticalSpace?: Maybe<Enum_Componentcircuithubsectionstyle_Verticalspace>;
};

export type EditComponentCircuitHubSectionTabInput = {
    content?: Maybe<Array<Maybe<EditComponentCircuitHubBasicSectionInput>>>;
    id?: Maybe<Scalars['ID']>;
    title?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubSectionTextInput = {
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    subtitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubTabsSectionInput = {
    id?: Maybe<Scalars['ID']>;
    sectionId?: Maybe<Scalars['String']>;
    style?: Maybe<EditComponentCircuitHubSectionStyleInput>;
    tabs?: Maybe<Array<Maybe<EditComponentCircuitHubSectionTabInput>>>;
    title?: Maybe<Scalars['String']>;
};

export type EditComponentCircuitHubTournamentConfigInput = {
    id?: Maybe<Scalars['ID']>;
    logo?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    order?: Maybe<Scalars['Float']>;
    period?: Maybe<Scalars['String']>;
    ranking?: Maybe<Scalars['ID']>;
    region?: Maybe<Scalars['String']>;
    status?: Maybe<Enum_Componentcircuithubtournamentconfig_Status>;
};

export type EditComponentCircuitHubTournamentInput = {
    action?: Maybe<EditComponentButtonsButtonInput>;
    id?: Maybe<Scalars['ID']>;
    sectionId?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    tournaments?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type EditComponentCircuitHubVideoInput = {
    id?: Maybe<Scalars['ID']>;
    position?: Maybe<Enum_Componentcircuithubvideo_Position>;
    url?: Maybe<Scalars['String']>;
};

export type EditComponentFeaturesFeatureInput = {
    config?: Maybe<Scalars['JSON']>;
    enabled?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type EditComponentGenericNavigationInput = {
    id?: Maybe<Scalars['ID']>;
    items?: Maybe<Array<Maybe<EditComponentGenericNavigationItemInput>>>;
    title?: Maybe<Scalars['String']>;
};

export type EditComponentGenericNavigationItemInput = {
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type EditComponentGenericOverrideInput = {
    id?: Maybe<Scalars['ID']>;
    series?: Maybe<Scalars['String']>;
    team?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    tournament?: Maybe<Scalars['String']>;
};

export type EditComponentImagesGalleryImageInput = {
    cta?: Maybe<EditComponentButtonsButtonInput>;
    fullsize?: Maybe<EditComponentImagesImageInput>;
    id?: Maybe<Scalars['ID']>;
    thumbnail?: Maybe<EditComponentImagesImageInput>;
};

export type EditComponentImagesImageInput = {
    alt_text?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    image?: Maybe<Scalars['ID']>;
};

export type EditComponentPageComponentsBestOddInput = {
    featured_betting_provider?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
};

export type EditComponentPageComponentsGalleryInput = {
    gallery?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    location?: Maybe<Enum_Componentpagecomponentsgallery_Location>;
};

export type EditComponentPageComponentsMetaInput = {
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    keywords?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type EditComponentPageComponentsPromoBannerInput = {
    id?: Maybe<Scalars['ID']>;
    location?: Maybe<Enum_Componentpagecomponentspromobanner_Location>;
    promo_banner?: Maybe<Scalars['ID']>;
};

export type EditComponentPageComponentsSponsorInput = {
    id?: Maybe<Scalars['ID']>;
    primary_sponsor?: Maybe<Scalars['ID']>;
    sponsors?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type EditComponentPageComponentsVisualisationsWidgetInput = {
    enabled?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    settings?: Maybe<Scalars['JSON']>;
    type?: Maybe<Enum_Componentpagecomponentsvisualisationswidget_Type>;
};

export type EditComponentSocialSocialInput = {
    facebook?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    instagram?: Maybe<Scalars['String']>;
    linkedin?: Maybe<Scalars['String']>;
    twitch?: Maybe<Scalars['String']>;
    twitter?: Maybe<Scalars['String']>;
    youtube?: Maybe<Scalars['String']>;
};

export type EditContentPageInput = {
    backbutton?: Maybe<EditComponentCircuitHubBackButtonInput>;
    circuit_hub?: Maybe<Scalars['ID']>;
    content?: Maybe<Array<Scalars['ContentPageContentDynamicZoneInput']>>;
    created_by?: Maybe<Scalars['ID']>;
    footer?: Maybe<EditComponentCircuitHubFooterInput>;
    meta?: Maybe<EditComponentPageComponentsMetaInput>;
    navigation?: Maybe<EditComponentGenericNavigationInput>;
    published_at?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type EditDocumentationPageInput = {
    category?: Maybe<Enum_Documentationpage_Category>;
    content?: Maybe<Scalars['String']>;
    created_by?: Maybe<Scalars['ID']>;
    order?: Maybe<Scalars['Int']>;
    permissions?: Maybe<Scalars['JSON']>;
    published_at?: Maybe<Scalars['DateTime']>;
    slug?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type EditGalleryInput = {
    created_by?: Maybe<Scalars['ID']>;
    cta_position?: Maybe<Enum_Gallery_Cta_Position>;
    facebook_social_share?: Maybe<Scalars['Boolean']>;
    images?: Maybe<Array<Maybe<EditComponentImagesGalleryImageInput>>>;
    layout?: Maybe<Enum_Gallery_Layout>;
    name?: Maybe<Scalars['String']>;
    share_position?: Maybe<Enum_Gallery_Share_Position>;
    sites?: Maybe<Array<Maybe<Scalars['ID']>>>;
    title?: Maybe<Scalars['String']>;
    twitter_social_share?: Maybe<Scalars['Boolean']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type EditPageInput = {
    circuitHub?: Maybe<Scalars['ID']>;
    components?: Maybe<Array<Scalars['PageComponentsDynamicZoneInput']>>;
    created_by?: Maybe<Scalars['ID']>;
    meta?: Maybe<EditComponentPageComponentsMetaInput>;
    name?: Maybe<Scalars['String']>;
    overrides?: Maybe<EditComponentGenericOverrideInput>;
    page_type?: Maybe<Enum_Page_Page_Type>;
    priority?: Maybe<Scalars['Int']>;
    site?: Maybe<Scalars['ID']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type EditProductUpdateInput = {
    content?: Maybe<Scalars['String']>;
    created_by?: Maybe<Scalars['ID']>;
    date?: Maybe<Scalars['Date']>;
    published_at?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type EditTwitchChannelInput = {
    channel_id?: Maybe<Scalars['String']>;
    created_by?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    tournament_id?: Maybe<Scalars['String']>;
    updated_by?: Maybe<Scalars['ID']>;
};

export type UpdateCircuitHubInput = {
    data?: Maybe<EditCircuitHubInput>;
    where?: Maybe<InputId>;
};

export type UpdateCircuitHubPayload = {
    __typename?: 'updateCircuitHubPayload';
    circuitHub?: Maybe<CircuitHub>;
};

export type UpdateContentPageInput = {
    data?: Maybe<EditContentPageInput>;
    where?: Maybe<InputId>;
};

export type UpdateContentPagePayload = {
    __typename?: 'updateContentPagePayload';
    contentPage?: Maybe<ContentPage>;
};

export type UpdateDocumentationPageInput = {
    data?: Maybe<EditDocumentationPageInput>;
    where?: Maybe<InputId>;
};

export type UpdateDocumentationPagePayload = {
    __typename?: 'updateDocumentationPagePayload';
    documentationPage?: Maybe<DocumentationPage>;
};

export type UpdateGalleryInput = {
    data?: Maybe<EditGalleryInput>;
    where?: Maybe<InputId>;
};

export type UpdateGalleryPayload = {
    __typename?: 'updateGalleryPayload';
    gallery?: Maybe<Gallery>;
};

export type UpdatePageInput = {
    data?: Maybe<EditPageInput>;
    where?: Maybe<InputId>;
};

export type UpdatePagePayload = {
    __typename?: 'updatePagePayload';
    page?: Maybe<Page>;
};

export type UpdateProductUpdateInput = {
    data?: Maybe<EditProductUpdateInput>;
    where?: Maybe<InputId>;
};

export type UpdateProductUpdatePayload = {
    __typename?: 'updateProductUpdatePayload';
    productUpdate?: Maybe<ProductUpdates>;
};

export type UpdateTwitchChannelInput = {
    data?: Maybe<EditTwitchChannelInput>;
    where?: Maybe<InputId>;
};

export type UpdateTwitchChannelPayload = {
    __typename?: 'updateTwitchChannelPayload';
    twitchChannel?: Maybe<TwitchChannels>;
};
