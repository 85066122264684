import { ComponentProps, ComponentType, FC, forwardRef, useMemo } from 'react';
import Box from '@components/Layout/Grid/Box';

import IconContainer, { StyledInput, InputProps as StyledInputProps } from './styles';
import Icon from '../../Images/Icon';
import maskString from './utils';

export interface InputProps extends StyledInputProps {
    /**
     * @deprecated use icon prop
     */
    Icon?: ComponentType | string;
    icon?: string | ComponentProps<typeof Icon>;
    hasKeyMask?: boolean;
}

export const KeyInput: FC<InputProps> = forwardRef(({ Icon: IconProp, ...props }, ref) => {
    const { value, onChange } = props;

    const maskedValue = value ? maskString(value) : value;

    const pr = IconProp ? 6 : 0;

    return (
        <>
            <input
                style={{ position: 'absolute', visibility: 'hidden' }}
                value={value}
                ref={ref}
                readOnly
            />
            <StyledInput {...props} pr={pr} value={maskedValue} onChange={onChange} />
        </>
    );
});

const Input: FC<InputProps> = forwardRef(({ Icon: IconProp, icon, hasKeyMask, ...props }, ref) => {
    const Component = !hasKeyMask ? StyledInput : KeyInput;

    const renderedIcon = useMemo(() => {
        if (IconProp) {
            return typeof IconProp === 'string' ? (
                <Icon icon={IconProp} fill="base.40" size="small" />
            ) : (
                <IconProp />
            );
        }

        if (icon) {
            if (typeof icon === 'string') {
                return <Icon icon={icon} fill="base.40" size="small" />;
            }

            const IconComponent = <Icon {...icon} />;
            return IconComponent;
        }

        return null;
    }, [IconProp, icon]);

    if (!renderedIcon) return <Component {...props} ref={ref} />;

    return (
        <Box position="relative" width={1}>
            <Component ref={ref} {...props} />
            <IconContainer position="absolute" top="50%" right="0">
                {renderedIcon}
            </IconContainer>
        </Box>
    );
});

export default Input;
